import { DirectiveBinding } from "vue";

export interface IDirective {
  directive?: string;
  args?: { max?: number };
}

export const allowOnlyNumber = (
  el: HTMLInputElement,
  binding: DirectiveBinding
) => {
  // binding.arg === "max" && binding.value
  // default arg is max
  el.addEventListener("keyup", (event: KeyboardEvent) => {
    const isRepeat = event.repeat;
    const element = event.target as HTMLInputElement;
    const maxValue = binding.value ?? 9;
    const keyCode = event.key;
    const isBackspace = keyCode === "Backspace";
    const isArrowKey = keyCode.includes("Arrow");
    // and remove all non-numeric character
    // trim to max value
    if (!isBackspace && !isArrowKey && !isRepeat) {
      el.value = element.value.replace(/\D/g, "").substring(0, maxValue);
      el.dispatchEvent(new Event("change"));
    }
  });
};
export const allowOnlyPhoneNumber = (
  el: HTMLInputElement,
  binding: DirectiveBinding
) => {
  let max = 10;
  if (binding.arg === "max" && binding.value) {
    max = parseInt(binding.value);
  }
  el.addEventListener("keyup", (event: KeyboardEvent) => {
    const isRepeat = event.repeat;
    const element = event.target as HTMLInputElement;
    const maxValue = binding.value ?? 9;
    const keyCode = event.key;
    const isBackspace = keyCode === "Backspace";
    const isArrowKey = keyCode.includes("Arrow");
    //remove leading zero
    // and replace with empty string
    // and remove all non-numeric character
    // trim to max value
    if (!isBackspace && !isArrowKey && !isRepeat) {
      el.value = element.value.replace(/\D/g, "").substring(0, maxValue);
      el.dispatchEvent(new Event("change"));
    }
  });
};

export const dynamicDirective = (el, binding) => {
  const _values = binding.value as IDirective[];
  const currentElement = el as HTMLInputElement;
  if (!_values) return;
  _values.forEach((value) => {
    if (!value) return;
    allowOnlyPhoneNUmber();
    allowMaxCharacter();
    allowOnlyAlphaNumeric();
    allowOnlyNumber();
    onUserSelectText();
    unsignedDecimal();
    phoneValidateV2();
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    function allowOnlyPhoneNUmber() {
      if (value.directive === "allow-only-phone-number") {
        allowOnlyPhoneNumber(el, {
          dir: binding.dir,
          instance: binding.instance,
          modifiers: binding.modifiers,
          oldValue: undefined,
          value: binding.value.max,
          arg: "max",
        });
        return;
      }
    }
    function allowMaxCharacter() {
      el.addEventListener("keypress", (event: KeyboardEvent) => {
        if (value.directive === "allow-max-character") {
          if (
            (event.target as HTMLInputElement).value.length >=
            (value.args?.max || 100)
          ) {
            event.preventDefault();
          }
        }
      });
    }

    // Must accept 9-10 characters max with combination of letters and numbers
    function allowOnlyAlphaNumeric() {
      el.addEventListener("keypress", (event: KeyboardEvent) => {
        if (value.directive === "allow-only-alpha-numeric") {
          const keyCode = event.key;
          const isOnlyAlphaNumeric = /^[a-zA-Z0-9]+$/.test(keyCode);
          if (!isOnlyAlphaNumeric) {
            event.preventDefault();
          } else {
            if (
              (event.target as HTMLInputElement).value.length >=
              (value.args?.max ?? 10)
            ) {
              event.preventDefault();
            }
          }
          return;
        }
      });

      el.addEventListener("paste", (event: ClipboardEvent) => {
        if (value.directive === "allow-only-alpha-numeric") {
          event.preventDefault();
          const pasteData = event.clipboardData?.getData("text");
          // clean paste data with only alphanumeric
          let cleanPasteData = (pasteData ?? "").replace(/[^a-zA-Z0-9]/g, "");
          // subtract new data length from max length
          const max = value.args?.max ?? 10;
          // if paste data is bigger than max length then slice it
          if (cleanPasteData?.length > max) {
            cleanPasteData = (cleanPasteData + el.value)?.slice(0, max);
          }
          el.value = cleanPasteData; // add new data to current data
          el.dispatchEvent(new Event("change"));
        }
      });
    }

    // Must accept 8-10 characters max with only numbers and no spaces
    function allowOnlyNumber() {
      el.addEventListener("keypress", (event: KeyboardEvent) => {
        if (value.directive === "allow-only-number") {
          const keyCode = event.key;
          const isOnlyNumber = /^[0-9]+$/.test(keyCode);
          if (!isOnlyNumber) {
            event.preventDefault();
          } else {
            if (
              (event.target as HTMLInputElement).value.length >=
              (value.args?.max ?? 10)
            ) {
              event.preventDefault();
            }
          }
        }
      });

      el.addEventListener("paste", (event: ClipboardEvent) => {
        if (value.directive === "allow-only-number") {
          event.preventDefault();
          const pasteData = event.clipboardData?.getData("text");
          // clean paste data with only alphanumeric
          let cleanPasteData = (pasteData ?? "").replace(/[^0-9]/g, "");
          // subtract new data length from max length
          const max = value.args?.max ?? 10;
          // if paste data is bigger than max length then slice it
          if (cleanPasteData?.length > max) {
            cleanPasteData = (cleanPasteData + el)?.slice(0, max);
          }
          el.value = cleanPasteData; // add new data to current data
          el.dispatchEvent(new Event("change"));
        }
      });
    }

    function onUserSelectText() {
      //on user select some text in input
      el.addEventListener("select", () => {
        // select all text in input
        currentElement.select();
        return;
      });
    }

    function unsignedDecimal() {
      el.addEventListener("keyup", (event: KeyboardEvent) => {
        if (value.directive === "unsigned-decimal") {
          const isRepeat = event.repeat;
          const element = event.target as HTMLInputElement;
          const maxValue = binding.value.max ?? 9;
          const keyCode = event.key;
          const isBackspace = keyCode === "Backspace";
          const isArrowKey = keyCode.includes("Arrow");
          // and remove all non-numeric character
          // trim to max value
          if (!isBackspace && !isArrowKey && !isRepeat) {
            el.value = element.value
              .replace(/[^0-9.]/g, "")
              // remove all decimal point except the first one
              .replace(/\./g, (match, offset, string) => {
                return string.indexOf(match) === offset ? match : "";
              })
              // add 0 if the first character is a decimal point
              .replace(/^\./g, "0.")
              .substring(0, maxValue);
          }
          el.dispatchEvent(new Event("change"));
        }
      });
    }

    function phoneValidateV2() {
      el.addEventListener("keyup", (event: KeyboardEvent) => {
        if (value.directive === "phone-validate-v2") {
          const value = el.value;
          const isBackspace = event.key === "Backspace";
          const isFirstCharacterIsNotZero = value[0] !== "0";
          if (isFirstCharacterIsNotZero) {
            if (!isBackspace) {
              el.value = `0${value}`;
              el.dispatchEvent(new Event("change"));
              return;
            }
          }
        }
      });
    }
  });
};
