import { defineStore } from "pinia";
import { getTranslate } from "@/core/helpers/custom";
import {
  MemberV2Api,
  ServiceLogRecordResponse,
  ServiceLogRecordResponseGeneralListResponse,
} from "@/api";

export const useMbServiceRequestListingStore = defineStore({
  id: "serviceRequestListingStore",
  state: () => ({
    titles: {
      km: getTranslate("SERVICE REQUEST HISTORY"),
      en: "SERVICE REQUEST HISTORY",
    },
    dateRange: {
      startDate: undefined as Date | undefined,
      endDate: undefined as Date | undefined,
    },
    list: [] as ServiceLogRecordResponse[],
    pagination: {
      pageNumber: 1,
      pageSize: undefined as number | undefined,
      totalCount: undefined as number | undefined,
      totalPage: undefined as number | undefined,
    },
    rawData: undefined as
      | ServiceLogRecordResponseGeneralListResponse
      | undefined,
    firstLoad: false,
    fistPageLoading: false,
  }),
  getters: {
    getRawList(state): ServiceLogRecordResponse[] {
      return state.list;
    },
    isNoData(state) {
      return state.list.length === 0 && state.firstLoad;
    },
  },
  actions: {
    async loadServiceRequestListing() {
      if (this.pagination.pageNumber === 1) {
        this.fistPageLoading = true;
      }
      await MemberV2Api.serviceLogs({
        pageNumber: this.pagination.pageNumber,
        pageSize: this.pagination.pageSize,
        dateFrom: this.dateRange.startDate?.toISOString(),
        dateTo: this.dateRange.endDate?.toISOString(),
      })
        .then((res) => {
          this.rawData = res;
          this.list.push(...(res.data ?? []));
          this.setPagination(res);
          this.firstLoad = true;
        })
        .finally(() => {
          this.fistPageLoading = false;
        });
    },
    async loadMore() {
      if (this.pagination.pageNumber === this.pagination.totalPage) return;
      this.pagination.pageNumber++;
      await this.loadServiceRequestListing();
    },
    setPagination(res: ServiceLogRecordResponseGeneralListResponse) {
      this.pagination = {
        pageNumber: res.pageNumber ?? 1,
        pageSize: res.pageSize,
        totalCount: res.totalCount,
        totalPage: res.totalPage,
      };
    },
  },
});
