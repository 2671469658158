import { IBankInfo } from "@/interface/front-desk/claimSubmitInterface";
import { IJwtDecodeInterface } from "@/interface/IJwtDecodeInterface";
import { IClaimStatus, IClaimType } from "@/interface/member/IClaimSubmit";
import { IMultiSelect } from "@/interface/multiSelectInterface";
import en from "@/lang/en.json";
import km from "@/lang/km.json";
import router from "@/router";
import { AxiosResponse } from "axios";
import { Modal } from "bootstrap";
import jwt_decode from "jwt-decode";
import moment from "moment";
import getLocalStorageService from "../services/getLocalStorageService";
import { brRouteName, fdRouteName, hrRouteName, mbRouteName } from "./util";
import * as Yup from "yup";
import { RouteRecordRaw } from "vue-router";
import { onBeforeMount, onBeforeUnmount } from "vue";
import { useMemberGlobalStore } from "@/store/member/UseMemberGlobalStore";
import pluralize from "pluralize";
import DeviceDetector from "device-detector-js";
import * as html2pdf from "html2pdf.js";
import { IYubErrorInner } from "@/interface/IYubValidate";
import { EnvironmentEnum, ProjectsEnum } from "@/core/data/data";
import { IUploadFileUniqueName } from "@/interface/imageUploadInterface";
import { string } from "yup";
import { OTPScreenDataOriginateScreen } from "@/interface/IOTPScreenData";

const basePath =
  (process.env.VUE_APP_API_URL?.indexOf("/") ?? -1) == 0
    ? window.location.origin + process.env.VUE_APP_API_URL
    : process.env.VUE_APP_API_URL;

export const routeActive = (arr: string[], systemType = "") => {
  if (systemType === "fd") {
    return arr.map((e: string) => {
      e = fdRouteName(e);
      return e;
    });
  } else if (systemType === "mb") {
    return arr.map((e: string) => {
      e = mbRouteName(e);
      return e;
    });
  } else if (systemType === "hr") {
    return arr.map((e: string) => {
      e = hrRouteName(e);
      return e;
    });
  } else if (systemType === "br") {
    return arr.map((e: string) => {
      e = brRouteName(e);
      return e;
    });
  }
  return [];
};

export const openSuccessModal = () => {
  const profileUpdateSuccess = new Modal(
    document.getElementById("kt_modal_update_success_modal") as Element,
    { keyboard: false, backdrop: "static" }
  );
  profileUpdateSuccess.show();
};

export const dateFormatter = (
  strDate: moment.MomentInput = null,
  isNow = false
) => {
  if (isNow || !strDate) {
    return undefined;
  }
  const resultDate = moment(strDate, "YYYY-MM-DD").format("DD MMM YYYY");
  return resultDate === "Invalid date" ? strDate : resultDate;
};

export function dateFormatterV2(
  date: string | Date | undefined,
  formatter?: string | undefined
) {
  if (!date) {
    return "";
  }
  try {
    return moment(date).format(formatter ?? "yyyy/MM/dd");
  } catch (error) {
    return "";
  }
}

export function downloadFile(resp: AxiosResponse, fileName: string): void {
  const url = window.URL.createObjectURL(new Blob([resp.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName); //or any other extensions
  document.body.appendChild(link);
  link.click();
  link.remove();
}

/**
 * Download file from new api
 * @param resp
 * @param fileName
 * @returns void
 * @example downloadFileNewApi(resp, "file.pdf")
 */
export function downloadFileNewApi(resp: BlobPart, fileName: string): void {
  const url = window.URL.createObjectURL(new Blob([resp]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName); //or any other extensions
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export const round2Decimal = (num: number) => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

export const animateCSS = (element, animation, prefix = "animate__") =>
  // We create a Promise and return it
  new Promise((resolve, reject) => {
    const animationName = `${prefix}${animation}`;
    const node = document.querySelector(element);

    node.classList.add(`${prefix}animated`, animationName);

    // When the animation ends, we clean the classes and resolve the Promise
    function handleAnimationEnd(event) {
      event.stopPropagation();
      node.classList.remove(`${prefix}animated`, animationName);
      resolve("Animation ended");
    }

    node.addEventListener("animationend", handleAnimationEnd, {
      once: true,
    });
  });

export function isContainSpecialCharacter(_currentValue: string) {
  return /[~!@#$%^&*()_+=|:;,./?-]+/.test(_currentValue);
}

export function isContainNumber(_currentValue: string) {
  return /\d+/.test(_currentValue);
}

export function isContainUppercase(_currentValue: string) {
  return /[A-Z]+/.test(_currentValue);
}

export function isContainLowercase(_currentValue: string) {
  return /[a-z]+/.test(_currentValue);
}

export function validateArrayFunction(_currentValue: string) {
  let errorArray = [{ id: "", textEn: "", textKm: "" }];
  errorArray = [];
  let i = 0;
  if (_currentValue.length < 8) {
    errorArray.push({
      id: String(++i),
      textEn: "Password must be at least 8 characters in length..",
      textKm: getTranslate("Password must be at least 8 characters in length"),
    });
  }
  if (_currentValue.length > 14) {
    errorArray.push({
      id: String(++i),
      textEn: "Password must be up to 14 characters in length..",
      textKm: "ពាក្យសង្ងាត់ត្រូវតែមានប្រវែងយ៉ាងច្រើនបំផុត 14 តួរអក្សរ",
    });
  }
  if (
    !isContainLowercase(_currentValue) ||
    !isContainUppercase(_currentValue)
  ) {
    errorArray.push({
      id: String(++i),
      textEn: "Password must contain both upper-case and lower-case.",
      textKm: getTranslate(
        "Password must contain both uppercase and lowercase letters"
      ),
    });
  }
  if (!isContainNumber(_currentValue)) {
    errorArray.push({
      id: String(++i),
      textEn:
        getTranslate("Password must contain one of the following characters") +
        ": 1234567890",
      textKm: "ពាក្យសម្ងាត់ត្រូវតែមានតួរអក្សរមួយដូចខាងក្រោម៖ ១២៣៤៥៦៧៨៩០",
    });
  }
  if (!isContainSpecialCharacter(_currentValue)) {
    errorArray.push({
      id: String(++i),
      textEn:
        getTranslate("Password must contain one of the following characters") +
        ": ~!@#$%^&*()_-+=|:;,./?",
      textKm:
        "ពាក្យសម្ងាត់ត្រូវតែមានតួរអក្សរមួយដូចខាងក្រោម៖ ~!@#S%^&*()_-+=|:;,./?",
    });
  }
  return errorArray;
}

export const getTranslate = (
  criteria: string,
  isUpperCase = false,
  locale = "km"
) => {
  const lang = locale == "km" ? km : en;
  if (isUpperCase) {
    return String(
      lang[criteria.trim()] ?? "បកប្រែមិនត្រឹមត្រូវ..."
    ).toUpperCase();
  }
  return lang[criteria.trim()]
    ? lang[criteria.trim()]
    : "បកប្រែមិនត្រឹមត្រូវ...";
};

export function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
}

export function insertDataToClaimTypeSelect(
  claimTypes: IClaimType[],
  claimTypeSelect: IMultiSelect[]
) {
  claimTypes.forEach((e) => {
    claimTypeSelect.push({
      label: e.claimType,
      labelAlt: e.claimTypeAlt,
      value: e.claimTypeId,
      checked: false,
    });
  });
  return claimTypeSelect;
}

export function insertDataToClaimStatusSelect(
  claimStatuses: IClaimStatus[],
  claimStatusSelect: IMultiSelect[]
) {
  claimStatuses.forEach((e) => {
    claimStatusSelect.push({
      label: e.claimStatus,
      labelAlt: e.claimStatusAlt,
      value: e.claimStatusId,
      checked: false,
    });
  });
  return claimStatusSelect;
}

export const getBasePath = () => {
  return (process.env.VUE_APP_API_URL?.indexOf("/") ?? -1) == 0
    ? window.location.origin + process.env.VUE_APP_API_URL
    : process.env.VUE_APP_API_URL;
};

export const jwtDecodeFromToken = () => {
  const token = getLocalStorageService()?.GetToken();
  if (token) {
    try {
      return jwt_decode(String(token)) as IJwtDecodeInterface;
    } catch (error) {
      console.error(error);
    }
  }
  return [];
};

export const defaultPhoneNumberPlaceholder = () => {
  return "Ex: 086 999 242";
};

export const dateAndTimeFormater = (strDate: moment.MomentInput) => {
  return moment(strDate).format("DD MMM YYYY hh:mm A");
};

export const stringNumberRegex = () => {
  return /^\d+$/;
};

export const invalidPhoneNumberMessage = () => {
  return {
    message: {
      message: "Invalid Phone Number.",
      messageAlt: "លេខទូរស័ព្ទមិនត្រឹមត្រូវ",
    },
  };
};

export const backOneStep = () => {
  return router.go(-1);
};

export const toSnakeCase = (str: string) => {
  const splitArr = str.toLocaleLowerCase().trim().split(/\s+/);
  return splitArr.join("_");
};

export const isArrayIsNotEmpty = (arr) => {
  if (Array.isArray(arr)) {
    return arr.length > 0;
  }
  return false;
};

export const isBankValid = (bankInfo: IBankInfo) => {
  const bankInfoValidate = Yup.object().shape({
    bankName: Yup.string().required(),
    bankAccountNumber: Yup.string().required(),
    bankAccountName: Yup.string().required(),
  });

  return bankInfoValidate.isValid(bankInfo);
};

function findRouteObjectByPropertyKey(
  routeObject: RouteRecordRaw[] | null,
  propertyKey = "name",
  routeName: string
) {
  if (!routeObject) {
    return null;
  }

  let result: RouteRecordRaw | null = null;
  for (
    let _propertyIndex = 0;
    _propertyIndex < routeObject.length;
    _propertyIndex++
  ) {
    if (routeObject[_propertyIndex][propertyKey] === routeName) {
      result = routeObject[_propertyIndex];
    } else if (routeObject[_propertyIndex].children) {
      result = findRouteObjectByPropertyKey(
        routeObject[_propertyIndex].children as RouteRecordRaw[],
        propertyKey,
        routeName
      );
    }
    if (result) {
      return result;
    }
  }
  return result;
}

export const getListOfHighlightRouteName = (
  routeObject: RouteRecordRaw[] | null,
  routeName: string,
  propertyKey = "name"
) => {
  const getRouteObject =
    findRouteObjectByPropertyKey(routeObject, propertyKey, routeName) ?? null;
  let result: string[] = [];
  for (const _property in getRouteObject) {
    if (_property === propertyKey) {
      result.push(<string>getRouteObject[propertyKey]);
      if (!getRouteObject?.children) break;
    } else if (_property === "children") {
      for (const child of getRouteObject[_property]) {
        result = result.concat(child[propertyKey]);
        result = result.concat(
          getListOfHighlightRouteName(routeObject, child["name"], propertyKey)
        );
      }
    }
  }

  // remove only duplicate value from result
  return result.filter((item, index) => result.indexOf(item) === index);

  // return result;
};

export const getIconLinkPhaseV2 = (iconName: string) => {
  return "/media/phase-2/" + iconName;
};

export const grayBackground = () => {
  onBeforeMount(() => {
    useMemberGlobalStore().isWhiteBackground = false;
  });

  onBeforeUnmount(() => {
    useMemberGlobalStore().isWhiteBackground = true;
  });
};

export function openModalById(elementId = "congratulation__modal") {
  const modalDiv = document.getElementById(elementId) as Element;
  const modal = new Modal(modalDiv);
  modal.show();
}

// convert date to iso string format without timezone offset
export const convertDateToISOString = (date: Date | undefined) => {
  if (!date) return "";
  //current date hour to 0 with UTC+7 timezone
  date.setHours(7, 0, 0, 0);
  return new Date(
    date.getTime() - date.getTimezoneOffset() * 60 * 1000
  ).toISOString();
};

/**
 * convert date to iso string format without timezone offset
 * @param date
 * @returns date in iso string format
 * @example
 * as +7 timezone date
 * convertDateToISO(new Date(2020, 1, 1)) // Sat Feb 01 2020 07:00:00 GMT+0700 (Indochina Time)
 * convertDateToISO(new Date(2020, 1, 1, 7, 0, 0, 0)) // Sat Feb 01 2020 07:00:00 GMT+0700 (Indochina Time)
 */
export const convertDateToISO = (date: Date) => {
  //current date hour to 0 with UTC+7 timezone
  return new Date(date.toISOString().replace(/T.*$/, "T00:00:00.000Z"));
};

// convert to date iso with time T00:00:00.000Z (zero time)
export const convertDateToISOWithZeroTime = (date: Date) => {
  return new Date(date.toISOString().replace(/T.*$/, "T00:00:00.000Z"));
};

export function convertInternationalToPhoneNumber(
  phoneNumber: string | undefined
) {
  if (!phoneNumber) return "";
  return phoneNumber.replace("+855", "0");
}

export function differenceInDays(dateLeft: Date, dateRight: Date) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  const utc1 = Date.UTC(
    dateLeft.getFullYear(),
    dateLeft.getMonth(),
    dateLeft.getDate()
  );
  const utc2 = Date.UTC(
    dateRight.getFullYear(),
    dateRight.getMonth(),
    dateRight.getDate()
  );
  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

/**
 * pluralize text
 * @param text
 * @param count
 * @returns pluralized text
 * @example
 * pluralNormalizer("beneficiary", 1) // "beneficiary"
 * pluralNormalizer("beneficiary", 2) // "beneficiaries"
 * pluralNormalizer("beneficiary", 0) // "beneficiary"
 */
export function pluralNormalizer(text: string, count: number) {
  if (count < 1) {
    return pluralize.singular(text);
  }
  return pluralize(text, count);
}

/**
 * convert base64 to png object url
 * @param base64
 * @returns object url
 * @example
 * base64ToPngObjectUrl("iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAA") // "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAA"
 */
export function base64ToPngObjectUrl(base64: string | undefined) {
  if (!base64) return "";
  const urlCreator = window.URL || window.webkitURL;
  let byteCharacters = "";
  try {
    byteCharacters = atob(base64);
  } catch (e) {
    return "";
  }
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "image/png" });
  return urlCreator.createObjectURL(blob);
}

export function isNotEmpty(param: unknown) {
  return param !== "" && param !== null && param !== undefined;
}

export async function getDeviceInfo() {
  interface IpInfo {
    status?: string;
    country?: string;
    countryCode?: string;
    region?: string;
    regionName?: string;
    city?: string;
    zip?: string;
    lat?: number;
    lon?: number;
    timezone?: string;
    isp?: string;
    org?: string;
    as?: string;
    query?: string;
  }

  const ipInfo: IpInfo = await getIpInfo().catch(() => ({} as IpInfo));
  const deviceDetector = new DeviceDetector();
  const deviceDetectorResult = deviceDetector.parse(getUserAgent());
  return { deviceInfo: deviceDetectorResult, ipInfo: ipInfo };
}

/**
 * get user agent
 * @returns user agent
 * @example
 * Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/87.0.4280.88 Safari/537.36
 */
export function getUserAgent() {
  return navigator.userAgent;
}

/**
 * get ip info from ip-api.com
 * @returns ip info
 * @example
 * {
 * "status": "success",
 * "country": "Cambodia",
 * "countryCode": "KH",
 * "region": "12",
 * "regionName": "Phnom Penh",
 * "city": "Phnom Penh",
 * "zip": "",
 * "lat": 11.5563,
 * "lon": 104.922,
 * "timezone": "Asia/Phnom_Penh",
 * "isp": "S.I Group",
 * "org": "",
 * "as": "AS131207 SINET, Cambodia's specialist Internet and Telecom Service Provider.",
 * "query": "180.178.127.206"
 * }
 */
export async function getIpInfo() {
  const response = await fetch("http://ip-api.com/json/");
  return response.json();
}

/**
 * check if string is valid iso date
 * @param date
 * @returns true if valid, false if not
 * @example
 * 2021-01-01T00:00:00.000Z => true
 * 2021-01-01 => false
 * 2021-01-01T00:00:00.000 => false
 * undefined => false
 */
export const isValidIsoDateString = (date: string | undefined): boolean => {
  if (!date) return false;
  try {
    const dateObj = new Date(date);
    return dateObj.toISOString() === date;
  } catch (e) {
    return false;
  }
};

/**
 * space string in every n digit
 * @param input string to be spaced
 * @param n number of digit
 * @param isSkipLastNPlusOneDigit if true, it will skip last n+1 digit
 * @returns spaced string
 * @example
 * 1234567890 => 123 456 789 0 (n = 3, isSkipLastNPlusOneDigit = false) or 123 456 7890 (n = 3, isSkipLastNPlusOneDigit = true)
 */
export function spaceStringEveryNDigit(
  input: string,
  n: number,
  isSkipLastNPlusOneDigit = false
) {
  if (!input) return "";
  const numberArray = input.split("");
  const result: string[] = [];
  for (let i = 0; i < numberArray.length; i++) {
    if (isSkipLastNPlusOneDigit) {
      if (i % n === 0 && i !== 0 && i !== numberArray.length - 1) {
        result.push(" ");
      }
    } else {
      if (i % n === 0 && i !== 0) {
        result.push(" ");
      }
    }
    result.push(numberArray[i]);
  }
  return result.join("");
}

/**
 * get list of params from url
 * @returns list of params
 * @example
 * http://localhost:3000/?a=1&b=2 => {a: "1", b: "2"}
 */
export function getListOfParamsFromUrl() {
  const urlParams = new URLSearchParams(window.location.search);
  const params: { [key: string]: string } = {};
  urlParams.forEach((value, key) => {
    params[key] = value;
  });
  return params;
}

/**
 * async local storage
 * @returns {string | null} async local storage
 * @example
 * await asyncLocalStorage.getItem("getDeviceInfo").then();
 */
export const asyncLocalStorage = {
  setItem: function (key, value) {
    return Promise.resolve().then(function () {
      localStorage.setItem(key, value);
    });
  },
  getItem: function (key) {
    return Promise.resolve().then(function () {
      return localStorage.getItem(key);
    });
  },
};

export function isNegativeNumber(value: number) {
  return value < 0;
}

export function absoluteNumber(value: number) {
  return Math.abs(value);
}

/**
 * fix html canvas not render space correctly
 * @param value
 * @returns fixed value
 * @example
 * "123 456 789" => "123&#8202; &#8202;456&#8202; &#8202;789"
 */
export function fixSpaceInDownloadCard(value: string | undefined) {
  if (!value) return "";
  return value.replaceAll(" ", "&#8202; &#8202;");
}

/**
 * download card from div as image
 * @param elementId
 * @param fileName
 * @example
 * downloadCardFromDiv("credit__card", "Member #" + "Member__id" + " CARDS.png")
 */
export function downloadCardFromDiv(
  elementId = "credit__card",
  fileName: string = "Member #" + "Member__id" + " CARDS.png"
) {
  const element = document.getElementById(elementId);
  const options = {
    margin: 5,
    image: { type: "png", quality: 1 },
    html2canvas: {
      scale: 3,
      letterRendering: true,
      useCORS: true,
      windowWidth: 480,
      width: 480,
      windowHeight: 275,
      height: 275,
      y: -4,
      x: 137.5,
    },
  };

  if (!element) return;
  // New Promise-based usage:

  renderImageDownloadCardAsImage(options, element as HTMLElement);

  function renderImageDownloadCardAsImage(
    options,
    element: HTMLElement | null
  ) {
    if (!element) return;
    html2pdf()
      .set(options)
      .from(element)
      .outputImg()
      .then((img) => imgDivToImage(img));
  }

  function imgDivToImage(img: HTMLImageElement) {
    const link = document.createElement("a");
    link.download = fileName;
    link.href = img.src;
    link.click();
  }
}

/**
 * close modal by id
 * @param elementId
 * @example
 * closeModalById("congratulation__modal")
 */
export function closeModalById(elementId = "congratulation__modal") {
  const modalDiv = document.getElementById(elementId) as Element;
  const modal = Modal.getInstance(modalDiv);
  modal?.hide();
}

/**
 * limit string by number of character
 * @param str
 * @param limit
 * @returns string
 * @example
 * stringLimit("1234567890", 5) => "12345..."
 */
export function stringLimit(str: string, limit: number) {
  if (!str) return "";
  if (str.length > limit) {
    return str.slice(0, limit) + "...";
  }
  console.log(str);
  return str;
}

export async function yupValidateForm(validateSchema: any, formValue: any) {
  let result: unknown = {};
  await validateSchema
    .validate(formValue, { abortEarly: false })
    .then(() => {
      // do nothing
    })
    .catch((err) => {
      // get error message from yup error
      const errorArr = JSON.parse(JSON.stringify(err))
        .inner as IYubErrorInner[];
      result = errorArr
        //distinct and get first duplicate error by errorArr.path
        // .filter(
        //   (element, index, all) =>
        //     all.findIndex((t) => t.path === element.path) === index
        // )
        // map element path as key and message as value
        .map((element) => {
          return { [element.path]: element.message };
        })
        // merge all object in array to one object
        .reduce((acc, cur) => {
          return { ...acc, ...cur };
        }, {});
    });
  return result;
}

/** check if object is empty
 * @param obj
 * @returns boolean
 * @example
 * isObjectEmpty({}) => true
 * isObjectEmpty({a: 1}) => false
 */
export const isObjectEmpty = (obj) => Object.keys(obj).length === 0;

// scroll to error field with smooth effect
export function scrollToErrorV1(validateResult) {
  if (!isObjectEmpty(validateResult)) {
    // scroll to error field
    const firstErrorField = Object.keys(validateResult)[0];
    const firstErrorFieldElement = document.querySelector(
      `[name="${firstErrorField}"]`
    );
    firstErrorFieldElement?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }
}

/** check if env is local
 * @returns boolean
 * @example
 * isEnvLocal() => true
 */
export function isEnvLocal() {
  return process.env.NODE_ENV === EnvironmentEnum.Local;
}

/** get access token from local storage
 * @returns void
 */
export function clearAllAccessToken() {
  enumToArray<string>(ProjectsEnum).forEach((project) => {
    localStorage.removeItem(`${project}_ACCESS_TOKEN`);
  });
  localStorage.removeItem("id_token");
}

/** convert enum to array
 * @template T
 * @param {T} enumType
 * @returns {T[]}
 * @param enumType
 * @example
 * enumToArray<T>(ProjectsEnum) => ["Member", "Admin"] as <T>[]
 */
export function enumToArray<T>(enumType) {
  return Object.keys(enumType).map((key) => enumType[key]) as T[];
}

/** get access token from local storage
 * @returns string
 */
export function getAccessToken() {
  const firstToken = localStorage.getItem(
    `${getCurrentProject()}_ACCESS_TOKEN`
  );
  const secondToken = localStorage.getItem("id_token");
  if (!firstToken) {
    return secondToken ? secondToken : "";
  }
  return firstToken ? firstToken : "";
}

export function getCurrentProject() {
  // get current full url
  const currentUrl = window.location.href;
  /** get current project from url
   * @example
   * "http://192.168.1.138:8081/mb/preview-file" => "mb"
   */
  const currentProject = currentUrl.split("/")[3];
  return currentProject.toUpperCase();
}

/** access file by url
 * @returns string
 * @param fileLink
 */
export function accessFileByUrl(fileLink: string) {
  // check if file link is valid
  return fileLink.concat(isEnvLocal() ? `&Token=${getAccessToken()}` : "");
}

export const imageReader = (
  meta: IUploadFileUniqueName,
  isAttachToken = true
) => {
  let link = "";
  if (meta.uniqueName) {
    link = basePath + "/Image/File?FileName=" + meta.uniqueName;
  } else {
    link = basePath + "/Image/File?FileName=" + meta.fileName;
  }
  if (isAttachToken) {
    return accessFileByUrl(link);
  }
  return link;
};

export async function resetPiniaFromRoute(
  piniaObj: { $reset: () => void },
  isForceReset = false
) {
  const isResetPinia = router.currentRoute.value.query.resetPinia === "yes";
  if (isResetPinia || isForceReset) {
    piniaObj.$reset();
    router
      .push({
        name: String(router.currentRoute.value.name),
        params: router.currentRoute.value.params,
        query: { ...router.currentRoute.value.query, resetPinia: "no" },
        force: true,
        replace: true,
      })
      .then();
  }
}

export function getCurrentTzOffset() {
  return (new Date().getTimezoneOffset() / 60) * -1;
}

export function uTCtoLocalIso(data: Date | undefined) {
  if (!data) return "";
  return moment(data).add(getCurrentTzOffset(), "hours").toISOString();
}

export function scrollToErrorV2(selectors = ".scroll_if_error") {
  const errorElements = document.querySelectorAll(selectors);
  if (errorElements.length > 0) {
    errorElements[0].scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }
}

export function downloadImageFromBase64(base64: string, fileName: string) {
  const link = document.createElement("a");
  link.href = base64;
  link.download = fileName;
  link.click();
}

/**
 * download card from div as image
 * @param elementId
 * @param fileName
 * @example
 * downloadCardFromDiv("credit__card", "Member #" + "Member__id" + " CARDS.png")
 */
export async function htmlToImageElement(
  elementStr,
  fileName: string = "Member #" + "Member__id" + " CARDS.png"
) {
  const document = new DOMParser().parseFromString(elementStr, "text/html");
  const element = document.querySelector("html");
  const body = document.querySelector("body");
  const html = document.querySelector("html");
  if (!body) return;
  body.style.width = "475px";
  body.style.height = "350px";
  if (!html) return;
  html.style.width = "475px";
  html.style.height = "350px";
  const options = {
    margin: 5,
    image: { type: "png", quality: 1 },
    html2canvas: {
      scale: 3,
      letterRendering: true,
      useCORS: true,
      windowWidth: 475,
      width: 475,
      windowHeight: 285,
      height: 285,
      y: 0,
      x: 0,
      dpi: 96,
      logging: false,
    },
  };

  if (!element) return;
  return html2pdf().set(options).from(element).outputImg();
}

export function isiOS() {
  return navigator.userAgent.match(/ipad|ipod|iphone/i);
}

export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function localToIntlPhoneNumber(
  phoneNumber: string | undefined,
  countryCode = "+855"
) {
  if (!phoneNumber) return "";
  // replace first 0 with country code
  if (phoneNumber.startsWith("0")) {
    return phoneNumber.replace("0", countryCode);
  }

  return phoneNumber;
}

export function intlToLocalPhoneNumber(phoneNumber: string | undefined) {
  if (!phoneNumber) return "";
  // replace first 0 with country code
  if (phoneNumber.startsWith("+855")) {
    return phoneNumber.replace("+855", "0");
  }

  return phoneNumber;
}

export function blobToBase64(blob: Blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

export function isMoreThan90Days(_dateObj: string) {
  const _date = new Date(_dateObj);
  // check if input date is older than current date -90 day JavaScript
  const _date90DaysAgo = new Date();
  _date90DaysAgo.setDate(_date90DaysAgo.getDate() - 90);
  if (_date < _date90DaysAgo) {
    return true;
  }
  return false;
}

export function colorCodeByClaimStatus(status: string | undefined) {
  if (!status) return "";
  switch (status) {
    case "Pending":
      return "#F7C926";
    case "Approved":
      return "#3DA758";
    case "Declined":
      return "#5C636B";
    default:
      return "";
  }
}

export function htmlDecodeOnlyProduction(text: string | undefined | null) {
  if (!text) return "";
  if (process.env.NODE_ENV === "production") {
    const doc = new DOMParser().parseFromString(text, "text/html");
    return doc.documentElement.textContent ?? "";
  }
  return text;
}

export function formatPreviewV1(date) {
  // padding 0
  const day = date.getDate().toString().padStart(2, "0");
  // padding 0
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export function getIsApi() {
  const originateScreen = getLocalStorageService()?.GetOTPOriginateScreen();
  let isApi = true;
  const isApiArr = [
    OTPScreenDataOriginateScreen.FDSI,
    OTPScreenDataOriginateScreen.FDResetP,
    OTPScreenDataOriginateScreen.MBSI,
    OTPScreenDataOriginateScreen.MBResetP,
    OTPScreenDataOriginateScreen.HRSI,
    OTPScreenDataOriginateScreen.HRResetP,
    // OTPScreenDataOriginateScreen.MBRG,
  ];
  if (isApiArr.includes(originateScreen ?? "xxxx")) {
    isApi = false;
  }
  return isApi;
}
