import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import frontDeskRouteList from "./fd";
import hr from "./hr";
import global from "./global";
import member from "./member";
import PermissionMiddleware from "@/middlewares/PermissionMiddleware";
import br from "@/router/br";

const routes: Array<RouteRecordRaw> = [
  ...global,
  ...frontDeskRouteList,
  ...hr,
  ...member,
  ...br,
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  await PermissionMiddleware(to, from, next);
});

router.afterEach(() => {
  window.scrollTo(0, 0);
});
export default router;
