import { createI18n } from "vue-i18n";
// import messagesData from "@/core/data/data";

// const messages = {
//   en: {
//     "CUSTOMER SERVICE": "CUSTOMER SERVICE",
//   },
//   km: {},
// };

const i18n = createI18n({
  legacy: false,
  locale: "en",
  globalInjection: true,
  messages: {},
});

export default i18n;
