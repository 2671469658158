import ApiService from "@/core/services/ApiService";
import JwtServiceMember from "@/core/services/JwtServiceMember";
import type { IProfile } from "@/interface/front-desk/IProfile";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { User, UserMemberAuthInfo } from "@/core/interface";
import ApiServiceMember from "@/core/services/ApiServiceMember";
import getLocalStorageService from "@/core/services/getLocalStorageService";
import { MemberApi } from "@/api";

@Module
export default class AuthModule
  extends VuexModule
  implements UserMemberAuthInfo
{
  errors = {};
  user = {} as User;
  isAuthenticatedMember = getLocalStorageService()?.GetToken() !== undefined;
  profile = {} as IProfile;
  notificationMember = [] as Notification[];

  get currentUserMember(): User {
    return this.user;
  }

  get isUserAuthenticatedMember(): boolean {
    return this.isAuthenticatedMember;
  }

  get getErrorsMember() {
    return this.errors;
  }

  get getProfileMember() {
    return this.profile;
  }

  get getNotificationMember() {
    return this.notificationMember as Notification[];
  }

  @Mutation
  [Mutations.SET_MEMBER_NOTIFICATION](payload: Notification[]) {
    this.notificationMember = payload;
    this.isAuthenticatedMember = true;
  }

  @Mutation
  [Mutations.SET_PROFILE_MEMBER](payload: IProfile) {
    this.profile = {
      ...payload,
    };
  }

  @Mutation
  [Mutations.SET_AUTH_MEMBER](payload) {
    this.isAuthenticatedMember = true;
    this.errors = {};
    JwtServiceMember.saveToken(payload?.accessToken);
    getLocalStorageService()?.SaveToken(payload?.accessToken);
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticatedMember = false;
    this.user = {} as User;
    this.errors = [];
    this.profile = {} as IProfile;
    this.notificationMember = [];
    JwtServiceMember.destroyToken();
  }

  @Action
  [Actions.MEMBER_LOGOUT]() {
    return ApiService.get(`/Account/Logout`)
      .then(() => {
        this.context.commit(Mutations.PURGE_AUTH);
      })
      .catch(() => {
        this.context.commit(Mutations.PURGE_AUTH);
      });
  }

  @Action({ rawError: true })
  [Actions.REQUEST_MEMBER_NOTIFICATION]() {
    return new Promise((resolve, reject) => {
      ApiServiceMember.get("/Member/MemberDetail")
        .then((data) => {
          this.context.commit(Mutations.SET_PROFILE_MEMBER, data.data);
        })
        .catch((error) => {
          console.log(error);
        });

      ApiServiceMember.get("/Account/LatestNotification")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_MEMBER_NOTIFICATION, data);
          resolve(data as Notification[]);
        })
        .catch(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, data);
          reject(data);
        });
    });
  }
}
