import ApiService from "@/core/services/ApiService";
// import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
// import { AxiosRequestConfig } from "axios";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
// import { INotification } from "@/interface/front-desk/notificationInterface";
import type { ISubmitClaimRequest } from "@/interface/front-desk/claimSubmitInterface";
import { AccountApi, AccountGetLatestNotificationResponseItem } from "@/api";

@Module
export default class AppModule extends VuexModule {
  notifications = [] as AccountGetLatestNotificationResponseItem[];
  dataForSubmit = {} as ISubmitClaimRequest;
  currentSystemType = "" as string;

  /**
   * Verify user authentication
   * @returns boolean
   */
  get getNotification() {
    return this.notifications;
  }

  get getDataForSubmit() {
    return this.dataForSubmit;
  }

  get getCurrentSystem() {
    return this.currentSystemType;
  }

  @Mutation
  [Mutations.SET_SYSTEM_TYPE](name) {
    this.currentSystemType = name;
  }

  @Mutation
  [Mutations.SET_NOTIFICATION](payload: Notification[]) {
    this.notifications = payload;
  }

  @Mutation
  [Mutations.SET_DATA_BEFORE_SUBMIT](payload: ISubmitClaimRequest) {
    this.dataForSubmit = payload;
  }

  @Action({ rawError: true })
  [Actions.STOTE_DATA_BEFORE_SUBMIT](data) {
    return new Promise((resolve) => {
      this.context.commit(Mutations.SET_DATA_BEFORE_SUBMIT, data);
      resolve(data);
    });
  }

  @Action({ rawError: true })
  [Actions.REQUEST_NOTIFICATION]() {
    return new Promise((resolve, reject) =>
      AccountApi.latestNotification()
        .then((data) => {
          this.context.commit(Mutations.SET_NOTIFICATION, data);
          resolve(data);
        })
        .catch((error) => {
          this.context.commit(Mutations.SET_ERROR, error?.data);
          reject(error?.data);
        })
    );
  }
}
