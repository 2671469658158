/** Generate by swagger-axios-codegen */
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';
import getApiService from './core/services/getApiService';

export interface IRequestOptions extends AxiosRequestConfig {
  /** only in axios interceptor config*/
  loading?: boolean;
  showError?: boolean;
  isAuth? :boolean;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
  /** only in axios interceptor config*/
  loading?: boolean;
  isAuth? :boolean;
  showError?: boolean;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  serviceOptions.axios = getApiService()?.instance;
  if(serviceOptions.isAuth ?? true){
    getApiService()?.addAuth(configs);
  }
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = {
    loading: serviceOptions.loading,
    showError: serviceOptions.showError,
    ...options,
    method,
    url
  };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export const basePath = (process.env.VUE_APP_API_URL?.indexOf('/') ?? -1) == 0  ? window.location.origin + process.env.VUE_APP_API_URL : process.env.VUE_APP_API_URL;

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class AccountApi {
  /**
   *
   */
  static getLogin(
    params: {
      /**  */
      body?: AccountdummyRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountdummyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Account/GetLogin';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getloginResetPassword(
    params: {
      /**  */
      body?: AccountdummyRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountdummyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Account/GetloginResetPassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static otpByUserPwd(
    params: {
      /**  */
      body?: AccountFDOTPByUserPwdRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountFDOTPByUserPwdResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Account/FD/OTPByUserPwd';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static otpByUserPwd1(
    params: {
      /**  */
      body?: AccountFDOTPByUserPwdRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountFDOTPByUserPwdResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Account/CX/OTPByUserPwd';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static otpByPhonePwd(
    params: {
      /**  */
      body?: AccountMBOTPByUserPwdRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountMBOTPByPhonePwdResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Account/MB/OTPByPhonePwd';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static loginByUserOtp(
    params: {
      /**  */
      body?: AccountFDLoginByUserOTPRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountFDLoginByUserOTPResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Account/FD/LoginByUserOTP';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static loginByUserOtp1(
    params: {
      /**  */
      body?: AccountFDLoginByUserOTPRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountFDLoginByUserOTPResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Account/CX/LoginByUserOTP';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static loginByPhoneOtp(
    params: {
      /**  */
      body?: AccountMBLoginByPhoneOTPRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountMBLoginByPhoneOTPResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Account/MB/LoginByPhoneOTP';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static refreshToken(
    params: {
      /**  */
      body?: RefreshTokenRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountMBLoginByPhoneOTPResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Account/MB/RefreshToken';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static profile(options: IRequestOptions = {}): Promise<AccountProfileResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Account/Profile';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static logout(options: IRequestOptions = {}): Promise<AccountLogoutResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Account/Logout';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static resendOtp(
    params: {
      /**  */
      body?: AccountResendOTPRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountResendOTPResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Account/ResendOTP';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static requestOtp(
    params: {
      /**  */
      isApi?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountResendOTPResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Account/RequestOTP';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { IsApi: params['isApi'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static latestNotification(
    params: {
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountGetLatestNotificationResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Account/LatestNotification';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { PageNumber: params['pageNumber'], PageSize: params['pageSize'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static markNotificationRead(
    params: {
      /**  */
      body?: AccountMarkNotificationReadRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GeneralMessageResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Account/MarkNotificationRead';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static resetPassword(
    params: {
      /**  */
      body?: AccountFDResetPasswordRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountFDResetPasswordResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Account/FD/ResetPassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static resetPasswordToken(
    params: {
      /**  */
      body?: AccountMBResetPasswordTokenRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountMBResetPasswordTokenResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Account/FD/ResetPasswordToken';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static resetPasswordToken1(
    params: {
      /**  */
      body?: AccountMBResetPasswordTokenRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountMBResetPasswordTokenResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Account/MB/ResetPasswordToken';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static resetPassword1(
    params: {
      /**  */
      body?: AccountMBResetPasswordRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountMBResetPasswordResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Account/MB/ResetPassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static setNewPassword(
    params: {
      /**  */
      body?: AccountMBSetNewPasswordRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountMBSetNewPasswordResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Account/MB/SetNewPassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static setNewPassword1(
    params: {
      /**  */
      body?: AccountFDSetNewPasswordRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountMBSetNewPasswordResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Account/FD/SetNewPassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static uploadProfilePhoto(
    params: {
      /**  */
      file?: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ImageUploadResponseFile> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Account/UploadProfilePhoto';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changePassword(
    params: {
      /**  */
      body?: AccountChangePasswordRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountChangePasswordResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Account/ChangePassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static hrotpByMailPwd(
    params: {
      /**  */
      body?: AccountHROTPByMailPwdRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountHROTPByMailPwdResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Account/HROTPByMailPwd';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static hrLoginByMailOtp(
    params: {
      /**  */
      body?: AccountHRLoginWithMailOTPRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountHRLoginWithMailOTPResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Account/HRLoginByMailOTP';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static hrResetPassword(
    params: {
      /**  */
      body?: AccountHRResetPasswordRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountHRResetPasswordResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Account/HRResetPassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static hrSetNewPassword(
    params: {
      /**  */
      body?: AccountMBSetNewPasswordRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountMBSetNewPasswordResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Account/HRSetNewPassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static hrChangePassword(
    params: {
      /**  */
      body?: AccountHRChangePasswordRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountChangePasswordResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Account/HRChangePassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static brotpByUserPwd(
    params: {
      /**  */
      body?: AccountFDOTPByUserPwdRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountFDOTPByUserPwdResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Account/BROTPByUserPwd';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static brLoginByUserOtp(
    params: {
      /**  */
      body?: AccountFDLoginByUserOTPRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountFDLoginByUserOTPResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Account/BRLoginByUserOTP';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static brResetPassword(
    params: {
      /**  */
      body?: AccountBRResetPasswordRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountBRResetPasswordResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Account/BRResetPassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static brSetNewPassword(
    params: {
      /**  */
      body?: AccountMBSetNewPasswordRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountMBSetNewPasswordResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Account/BRSetNewPassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static viewClaimHistory(
    params: {
      /**  */
      uuid?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClaimHistoryResponseItem> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Account/ViewClaimHistory';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { uuid: params['uuid'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getEffectiveDate(
    params: {
      /**  */
      memberId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountEffectiveDateResponsecs> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Account/GetEffectiveDate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { memberId: params['memberId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class BrokerApi {
  /**
   *
   */
  static transactionType(options: IRequestOptions = {}): Promise<HRTransactionTypeListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/TransactionType';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static listPolicyHolder(
    params: {
      /**  */
      search?: string;
      /**  */
      status?: boolean;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BrokerListPolicyHolderResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/ListPolicyHolder';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        search: params['search'],
        status: params['status'],
        PageNumber: params['pageNumber'],
        PageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static generateListPolicyHolder(
    params: {
      /**  */
      body?: BrokeGenerateListPolicyHolderRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BrokerGenerateListMemberReponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/GenerateListPolicyHolder';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static detailPolicyHolder(
    params: {
      /**  */
      policyHolderId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BrokerDetailPolicyHolderItemResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/DetailPolicyHolder';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { policyHolderId: params['policyHolderId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static listPlan(
    params: {
      /**  */
      policyHolderId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HRPlanListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/ListPlan';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { policyHolderId: params['policyHolderId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static listPlanMember(
    params: {
      /**  */
      planId?: number;
      /**  */
      policyHolderId?: number;
      /**  */
      search?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HRListPlanMemberResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/ListPlanMember';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        PlanId: params['planId'],
        policyHolderId: params['policyHolderId'],
        Search: params['search'],
        PageNumber: params['pageNumber'],
        PageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static generateListMember(
    params: {
      /**  */
      body?: BrokeGenerateListMemberRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HRGenerateListMemberReponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/GenerateListMember';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static downloadFile(
    params: {
      /**  */
      fileName?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/DownloadFile';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { FileName: params['fileName'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static listMember(
    params: {
      /**  */
      policyHolderId?: number;
      /**  */
      search?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HRListMemberResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/ListMember';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        policyHolderId: params['policyHolderId'],
        Search: params['search'],
        PageNumber: params['pageNumber'],
        PageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static memberDetail(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HRDetailMemberResponseItem> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/MemberDetail/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static amountTerminate(
    params: {
      /**  */
      body?: HRAmountTerminateRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HRAmountTerminateResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/AmountTerminate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static submitTerminateMember(
    params: {
      /**  */
      body?: HRAmountTerminateRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GeneralMessageResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/SubmitTerminateMember';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static listTransaction(
    params: {
      /**  */
      policyHolderId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HRTransactionListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/ListTransaction';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { policyHolderId: params['policyHolderId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static requestRefund(
    params: {
      /**  */
      body?: BrokerRequestRefund;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GeneralMessageResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/RequestRefund';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static benefitDetial(
    params: {
      /**  */
      memberId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBPolicyBenefitV2Response> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/BenefitDetial';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { memberId: params['memberId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static banks(
    params: {
      /**  */
      memberId?: number;
      /**  */
      search?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBBankListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/Banks';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { memberId: params['memberId'], Search: params['search'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static serviceLogs(
    params: {
      /**  */
      policyHolderId?: number;
      /**  */
      dateFrom?: string;
      /**  */
      dateTo?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceLogRecordResponseGeneralListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/ServiceLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        policyHolderId: params['policyHolderId'],
        DateFrom: params['dateFrom'],
        DateTo: params['dateTo'],
        PageNumber: params['pageNumber'],
        PageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static serviceLogs1(
    params: {
      /**  */
      uniqueKey: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceLogRecordResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/ServiceLogs/{UniqueKey}';
      url = url.replace('{UniqueKey}', params['uniqueKey'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static policyBenefit(
    params: {
      /**  */
      policyHolderId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HRPolicyBenefitResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/PolicyBenefit';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { policyHolderId: params['policyHolderId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static policyBenefitV2(
    params: {
      /**  */
      policyHolderId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HRPolicyBenefitResponseV2> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/PolicyBenefitV2';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { policyHolderId: params['policyHolderId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static policyHolderDocuments(
    params: {
      /**  */
      policyHolderId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXPolicyHolderDocumentsResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/PolicyHolderDocuments';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { policyHolderId: params['policyHolderId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static companyProfile(
    params: {
      /**  */
      policyHolderId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HRCompanyProfileResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/CompanyProfile';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { policyHolderId: params['policyHolderId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static plans(
    params: {
      /**  */
      policyHolderId?: number;
      /**  */
      memberSearch?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXPolicyHolderPlansResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/Plans';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { policyHolderId: params['policyHolderId'], MemberSearch: params['memberSearch'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static members(
    params: {
      /**  */
      policyHolderId?: number;
      /**  */
      planId?: number;
      /**  */
      memberSearch?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXPolicyHolderMembersResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/Members';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        policyHolderId: params['policyHolderId'],
        PlanId: params['planId'],
        MemberSearch: params['memberSearch'],
        PageNumber: params['pageNumber'],
        PageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static memberProfile(
    params: {
      /**  */
      memberId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BrokerMBProfileResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/MemberProfile/{MemberId}';
      url = url.replace('{MemberId}', params['memberId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateMemberBankInfo(
    params: {
      /**  */
      body?: CXUpdateMemberBankInfoRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBUpdateBankInfoResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/UpdateMemberBankInfo';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateMemberProfile(
    params: {
      /**  */
      body?: HRUpdateMemberProfileRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBUpdateProfileResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/UpdateMemberProfile';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static terminateMember(
    params: {
      /**  */
      body?: CXTerminateMemberRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXTerminateMemberResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/TerminateMember';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static claimHistory(
    params: {
      /**  */
      policyHolderId?: number;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      claimStatusId?: number;
      /**  */
      claimTypeId?: number;
      /**  */
      oldestFirst?: number;
      /**  */
      type?: string;
      /**  */
      beneficiaryId?: number;
      /**  */
      search?: string;
      /**  */
      memberId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClaimHistoryResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/ClaimHistory';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        policyHolderId: params['policyHolderId'],
        PageNumber: params['pageNumber'],
        PageSize: params['pageSize'],
        ClaimStatusId: params['claimStatusId'],
        ClaimTypeId: params['claimTypeId'],
        OldestFirst: params['oldestFirst'],
        type: params['type'],
        BeneficiaryId: params['beneficiaryId'],
        Search: params['search'],
        MemberId: params['memberId']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static generateListClaimHistory(
    params: {
      /**  */
      body?: BrokerGenerateListClaimRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXGenerateListRateReponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/GenerateListClaimHistory';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static submitClaim(
    params: {
      /**  */
      body?: CXSubmitNewClaimRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBSubmitNewClaimResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/SubmitClaim';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static memberBankInfo(
    params: {
      /**  */
      memberId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FDBankInfoResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/MemberBankInfo/{MemberId}';
      url = url.replace('{MemberId}', params['memberId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static clinics(
    params: {
      /**  */
      search?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBClinicListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/Clinics';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Search: params['search'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static searchMember(
    params: {
      /**  */
      memberNumber?: string;
      /**  */
      dateOfBirth?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FDSearchMemberResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/SearchMember';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { MemberNumber: params['memberNumber'], DateOfBirth: params['dateOfBirth'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cancelClaim(
    params: {
      /**  */
      body?: FDCancelClaimRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FDCancelClaimResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/CancelClaim';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static claimDetail(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClaimHistoryResponseItem> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/ClaimDetail/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static downloadClaim(
    params: {
      /**  */
      claimId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/DownloadClaim/{ClaimId}';
      url = url.replace('{ClaimId}', params['claimId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static claimStatuses(options: IRequestOptions = {}): Promise<MBClaimStatusListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/ClaimStatuses';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static claimTypes(options: IRequestOptions = {}): Promise<MBClaimTypeListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/ClaimTypes';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static memberPolicyBenefit(
    params: {
      /**  */
      memberId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBPolicyBenefitV2Response> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/MemberPolicyBenefit/{MemberId}';
      url = url.replace('{MemberId}', params['memberId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static claimForEdit(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClaimHistoryResponseItem> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/ClaimForEdit/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static inactiveMember(
    params: {
      /**  */
      body?: BrokerChangeStatusMemberRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXTerminateMemberResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Broker/InactiveMember';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class CxApi {
  /**
   *
   */
  static claimSummary(options: IRequestOptions = {}): Promise<CXClaimSummaryResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/ClaimSummary';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static declineClaim(
    params: {
      /**  */
      body?: CXDeclineClaimRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXDeclineClaimResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/DeclineClaim';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static approveClaim(
    params: {
      /**  */
      body?: CXApproveClaimRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXApproveClaimResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/ApproveClaim';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static requireInfoClaim(
    params: {
      /**  */
      body?: CXRequireInfoClaimRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXRequireInfoClaimResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/RequireInfoClaim';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static claimStatuses(options: IRequestOptions = {}): Promise<MBClaimStatusListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/ClaimStatuses';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static claimTypes(options: IRequestOptions = {}): Promise<MBClaimTypeListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/ClaimTypes';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static markPaidClaim(
    params: {
      /**  */
      body?: CXMarkPaidClaimRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXMarkPaidClaimResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/MarkPaidClaim';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static claimHistory(
    params: {
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      claimStatusId?: number;
      /**  */
      claimTypeId?: number;
      /**  */
      oldestFirst?: number;
      /**  */
      type?: string;
      /**  */
      beneficiaryId?: number;
      /**  */
      search?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClaimHistoryResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/ClaimHistory';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        PageNumber: params['pageNumber'],
        PageSize: params['pageSize'],
        ClaimStatusId: params['claimStatusId'],
        ClaimTypeId: params['claimTypeId'],
        OldestFirst: params['oldestFirst'],
        type: params['type'],
        BeneficiaryId: params['beneficiaryId'],
        Search: params['search']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static claimDetail(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClaimHistoryResponseItem> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/ClaimDetail/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static clinics(
    params: {
      /**  */
      search?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBClinicListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/Clinics';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Search: params['search'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static clinicDetail(
    params: {
      /**  */
      clinicId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXClinicDetailResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/ClinicDetail/{ClinicId}';
      url = url.replace('{ClinicId}', params['clinicId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static clinicUsers(
    params: {
      /**  */
      clinicId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXClinicUsersResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/ClinicUsers/{ClinicId}';
      url = url.replace('{ClinicId}', params['clinicId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static submitClaim(
    params: {
      /**  */
      body?: CXSubmitNewClaimRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBSubmitNewClaimResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/SubmitClaim';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static searchMember(
    params: {
      /**  */
      memberNumber?: string;
      /**  */
      dateOfBirth?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FDSearchMemberResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/SearchMember';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { MemberNumber: params['memberNumber'], DateOfBirth: params['dateOfBirth'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static memberDetail(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FDSearchMemberResponseItem> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/MemberDetail/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createClinic(
    params: {
      /**  */
      body?: CXCreateCinicRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXCreateCinicResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/CreateClinic';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateClinic(
    params: {
      /**  */
      body?: CXUpdateCinicRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXCreateCinicResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/UpdateClinic';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateClinicContactPerson(
    params: {
      /**  */
      body?: CXUpdateClinicContactRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXCreateCinicResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/UpdateClinicContactPerson';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static memberBankInfo(
    params: {
      /**  */
      memberId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FDBankInfoResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/MemberBankInfo/{MemberId}';
      url = url.replace('{MemberId}', params['memberId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static clinicBankInfo(
    params: {
      /**  */
      clinicId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FDBankInfoResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/ClinicBankInfo/{ClinicId}';
      url = url.replace('{ClinicId}', params['clinicId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createCinicUser(
    params: {
      /**  */
      body?: CXCreateClinicUserRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXCreateClinicUserResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/CreateCinicUser';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateClinicUser(
    params: {
      /**  */
      body?: CXUpdateClinicUserRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXCreateClinicUserResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/UpdateClinicUser';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static banks(
    params: {
      /**  */
      search?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBBankListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/Banks';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Search: params['search'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static memberProfile(
    params: {
      /**  */
      memberId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountProfileResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/MemberProfile/{MemberId}';
      url = url.replace('{MemberId}', params['memberId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static clinicUserProfile(
    params: {
      /**  */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountProfileResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/ClinicUserProfile/{UserId}';
      url = url.replace('{UserId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateMemberBankInfo(
    params: {
      /**  */
      body?: CXUpdateMemberBankInfoRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBUpdateBankInfoResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/UpdateMemberBankInfo';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateClinicBankInfo(
    params: {
      /**  */
      body?: CXUpdateClinicBankInfoRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBUpdateBankInfoResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/UpdateClinicBankInfo';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static claimCategories(options: IRequestOptions = {}): Promise<FDClaimCategoriesResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/ClaimCategories';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static terminateMember(
    params: {
      /**  */
      body?: CXTerminateMemberRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXTerminateMemberResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/TerminateMember';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static inactiveMember(
    params: {
      /**  */
      body?: CXTerminateMemberRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXTerminateMemberResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/InactiveMember';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateMemberProfile(
    params: {
      /**  */
      body?: CXUpdateMemberProfileRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBUpdateProfileResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/UpdateMemberProfile';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deactivateClinicUser(
    params: {
      /**  */
      body?: CXDeactivateClinicUserRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXDeactivateClinicUserResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/DeactivateClinicUser';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static initialLogRule(options: IRequestOptions = {}): Promise<CXGetInitialLOGRuleResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/InitialLOGRule';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reimbursementRule(options: IRequestOptions = {}): Promise<CXGetReimbursementRuleResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/ReimbursementRule';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateInitialLogApproveAmount(
    params: {
      /**  */
      body?: CXUpdateInitialLOGApproveAmountRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXUpdateInitialLOGApproveAmountResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/UpdateInitialLOGApproveAmount';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addInitialLogClinicException(
    params: {
      /**  */
      body?: CXAddClinicExceptionRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GeneralMessageResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/AddInitialLOGClinicException';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addInitialLogMemberException(
    params: {
      /**  */
      body?: CXAddMemberExceptionRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GeneralMessageResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/AddInitialLOGMemberException';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addInitialLogPolicyHolderException(
    params: {
      /**  */
      body?: CXAddPolicyHolderExceptionRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GeneralMessageResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/AddInitialLOGPolicyHolderException';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addReimbursementMemberException(
    params: {
      /**  */
      body?: CXAddMemberExceptionRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GeneralMessageResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/AddReimbursementMemberException';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addReimbursementPolicyHolderException(
    params: {
      /**  */
      body?: CXAddPolicyHolderExceptionRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GeneralMessageResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/AddReimbursementPolicyHolderException';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static removeClinic(
    params: {
      /**  */
      body?: CXRemoveClinicRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GeneralMessageResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/RemoveClinic';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static removeInitialLogClinicException(
    params: {
      /**  */
      body?: CXAddClinicExceptionRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GeneralMessageResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/RemoveInitialLOGClinicException';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static removeInitialLogMemberException(
    params: {
      /**  */
      body?: CXAddMemberExceptionRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GeneralMessageResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/RemoveInitialLOGMemberException';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static removeInitialLogPolicyHolderException(
    params: {
      /**  */
      body?: CXAddPolicyHolderExceptionRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GeneralMessageResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/RemoveInitialLOGPolicyHolderException';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static removeReimbursementMemberException(
    params: {
      /**  */
      body?: CXAddMemberExceptionRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GeneralMessageResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/RemoveReimbursementMemberException';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static removeReimbursementPolicyHolderException(
    params: {
      /**  */
      body?: CXAddPolicyHolderExceptionRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GeneralMessageResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/RemoveReimbursementPolicyHolderException';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static policyHolders(
    params: {
      /**  */
      search?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXPolicyHoldersResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/PolicyHolders';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Search: params['search'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static members(
    params: {
      /**  */
      search?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXPolicyHoldersResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/Members';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Search: params['search'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static generateReportClaimHistory(
    params: {
      /**  */
      body?: CXGenerateReportClaimHistoryRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXGenerateReportClaimHistoryResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/GenerateReportClaimHistory';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static downloadReport(
    params: {
      /**  */
      reportFileName?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/DownloadReport';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { ReportFileName: params['reportFileName'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static searchPolicy(
    params: {
      /**  */
      policyNumber?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXSearchPolicyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/SearchPolicy';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { PolicyNumber: params['policyNumber'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static policyHolderDetail(
    params: {
      /**  */
      policyHolderId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXPolicyHolderDetailResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/PolicyHolderDetail/{PolicyHolderId}';
      url = url.replace('{PolicyHolderId}', params['policyHolderId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static policyHolderProfile(
    params: {
      /**  */
      policyHolderId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXPolicyHolderProfileResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/PolicyHolderProfile/{PolicyHolderId}';
      url = url.replace('{PolicyHolderId}', params['policyHolderId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static policyHolderUsers(
    params: {
      /**  */
      policyHolderId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXPolicyHolderUsersResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/PolicyHolderUsers/{PolicyHolderId}';
      url = url.replace('{PolicyHolderId}', params['policyHolderId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static policyHolderUserProfile(
    params: {
      /**  */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountProfileResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/PolicyHolderUserProfile/{UserId}';
      url = url.replace('{UserId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deactivatePolicyHolderUser(
    params: {
      /**  */
      body?: CXDeactivateClinicUserRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXDeactivateClinicUserResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/DeactivatePolicyHolderUser';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updatePolicyHolderUserPassword(
    params: {
      /**  */
      body?: CXUpdatePolicyHolderUserPasswordRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXCreateClinicUserResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/UpdatePolicyHolderUserPassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createPolicyHolderUser(
    params: {
      /**  */
      body?: CXCreatePolicyHolderUserRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXCreateClinicUserResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/CreatePolicyHolderUser';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updatePolicyHolderUser(
    params: {
      /**  */
      body?: CXUpdatePolicyHolderUser;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXCreateClinicUserResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/UpdatePolicyHolderUser';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addPolicyHolderDocument(
    params: {
      /**  */
      body?: CXAddPolicyHolderDocumentRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GeneralMessageResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/AddPolicyHolderDocument';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static policyHolderDocuments(
    params: {
      /**  */
      policyHolderId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXPolicyHolderDocumentsResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/PolicyHolderDocuments/{PolicyHolderId}';
      url = url.replace('{PolicyHolderId}', params['policyHolderId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static removePolicyHolderDocument(
    params: {
      /**  */
      body?: CXRemovePolicyHolderDocumentRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GeneralMessageResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/RemovePolicyHolderDocument';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static policyHolderPlans(
    params: {
      /**  */
      policyHolderId: number;
      /**  */
      memberSearch?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXPolicyHolderPlansResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/PolicyHolderPlans/{PolicyHolderId}';
      url = url.replace('{PolicyHolderId}', params['policyHolderId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { MemberSearch: params['memberSearch'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static policyHolderMembers(
    params: {
      /**  */
      policyHolderId: number;
      /**  */
      planId?: number;
      /**  */
      memberSearch?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXPolicyHolderMembersResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/PolicyHolderMembers/{PolicyHolderId}';
      url = url.replace('{PolicyHolderId}', params['policyHolderId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        PlanId: params['planId'],
        MemberSearch: params['memberSearch'],
        PageNumber: params['pageNumber'],
        PageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static downloadClaim(
    params: {
      /**  */
      claimId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/DownloadClaim/{ClaimId}';
      url = url.replace('{ClaimId}', params['claimId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cancelClaim(
    params: {
      /**  */
      body?: FDCancelClaimRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FDCancelClaimResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/CancelClaim';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static claimDetailForEdit(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FDClaimDetailForEditResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/ClaimDetailForEdit/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static memberPolicyBenefit(
    params: {
      /**  */
      memberId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBPolicyBenefitV2Response> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/MemberPolicyBenefit/{MemberId}';
      url = url.replace('{MemberId}', params['memberId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static claimForEdit(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClaimHistoryResponseItem> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/ClaimForEdit/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createBrokerUser(
    params: {
      /**  */
      body?: CXCreatePolicyHolderUserRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXCreateClinicUserResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CX/CreateBrokerUser';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class Cxv2Api {
  /**
   *
   */
  static claimSummary(options: IRequestOptions = {}): Promise<CXClaimSummaryResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/ClaimSummary';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static searchIndividulePolicy(
    params: {
      /**  */
      policyNumber?: string;
      /**  */
      dateOfBirth?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FDSearchMemberResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/SearchIndividulePolicy';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { PolicyNumber: params['policyNumber'], DateOfBirth: params['dateOfBirth'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static memberDetail(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FDSearchMemberResponseItem> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/MemberDetail/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static listPlan(
    params: {
      /**  */
      policyHolderId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HRPlanListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/ListPlan';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { PolicyHolderId: params['policyHolderId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static listPlanMember(
    params: {
      /**  */
      policyHolderId?: number;
      /**  */
      planId?: number;
      /**  */
      search?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HRListPlanMemberResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/ListPlanMember';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        PolicyHolderId: params['policyHolderId'],
        PlanId: params['planId'],
        Search: params['search'],
        PageNumber: params['pageNumber'],
        PageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static generateListMember(
    params: {
      /**  */
      body?: CXGenerateListMemberRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HRGenerateListMemberReponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/GenerateListMember';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static downloadFile(
    params: {
      /**  */
      fileName?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/DownloadFile';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { FileName: params['fileName'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static amountTerminate(
    params: {
      /**  */
      body?: CXAmountTerminateRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HRAmountTerminateResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/AmountTerminate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static submitTerminateMember(
    params: {
      /**  */
      body?: CXAmountTerminateRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GeneralMessageResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/SubmitTerminateMember';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static listTransaction(
    params: {
      /**  */
      policyHolderId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HRTransactionListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/ListTransaction';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { PolicyHolderId: params['policyHolderId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static transactionType(options: IRequestOptions = {}): Promise<HRTransactionTypeListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/TransactionType';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static submitAdjustmentBalance(
    params: {
      /**  */
      body?: CXAdjustmentBalanceRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GeneralMessageResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/SubmitAdjustmentBalance';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static memberDocuments(
    params: {
      /**  */
      memberId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXMemberDocumentListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/MemberDocuments/{MemberId}';
      url = url.replace('{MemberId}', params['memberId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addMemberDocument(
    params: {
      /**  */
      body?: CXAddMemberDocumentRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GeneralMessageResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/AddMemberDocument';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static removeMemberDocument(
    params: {
      /**  */
      body?: CXRemoveMemberDocumentRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GeneralMessageResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/RemoveMemberDocument';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static listRate(
    params: {
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBRateListPaginateResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/ListRate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { PageNumber: params['pageNumber'], PageSize: params['pageSize'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changeRateStatus(
    params: {
      /**  */
      body?: CXChangeStatusRateRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GeneralMessageResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/ChangeRateStatus';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rateDetail(
    params: {
      /**  */
      rateId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBRateDetailResponseItem> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/RateDetail/{RateId}';
      url = url.replace('{RateId}', params['rateId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static submitCommentRate(
    params: {
      /**  */
      body?: CXSubmitCommentRateRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GeneralMessageResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/SubmitCommentRate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static generateListRate(options: IRequestOptions = {}): Promise<CXGenerateListRateReponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/GenerateListRate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static claimHistoryAutoApproved(
    params: {
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      oldestFirst?: number;
      /**  */
      isAutoApprove?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClaimHistoryResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/ClaimHistoryAutoApproved';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        FromDate: params['fromDate'],
        ToDate: params['toDate'],
        PageNumber: params['pageNumber'],
        PageSize: params['pageSize'],
        OldestFirst: params['oldestFirst'],
        isAutoApprove: params['isAutoApprove']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changeSystemAutoApproveStatus(
    params: {
      /**  */
      body?: CXChangeSystemAutoApproveStatusRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GeneralMessageResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/ChangeSystemAutoApproveStatus';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static generateListClaimHistory(
    params: {
      /**  */
      body?: GenerateListClaimRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXGenerateListRateReponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/GenerateListClaimHistory';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static claimDetail(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClaimHistoryResponseItem> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/ClaimDetail/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static claimHistory(
    params: {
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      claimStatusId?: number;
      /**  */
      claimTypeId?: number;
      /**  */
      oldestFirst?: number;
      /**  */
      type?: string;
      /**  */
      beneficiaryId?: number;
      /**  */
      search?: string;
      /**  */
      isSystemApproved?: boolean;
      /**  */
      memberId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClaimHistoryResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/ClaimHistory';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        PageNumber: params['pageNumber'],
        PageSize: params['pageSize'],
        ClaimStatusId: params['claimStatusId'],
        ClaimTypeId: params['claimTypeId'],
        OldestFirst: params['oldestFirst'],
        type: params['type'],
        BeneficiaryId: params['beneficiaryId'],
        Search: params['search'],
        IsSystemApproved: params['isSystemApproved'],
        memberId: params['memberId']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static submitClaimHistoryComment(
    params: {
      /**  */
      body?: CXClaimHistoryCommentRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GeneralMessageResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/SubmitClaimHistoryComment';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static banks(
    params: {
      /**  */
      memberId?: number;
      /**  */
      search?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBBankListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/Banks';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { memberId: params['memberId'], Search: params['search'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static maritalStatus(options: IRequestOptions = {}): Promise<MBMaritalStatusListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/MaritalStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static memberProfile(
    params: {
      /**  */
      memberId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountProfileV2Response> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/MemberProfile/{MemberId}';
      url = url.replace('{MemberId}', params['memberId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateMemberProfile(
    params: {
      /**  */
      body?: CX2UpdateMemberProfileRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBUpdateProfileResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/UpdateMemberProfile';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static memberDocumentPayment(
    params: {
      /**  */
      memberId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MemberDocumentPayment> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/MemberDocumentPayment';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { memberId: params['memberId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static serviceLogs(
    params: {
      /**  */
      dateFrom?: string;
      /**  */
      dateTo?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceLogRecordResponseGeneralListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/ServiceLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DateFrom: params['dateFrom'],
        DateTo: params['dateTo'],
        PageNumber: params['pageNumber'],
        PageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exportServiceLogs(
    params: {
      /**  */
      dateFrom?: string;
      /**  */
      dateTo?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/ExportServiceLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { DateFrom: params['dateFrom'], DateTo: params['dateTo'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static serviceLogs1(
    params: {
      /**  */
      uniqueKey: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceLogRecordResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/ServiceLogs/{UniqueKey}';
      url = url.replace('{UniqueKey}', params['uniqueKey'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static flag(
    params: {
      /**  */
      body?: FlagServiceLogRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GeneralMessageResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/ServiceLogs/Flag';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static comment(
    params: {
      /**  */
      body?: CommentServiceLogRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GeneralMessageResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/ServiceLogs/Comment';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static memberDetailCardHtml(
    params: {
      /**  */
      memberId?: number;
      /**  */
      isDownload?: boolean;
      /**  */
      isFontEmbeded?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/MemberDetailCardHTML';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        memberId: params['memberId'],
        isDownload: params['isDownload'],
        isFontEmbeded: params['isFontEmbeded']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static policyBenefitV2Imr(
    params: {
      /**  */
      memberId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBPolicyBenefitV2IMRResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/PolicyBenefitV2IMR';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { memberId: params['memberId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static listBeneficiary(
    params: {
      /**  */
      memberId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBNewBeneficiaryListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/ListBeneficiary';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { memberId: params['memberId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static submitBeneficiary(
    params: {
      /**  */
      body?: MBNewBeneficiaryRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GeneralMessageResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/SubmitBeneficiary';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static editBeneficiary(
    params: {
      /**  */
      beneficiaryId: number;
      /**  */
      memberId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBNewBeneficiaryListResponseItem> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/EditBeneficiary/{beneficiaryId}';
      url = url.replace('{beneficiaryId}', params['beneficiaryId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { memberId: params['memberId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateBeneficiary(
    params: {
      /**  */
      body?: MBNewBeneficiaryRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GeneralMessageResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/UpdateBeneficiary';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteBeneficiary(
    params: {
      /**  */
      body?: MBNewBeneficiaryRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GeneralMessageResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/DeleteBeneficiary';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateAmountPercentBeneficiary(
    params: {
      /**  */
      body?: MBNewBeneficiaryRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GeneralMessageResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/UpdateAmountPercentBeneficiary';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static uploadProfilePhotoBeneficiary(
    params: {
      /**  */
      file?: any;
      /**  */
      beneficiaryId?: number;
      /**  */
      memberId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ImageUploadResponseFile> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/UploadProfilePhotoBeneficiary';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('File', item as any);
          }
        } else {
          data.append('File', params['file'] as any);
        }
      }

      if (params['beneficiaryId']) {
        if (Object.prototype.toString.call(params['beneficiaryId']) === '[object Array]') {
          for (const item of params['beneficiaryId'] as any) {
            data.append('BeneficiaryId', item as any);
          }
        } else {
          data.append('BeneficiaryId', params['beneficiaryId'] as any);
        }
      }

      if (params['memberId']) {
        if (Object.prototype.toString.call(params['memberId']) === '[object Array]') {
          for (const item of params['memberId'] as any) {
            data.append('MemberId', item as any);
          }
        } else {
          data.append('MemberId', params['memberId'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static memberProfileV2(
    params: {
      /**  */
      memberId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBProfileResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/MemberProfileV2';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { memberId: params['memberId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static claimTypeCanSubmit(
    params: {
      /**  */
      memberId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBClaimTypeCanSubmitResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/ClaimTypeCanSubmit';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { memberId: params['memberId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static submitClaim(
    params: {
      /**  */
      body?: MBSubmitNewClaimV2Request;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBSubmitNewClaimResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/SubmitClaim';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static bankInfo(
    params: {
      /**  */
      memberId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBBankInfoResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/BankInfo';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { memberId: params['memberId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static beneficiaries(
    params: {
      /**  */
      memberId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBBeneficiaryV2ListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/Beneficiaries';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { memberId: params['memberId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static claimDetailForEdit(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FDClaimDetailForEditResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/ClaimDetailForEdit/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static submitClaimOnlyDocument(
    params: {
      /**  */
      body?: MBSubmitClaimOnlyDocumentRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBSubmitNewClaimResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/CXV2/SubmitClaimOnlyDocument';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class FrontDeskApi {
  /**
   *
   */
  static claimSummary(options: IRequestOptions = {}): Promise<FDClaimSummaryResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/FrontDesk/ClaimSummary';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static claimHistory(
    params: {
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      claimStatusId?: number;
      /**  */
      claimTypeId?: number;
      /**  */
      oldestFirst?: number;
      /**  */
      type?: string;
      /**  */
      beneficiaryId?: number;
      /**  */
      search?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClaimHistoryResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/FrontDesk/ClaimHistory';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        PageNumber: params['pageNumber'],
        PageSize: params['pageSize'],
        ClaimStatusId: params['claimStatusId'],
        ClaimTypeId: params['claimTypeId'],
        OldestFirst: params['oldestFirst'],
        type: params['type'],
        BeneficiaryId: params['beneficiaryId'],
        Search: params['search']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static claimDetail(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClaimHistoryResponseItem> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/FrontDesk/ClaimDetail/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static searchMember(
    params: {
      /**  */
      memberNumber?: string;
      /**  */
      dateOfBirth?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FDSearchMemberResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/FrontDesk/SearchMember';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { MemberNumber: params['memberNumber'], DateOfBirth: params['dateOfBirth'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static memberDetail(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FDSearchMemberResponseItem> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/FrontDesk/MemberDetail/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static bankInfo(options: IRequestOptions = {}): Promise<FDBankInfoResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/FrontDesk/BankInfo';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static claimCategories(options: IRequestOptions = {}): Promise<FDClaimCategoriesResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/FrontDesk/ClaimCategories';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static submitClaim(
    params: {
      /**  */
      body?: FDSubmitNewClaimRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FDSubmitNewClaimResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/FrontDesk/SubmitClaim';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static claimDetailForEdit(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FDClaimDetailForEditResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/FrontDesk/ClaimDetailForEdit/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cancelClaim(
    params: {
      /**  */
      body?: FDCancelClaimRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FDCancelClaimResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/FrontDesk/CancelClaim';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static downloadClaim(
    params: {
      /**  */
      claimId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/FrontDesk/DownloadClaim/{ClaimId}';
      url = url.replace('{ClaimId}', params['claimId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static claimStatuses(options: IRequestOptions = {}): Promise<MBClaimStatusListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/FrontDesk/ClaimStatuses';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static claimTypes(options: IRequestOptions = {}): Promise<MBClaimTypeListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/FrontDesk/ClaimTypes';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static beneficiaries(options: IRequestOptions = {}): Promise<MBBeneficiaryListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/FrontDesk/Beneficiaries';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class FrontDeskV2Api {
  /**
   *
   */
  static searchMember(
    params: {
      /**  */
      memberNumber?: string;
      /**  */
      policyNumber?: string;
      /**  */
      dateOfBirth?: string;
      /**  */
      type?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FDSearchMemberResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/FrontDeskV2/SearchMember';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        MemberNumber: params['memberNumber'],
        PolicyNumber: params['policyNumber'],
        DateOfBirth: params['dateOfBirth'],
        Type: params['type']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static memberDetail(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FDSearchMemberResponseItem> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/FrontDeskV2/MemberDetail/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static memberDetailCardHtml(
    params: {
      /**  */
      memberId?: number;
      /**  */
      isDownload?: boolean;
      /**  */
      isFontEmbeded?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/FrontDeskV2/MemberDetailCardHTML';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        memberId: params['memberId'],
        isDownload: params['isDownload'],
        isFontEmbeded: params['isFontEmbeded']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static claimHistory(
    params: {
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      claimStatusId?: number;
      /**  */
      claimTypeId?: number;
      /**  */
      oldestFirst?: number;
      /**  */
      type?: string;
      /**  */
      beneficiaryId?: number;
      /**  */
      search?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClaimHistoryResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/FrontDeskV2/ClaimHistory';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        PageNumber: params['pageNumber'],
        PageSize: params['pageSize'],
        ClaimStatusId: params['claimStatusId'],
        ClaimTypeId: params['claimTypeId'],
        OldestFirst: params['oldestFirst'],
        type: params['type'],
        BeneficiaryId: params['beneficiaryId'],
        Search: params['search']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static claimDetail(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClaimHistoryResponseItem> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/FrontDeskV2/ClaimDetail/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static submitClaim(
    params: {
      /**  */
      body?: FDV2SubmitNewClaimRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FDSubmitNewClaimResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/FrontDeskV2/SubmitClaim';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static claimDetailForEdit(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FDClaimDetailForEditResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/FrontDeskV2/ClaimDetailForEdit/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class GeneralApi {
  /**
   *
   */
  static telemedicine(options: IRequestOptions = {}): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/General/Telemedicine';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static termAndCondition(
    params: {
      /**  */
      claimTypeId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/General/TermAndCondition/{ClaimTypeId}';
      url = url.replace('{ClaimTypeId}', params['claimTypeId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static treatmentType(options: IRequestOptions = {}): Promise<MBTreatmentTypeListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/General/TreatmentType';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPermissions(options: IRequestOptions = {}): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/GetPermissions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static relationship(options: IRequestOptions = {}): Promise<MBRelationshipListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/General/Relationship';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static tpdWasWue(options: IRequestOptions = {}): Promise<MBTPDWasWueListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/General/TPDWasWue';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static haClaiming(options: IRequestOptions = {}): Promise<MBHAClaimingListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/General/HAClaiming';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static bodyInjuredType(options: IRequestOptions = {}): Promise<MBBodyInjuredTypeListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/General/BodyInjuredType';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static degreeBurn(options: IRequestOptions = {}): Promise<MBDegreeBurnListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/General/DegreeBurn';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static confined(options: IRequestOptions = {}): Promise<MBConfinedListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/General/Confined';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class HelpApi {
  /**
   *
   */
  static info(options: IRequestOptions = {}): Promise<HelpInfoResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Help/Info';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static testNotification(options: IRequestOptions = {}): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Help/TestNotification';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static testIlBeneficiary(options: IRequestOptions = {}): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Help/TestILBeneficiary';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static testIlClientProfile(options: IRequestOptions = {}): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Help/TestILClientProfile';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class HrApi {
  /**
   *
   */
  static policyInfo(options: IRequestOptions = {}): Promise<HRPolicyInfoResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/HR/PolicyInfo';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static policyBenefit(options: IRequestOptions = {}): Promise<HRPolicyBenefitResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/HR/PolicyBenefit';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static policyBenefitV2(options: IRequestOptions = {}): Promise<HRPolicyBenefitResponseV2> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/HR/PolicyBenefitV2';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static policyHolderDocuments(options: IRequestOptions = {}): Promise<CXPolicyHolderDocumentsResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/HR/PolicyHolderDocuments';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static companyProfile(options: IRequestOptions = {}): Promise<HRCompanyProfileResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/HR/CompanyProfile';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static plans(
    params: {
      /**  */
      memberSearch?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXPolicyHolderPlansResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/HR/Plans';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { MemberSearch: params['memberSearch'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static members(
    params: {
      /**  */
      planId?: number;
      /**  */
      memberSearch?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXPolicyHolderMembersResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/HR/Members';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        PlanId: params['planId'],
        MemberSearch: params['memberSearch'],
        PageNumber: params['pageNumber'],
        PageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static memberProfile(
    params: {
      /**  */
      memberId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountProfileResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/HR/MemberProfile/{MemberId}';
      url = url.replace('{MemberId}', params['memberId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateMemberBankInfo(
    params: {
      /**  */
      body?: CXUpdateMemberBankInfoRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBUpdateBankInfoResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/HR/UpdateMemberBankInfo';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateMemberProfile(
    params: {
      /**  */
      body?: HRUpdateMemberProfileRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBUpdateProfileResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/HR/UpdateMemberProfile';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static terminateMember(
    params: {
      /**  */
      body?: CXTerminateMemberRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXTerminateMemberResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/HR/TerminateMember';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static claimHistory(
    params: {
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      claimStatusId?: number;
      /**  */
      claimTypeId?: number;
      /**  */
      oldestFirst?: number;
      /**  */
      type?: string;
      /**  */
      beneficiaryId?: number;
      /**  */
      search?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClaimHistoryResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/HR/ClaimHistory';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        PageNumber: params['pageNumber'],
        PageSize: params['pageSize'],
        ClaimStatusId: params['claimStatusId'],
        ClaimTypeId: params['claimTypeId'],
        OldestFirst: params['oldestFirst'],
        type: params['type'],
        BeneficiaryId: params['beneficiaryId'],
        Search: params['search']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static submitClaim(
    params: {
      /**  */
      body?: CXSubmitNewClaimRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBSubmitNewClaimResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/HR/SubmitClaim';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static memberBankInfo(
    params: {
      /**  */
      memberId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FDBankInfoResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/HR/MemberBankInfo/{MemberId}';
      url = url.replace('{MemberId}', params['memberId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static banks(
    params: {
      /**  */
      search?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBBankListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/HR/Banks';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Search: params['search'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static clinics(
    params: {
      /**  */
      search?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBClinicListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/HR/Clinics';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Search: params['search'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static searchMember(
    params: {
      /**  */
      memberNumber?: string;
      /**  */
      dateOfBirth?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FDSearchMemberResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/HR/SearchMember';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { MemberNumber: params['memberNumber'], DateOfBirth: params['dateOfBirth'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static memberDetail(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FDSearchMemberResponseItem> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/HR/MemberDetail/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cancelClaim(
    params: {
      /**  */
      body?: FDCancelClaimRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FDCancelClaimResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/HR/CancelClaim';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static claimDetail(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClaimHistoryResponseItem> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/HR/ClaimDetail/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static downloadClaim(
    params: {
      /**  */
      claimId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/HR/DownloadClaim/{ClaimId}';
      url = url.replace('{ClaimId}', params['claimId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static claimStatuses(options: IRequestOptions = {}): Promise<MBClaimStatusListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/HR/ClaimStatuses';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static claimTypes(options: IRequestOptions = {}): Promise<MBClaimTypeListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/HR/ClaimTypes';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static memberPolicyBenefit(
    params: {
      /**  */
      memberId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBPolicyBenefitV2Response> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/HR/MemberPolicyBenefit/{MemberId}';
      url = url.replace('{MemberId}', params['memberId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static claimForEdit(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClaimHistoryResponseItem> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/HR/ClaimForEdit/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class Hrv2Api {
  /**
   *
   */
  static transactionType(options: IRequestOptions = {}): Promise<HRTransactionTypeListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/HRV2/TransactionType';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static listPlan(options: IRequestOptions = {}): Promise<HRPlanListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/HRV2/ListPlan';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static listPlanMember(
    params: {
      /**  */
      planId?: number;
      /**  */
      search?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HRListPlanMemberResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/HRV2/ListPlanMember';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        PlanId: params['planId'],
        Search: params['search'],
        PageNumber: params['pageNumber'],
        PageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static generateListMember(
    params: {
      /**  */
      body?: HRGenerateListMemberRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HRGenerateListMemberReponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/HRV2/GenerateListMember';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static downloadFile(
    params: {
      /**  */
      fileName?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/HRV2/DownloadFile';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { FileName: params['fileName'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static listMember(
    params: {
      /**  */
      search?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HRListMemberResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/HRV2/ListMember';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Search: params['search'], PageNumber: params['pageNumber'], PageSize: params['pageSize'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static memberDetail(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HRDetailMemberResponseItem> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/HRV2/MemberDetail/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static amountTerminate(
    params: {
      /**  */
      body?: HRAmountTerminateRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HRAmountTerminateResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/HRV2/AmountTerminate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static submitTerminateMember(
    params: {
      /**  */
      body?: HRAmountTerminateRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GeneralMessageResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/HRV2/SubmitTerminateMember';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static listTransaction(options: IRequestOptions = {}): Promise<HRTransactionListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/HRV2/ListTransaction';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static requestRefund(
    params: {
      /**  */
      body?: HRRequestRefund;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GeneralMessageResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/HRV2/RequestRefund';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static benefitDetial(
    params: {
      /**  */
      memberId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBPolicyBenefitV2Response> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/HRV2/BenefitDetial';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { memberId: params['memberId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static banks(
    params: {
      /**  */
      memberId?: number;
      /**  */
      search?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBBankListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/HRV2/Banks';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { memberId: params['memberId'], Search: params['search'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static serviceLogs(
    params: {
      /**  */
      dateFrom?: string;
      /**  */
      dateTo?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceLogRecordResponseGeneralListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/HRV2/ServiceLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DateFrom: params['dateFrom'],
        DateTo: params['dateTo'],
        PageNumber: params['pageNumber'],
        PageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static serviceLogs1(
    params: {
      /**  */
      uniqueKey: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceLogRecordResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/HRV2/ServiceLogs/{UniqueKey}';
      url = url.replace('{UniqueKey}', params['uniqueKey'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ImageApi {
  /**
   *
   */
  static telegramQr(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Image/TelegramQR';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static image(
    params: {
      /**  */
      fileName?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Image';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { FileName: params['fileName'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static upload(
    params: {
      /**  */
      files?: string[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ImageUploadResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Image/Upload';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = new FormData();
      if (params['files']) {
        if (Object.prototype.toString.call(params['files']) === '[object Array]') {
          for (const item of params['files']) {
            data.append('files', item as any);
          }
        } else {
          data.append('files', params['files'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static file(
    params: {
      /**  */
      fileName?: string;
      /**  */
      link?: string;
      /**  */
      memberId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Image/File';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { FileName: params['fileName'], Link: params['link'], MemberId: params['memberId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static tmpFile(
    params: {
      /**  */
      fileName?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Image/TmpFile';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { FileName: params['fileName'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class MemberApi {
  /**
   *
   */
  static register(
    params: {
      /**  */
      body?: MBRegisterRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBRegisterResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Member/Register';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static registerValidateOtp(
    params: {
      /**  */
      body?: MBRegisterValidateOTPRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBRegisterValidateOTPResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Member/RegisterValidateOTP';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static registerSetPassword(
    params: {
      /**  */
      body?: MBRegisterSetPasswordRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBRegisterSetPasswordResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Member/RegisterSetPassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static memberDetail(options: IRequestOptions = {}): Promise<MBMemberDetailResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Member/MemberDetail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static claimHistory(
    params: {
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      claimStatusId?: number;
      /**  */
      claimTypeId?: number;
      /**  */
      oldestFirst?: number;
      /**  */
      type?: string;
      /**  */
      beneficiaryId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClaimHistoryResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Member/ClaimHistory';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        PageNumber: params['pageNumber'],
        PageSize: params['pageSize'],
        ClaimStatusId: params['claimStatusId'],
        ClaimTypeId: params['claimTypeId'],
        OldestFirst: params['oldestFirst'],
        type: params['type'],
        BeneficiaryId: params['beneficiaryId']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static submitClaim(
    params: {
      /**  */
      body?: MBSubmitNewClaimRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBSubmitNewClaimResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Member/SubmitClaim';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static bankInfo(options: IRequestOptions = {}): Promise<FDBankInfoResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Member/BankInfo';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static clinics(
    params: {
      /**  */
      search?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBClinicListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Member/Clinics';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Search: params['search'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static beneficiaries(options: IRequestOptions = {}): Promise<MBBeneficiaryListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Member/Beneficiaries';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static claimStatuses(options: IRequestOptions = {}): Promise<MBClaimStatusListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Member/ClaimStatuses';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static claimTypes(options: IRequestOptions = {}): Promise<MBClaimTypeListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Member/ClaimTypes';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cancelClaim(
    params: {
      /**  */
      body?: FDCancelClaimRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FDCancelClaimResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Member/CancelClaim';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static policyBenefit(options: IRequestOptions = {}): Promise<MBPolicyBenefitResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Member/PolicyBenefit';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static policyBenefitV2(options: IRequestOptions = {}): Promise<MBPolicyBenefitV2Response> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Member/PolicyBenefitV2';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateBankInfo(
    params: {
      /**  */
      body?: MBUpdateBankInfoRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBUpdateBankInfoResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Member/UpdateBankInfo';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static banks(
    params: {
      /**  */
      search?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBBankListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Member/Banks';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Search: params['search'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateProfile(
    params: {
      /**  */
      body?: MBUpdateProfileRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBUpdateProfileResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Member/UpdateProfile';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static downloadClaim(
    params: {
      /**  */
      claimId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Member/DownloadClaim/{ClaimId}';
      url = url.replace('{ClaimId}', params['claimId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static claimDetailForEdit(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FDClaimDetailForEditResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Member/ClaimDetailForEdit/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static claimDetail(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClaimHistoryResponseItem> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Member/ClaimDetail/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static downloadCard(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Member/DownloadCard';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class MemberV2Api {
  /**
   *
   */
  static rewardType(options: IRequestOptions = {}): Promise<MBRewardTypeListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/RewardType';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static rewardItemDetail(
    params: {
      /**  */
      rewardItemId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBRewardItemListResponseItem> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/RewardItemDetail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { RewardItemId: params['rewardItemId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static exclusion(options: IRequestOptions = {}): Promise<MBExclusionListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/Exclusion';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static degreeBurn(options: IRequestOptions = {}): Promise<MBDegreeBurnListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/DegreeBurn';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static tpdWasWue(options: IRequestOptions = {}): Promise<MBTPDWasWueListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/TPDWasWue';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static haClaiming(options: IRequestOptions = {}): Promise<MBHAClaimingListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/HAClaiming';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static bodyInjuredType(options: IRequestOptions = {}): Promise<MBBodyInjuredTypeListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/BodyInjuredType';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static confined(options: IRequestOptions = {}): Promise<MBConfinedListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/Confined';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static treatmentType(options: IRequestOptions = {}): Promise<MBTreatmentTypeListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/TreatmentType';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static maritalStatus(options: IRequestOptions = {}): Promise<MBMaritalStatusListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/MaritalStatus';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static memberDetailCard(
    params: {
      /**  */
      memberId?: number;
      /**  */
      width?: number;
      /**  */
      isDownload?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MemberCardImage> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/MemberDetailCard';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { memberId: params['memberId'], width: params['width'], isDownload: params['isDownload'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static memberDetailCardHtml(
    params: {
      /**  */
      memberId?: number;
      /**  */
      isDownload?: boolean;
      /**  */
      isFontEmbeded?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/MemberDetailCardHTML';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        memberId: params['memberId'],
        isDownload: params['isDownload'],
        isFontEmbeded: params['isFontEmbeded']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static memberDetail(options: IRequestOptions = {}): Promise<MBMemberDetailListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/MemberDetail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static policyBenefit(
    params: {
      /**  */
      memberId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBPolicyBenefitResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/PolicyBenefit';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { memberId: params['memberId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static policyBenefitV2(
    params: {
      /**  */
      memberId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBPolicyBenefitV2Response> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/PolicyBenefitV2';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { memberId: params['memberId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static policyBenefitV2Imr(
    params: {
      /**  */
      memberId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBPolicyBenefitV2IMRResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/PolicyBenefitV2IMR';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { memberId: params['memberId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static bankInfo(
    params: {
      /**  */
      memberId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBBankInfoResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/BankInfo';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { memberId: params['memberId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static beneficiaries(
    params: {
      /**  */
      memberId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBBeneficiaryV2ListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/Beneficiaries';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { memberId: params['memberId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static claimHistory(
    params: {
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      claimStatusId?: number;
      /**  */
      claimTypeId?: number;
      /**  */
      oldestFirst?: number;
      /**  */
      type?: string;
      /**  */
      beneficiaryId?: number;
      /**  */
      memberId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClaimHistoryResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/ClaimHistory';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        PageNumber: params['pageNumber'],
        PageSize: params['pageSize'],
        ClaimStatusId: params['claimStatusId'],
        ClaimTypeId: params['claimTypeId'],
        OldestFirst: params['oldestFirst'],
        type: params['type'],
        BeneficiaryId: params['beneficiaryId'],
        MemberId: params['memberId']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static submitClaim(
    params: {
      /**  */
      body?: MBSubmitNewClaimV2Request;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBSubmitNewClaimResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/SubmitClaim';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cancelClaim(
    params: {
      /**  */
      body?: FDCancelClaimRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FDCancelClaimResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/CancelClaim';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static downloadClaim(
    params: {
      /**  */
      claimId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/DownloadClaim/{ClaimId}';
      url = url.replace('{ClaimId}', params['claimId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static claimDetailForEdit(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FDClaimDetailForEditResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/ClaimDetailForEdit/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static claimDetail(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClaimHistoryResponseItem> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/ClaimDetail/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static downloadCard(
    params: {
      /**  */
      memberId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/DownloadCard';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { memberId: params['memberId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static setDefault(
    params: {
      /**  */
      body?: MBDetailIdRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GeneralMessageResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/SetDefault';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static register(
    params: {
      /**  */
      body?: MBRegisterV2Request;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBRegisterResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/Register';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static registerValidateOtp(
    params: {
      /**  */
      body?: MBRegisterValidateOTPRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBRegisterValidateOTPV2Response> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/RegisterValidateOTP';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static profile(
    params: {
      /**  */
      memberId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBProfileResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/Profile';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { memberId: params['memberId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateProfile(
    params: {
      /**  */
      body?: MBUpdateProfileV2Request;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBProfileResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/UpdateProfile';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static policyNotFound(
    params: {
      /**  */
      body?: MBPolicyNotFoundRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GeneralMessageResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/PolicyNotFound';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updatePartialProfile(
    params: {
      /**  */
      body?: MBUpdatePartialProfileRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBProfileResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/UpdatePartialProfile';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static document(
    params: {
      /**  */
      memberId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBDocumentListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/Document';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { memberId: params['memberId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateBankInfo(
    params: {
      /**  */
      body?: MBUpdateBankInfoRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBUpdateBankInfoResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/UpdateBankInfo';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static relationship(options: IRequestOptions = {}): Promise<MBRelationshipListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/Relationship';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static listBeneficiary(
    params: {
      /**  */
      memberId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBNewBeneficiaryListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/ListBeneficiary';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { memberId: params['memberId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static submitBeneficiary(
    params: {
      /**  */
      body?: MBNewBeneficiaryRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GeneralMessageResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/SubmitBeneficiary';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static editBeneficiary(
    params: {
      /**  */
      beneficiaryId: number;
      /**  */
      memberId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBNewBeneficiaryListResponseItem> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/EditBeneficiary/{beneficiaryId}';
      url = url.replace('{beneficiaryId}', params['beneficiaryId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { memberId: params['memberId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateBeneficiary(
    params: {
      /**  */
      body?: MBNewBeneficiaryRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GeneralMessageResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/UpdateBeneficiary';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteBeneficiary(
    params: {
      /**  */
      body?: MBNewBeneficiaryRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GeneralMessageResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/DeleteBeneficiary';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateAmountPercentBeneficiary(
    params: {
      /**  */
      body?: MBNewBeneficiaryRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GeneralMessageResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/UpdateAmountPercentBeneficiary';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static uploadProfilePhotoBeneficiary(
    params: {
      /**  */
      file?: any;
      /**  */
      beneficiaryId?: number;
      /**  */
      memberId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ImageUploadResponseFile> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/UploadProfilePhotoBeneficiary';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('File', item as any);
          }
        } else {
          data.append('File', params['file'] as any);
        }
      }

      if (params['beneficiaryId']) {
        if (Object.prototype.toString.call(params['beneficiaryId']) === '[object Array]') {
          for (const item of params['beneficiaryId'] as any) {
            data.append('BeneficiaryId', item as any);
          }
        } else {
          data.append('BeneficiaryId', params['beneficiaryId'] as any);
        }
      }

      if (params['memberId']) {
        if (Object.prototype.toString.call(params['memberId']) === '[object Array]') {
          for (const item of params['memberId'] as any) {
            data.append('MemberId', item as any);
          }
        } else {
          data.append('MemberId', params['memberId'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static paymentKhqr(
    params: {
      /**  */
      memberId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBPaymentKHQRListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/PaymentKHQR';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { memberId: params['memberId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static submitRate(
    params: {
      /**  */
      body?: MBRateRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GeneralMessageResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/SubmitRate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static claimTypeCanSubmit(
    params: {
      /**  */
      memberId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBClaimTypeCanSubmitResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/ClaimTypeCanSubmit';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { memberId: params['memberId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static banks(
    params: {
      /**  */
      memberId?: number;
      /**  */
      search?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MBBankListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/Banks';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { memberId: params['memberId'], Search: params['search'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static memberDocumentPayment(
    params: {
      /**  */
      memberId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MemberDocumentPayment> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/MemberDocumentPayment';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { memberId: params['memberId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static serviceLogs(
    params: {
      /**  */
      dateFrom?: string;
      /**  */
      dateTo?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceLogRecordResponseGeneralListResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/ServiceLogs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DateFrom: params['dateFrom'],
        DateTo: params['dateTo'],
        PageNumber: params['pageNumber'],
        PageSize: params['pageSize']
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static serviceLogs1(
    params: {
      /**  */
      uniqueKey: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceLogRecordResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/MemberV2/ServiceLogs/{UniqueKey}';
      url = url.replace('{UniqueKey}', params['uniqueKey'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class OktaApi {
  /**
   *
   */
  static test(options: IRequestOptions = {}): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/OKTAAPI/Test';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static testLogin(options: IRequestOptions = {}): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/OKTAAPI/TestLogin';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getUserOktaToken(
    params: {
      /**  */
      token?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/OKTAAPI/GetUserOKTAToken';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { token: params['token'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getUserProfil(options: IRequestOptions = {}): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/OKTAAPI/GetUserProfil';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static auth(options: IRequestOptions = {}): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/OKTAAPI/Auth';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static testLoginCallBack(
    params: {
      /**  */
      token?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/OKTA/TestLoginCallBack';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { token: params['token'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static login(
    params: {
      /**  */
      body?: OktaLoginRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/OKTA/Login';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static listOktaUser(options: IRequestOptions = {}): Promise<OktaUserResponse[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/OKTA/ListOktaUser';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendSms(
    params: {
      /**  */
      userId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/OKTA/SendSMS';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { userId: params['userId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static verifySms(
    params: {
      /**  */
      userId?: string;
      /**  */
      code?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/OKTA/VerifySMS';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { userId: params['userId'], code: params['code'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static testClientAuth(options: IRequestOptions = {}): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/OKTA/TestClientAuth';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createCinicUser(
    params: {
      /**  */
      body?: CXCreateClinicUserRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXCreateClinicUserResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Okta/CreateCinicUser';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createPolicyHolderUser(
    params: {
      /**  */
      body?: CXCreatePolicyHolderUserRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXCreateClinicUserResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Okta/CreatePolicyHolderUser';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createMemberUser(
    params: {
      /**  */
      userId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CXCreateClinicUserResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Okta/CreateMemberUser';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { userId: params['userId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static testEnroll(
    params: {
      /**  */
      userId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Okta/TestEnroll';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { userId: params['userId'] };

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static testUserOkta(
    params: {
      /**  */
      userId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/OKTA/TestUserOkta';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { userId: params['userId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static migrateMember(
    params: {
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/OKTA/MigrateMember';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { PageNumber: params['pageNumber'], PageSize: params['pageSize'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static migrateClinic(
    params: {
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/OKTA/MigrateClinic';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { PageNumber: params['pageNumber'], PageSize: params['pageSize'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static migrateHr(
    params: {
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/OKTA/MigrateHR';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { PageNumber: params['pageNumber'], PageSize: params['pageSize'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class OktaMigrationApi {
  /**
   *
   */
  static migrate(options: IRequestOptions = {}): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/OKTA/Migrate';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static checkMigration(
    params: {
      /**  */
      jobId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/OKTA/CheckMigration';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { jobId: params['jobId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export interface AccountBRResetPasswordRequest {
  /**  */
  userName: string;

  /**  */
  dateOfBirth: Date;
}

export interface AccountBRResetPasswordResponse {
  /**  */
  resetPasswordToken?: string;

  /**  */
  resetPasswordTokenId?: string;

  /**  */
  emailMask?: string;
}

export interface AccountChangePasswordRequest {
  /**  */
  currentPassword: string;

  /**  */
  newPassword: string;

  /**  */
  otp?: string;
}

export interface AccountChangePasswordResponse {
  /**  */
  message?: string;
}

export interface AccountdummyRequest {
  /**  */
  login?: string;

  /**  */
  password?: string;

  /**  */
  birthDate?: Date;

  /**  */
  type?: string;
}

export interface AccountdummyResponse {
  /**  */
  dummyLogin?: string;
}

export interface AccountEffectiveDateResponsecs {
  /**  */
  effectiveDateDisplay?: string;

  /**  */
  effectiveDate?: Date;
}

export interface AccountFDLoginByUserOTPRequest {
  /**  */
  userName?: string;

  /**  */
  otp?: string;
}

export interface AccountFDLoginByUserOTPResponse {
  /**  */
  userName?: string;

  /**  */
  accessToken?: string;
}

export interface AccountFDOTPByUserPwdRequest {
  /**  */
  userName?: string;

  /**  */
  password?: string;
}

export interface AccountFDOTPByUserPwdResponse {
  /**  */
  userName?: string;

  /**  */
  otp?: string;

  /**  */
  otpToken?: string;

  /**  */
  waitingSeconds?: number;

  /**  */
  phoneMask?: string;

  /**  */
  emailMask?: string;

  /**  */
  oktaSession?: string;
}

export interface AccountFDResetPasswordRequest {
  /**  */
  phone?: string;

  /**  */
  birthDate?: Date;
}

export interface AccountFDResetPasswordResponse {
  /**  */
  resetPasswordTokenId?: string;

  /**  */
  otp?: string;

  /**  */
  otpToken?: string;

  /**  */
  waitingSeconds?: number;

  /**  */
  phoneMask?: string;

  /**  */
  oktaSession?: string;
}

export interface AccountFDSetNewPasswordRequest {
  /**  */
  resetPasswordTokenId?: string;

  /**  */
  resetPasswordToken?: string;

  /**  */
  newPassword?: string;
}

export interface AccountGetLatestNotificationResponse {
  /**  */
  numberOfUnread?: number;

  /**  */
  items?: AccountGetLatestNotificationResponseItem[];

  /**  */
  totalCount?: number;

  /**  */
  pageNumber?: number;

  /**  */
  pageSize?: number;

  /**  */
  totalPage?: number;
}

export interface AccountGetLatestNotificationResponseItem {
  /**  */
  id?: number;

  /**  */
  title?: string;

  /**  */
  titleAlt?: string;

  /**  */
  timeDisplay?: string;

  /**  */
  type?: string;

  /**  */
  refferenceId?: string;

  /**  */
  refferenceParam1?: string;

  /**  */
  refferenceParam2?: string;

  /**  */
  isRead?: boolean;
}

export interface AccountHRChangePasswordRequest {
  /**  */
  currentPassword: string;

  /**  */
  newPassword: string;

  /**  */
  otp: string;
}

export interface AccountHRLoginWithMailOTPRequest {
  /**  */
  email: string;

  /**  */
  otp: string;
}

export interface AccountHRLoginWithMailOTPResponse {
  /**  */
  email?: string;

  /**  */
  accessToken?: string;
}

export interface AccountHROTPByMailPwdRequest {
  /**  */
  email: string;

  /**  */
  password: string;
}

export interface AccountHROTPByMailPwdResponse {
  /**  */
  email?: string;

  /**  */
  otp?: string;

  /**  */
  otpToken?: string;

  /**  */
  waitingSeconds?: number;

  /**  */
  phoneMask?: string;

  /**  */
  emailMask?: string;

  /**  */
  oktaSession?: string;
}

export interface AccountHRResetPasswordRequest {
  /**  */
  email: string;

  /**  */
  policyNumber: string;
}

export interface AccountHRResetPasswordResponse {
  /**  */
  resetPasswordToken?: string;

  /**  */
  resetPasswordTokenId?: string;
}

export interface AccountLogoutResponse {
  /**  */
  message?: string;
}

export interface AccountMarkNotificationReadRequest {
  /**  */
  id?: number;
}

export interface AccountMBLoginByPhoneOTPRequest {
  /**  */
  phone?: string;

  /**  */
  otp?: string;
}

export interface AccountMBLoginByPhoneOTPResponse {
  /**  */
  phone?: string;

  /**  */
  accessToken?: string;

  /**  */
  refreshToken?: string;

  /**  */
  userId?: string;
}

export interface AccountMBOTPByPhonePwdResponse {
  /**  */
  phone?: string;

  /**  */
  otp?: string;

  /**  */
  otpToken?: string;

  /**  */
  waitingSeconds?: number;

  /**  */
  phoneMask?: string;

  /**  */
  oktaSession?: string;
}

export interface AccountMBOTPByUserPwdRequest {
  /**  */
  phone?: string;

  /**  */
  password?: string;
}

export interface AccountMBResetPasswordRequest {
  /**  */
  phone?: string;

  /**  */
  birthDate?: Date;
}

export interface AccountMBResetPasswordResponse {
  /**  */
  resetPasswordTokenId?: string;

  /**  */
  otp?: string;

  /**  */
  otpToken?: string;

  /**  */
  waitingSeconds?: number;

  /**  */
  phoneMask?: string;

  /**  */
  oktaSession?: string;
}

export interface AccountMBResetPasswordTokenRequest {
  /**  */
  resetPasswordTokenId?: string;

  /**  */
  otp?: string;
}

export interface AccountMBResetPasswordTokenResponse {
  /**  */
  resetPasswordTokenId?: string;

  /**  */
  resetPasswordToken?: string;
}

export interface AccountMBSetNewPasswordRequest {
  /**  */
  resetPasswordTokenId?: string;

  /**  */
  resetPasswordToken?: string;

  /**  */
  newPassword?: string;
}

export interface AccountMBSetNewPasswordResponse {
  /**  */
  message?: string;
}

export interface AccountProfileResponse {
  /**  */
  fullName?: string;

  /**  */
  userName?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  organizationName?: string;

  /**  */
  phoneNumberDisplay?: string;

  /**  */
  email?: string;

  /**  */
  dateOfBirthDisplay?: string;

  /**  */
  dateOfBirth?: Date;

  /**  */
  identityNumber?: string;

  /**  */
  gender?: string;

  /**  */
  roleDisplay?: string;

  /**  */
  homeAddressDisplay?: string;

  /**  */
  profilePhotoUrl?: string;

  /**  */
  paymentDetail?: AccountProfileResponsePaymentDetail;

  /**  */
  companyDetail?: AccountProfileResponseCompanyDetail;

  /**  */
  clinicDetail?: AccountProfileResponseClinicDetail;

  /**  */
  memberDetail?: AccountProfileResponseMemberDetail;

  /**  */
  employeeDetail?: AccountProfileResponseEmployeeDetail;

  /**  */
  statusDisplay?: string;

  /**  */
  expireDateDisplay?: string;

  /**  */
  effectiveDateDisplay?: string;

  /**  */
  expireDate?: Date;

  /**  */
  effectiveDate?: Date;

  /**  */
  approvalLimit?: number;
}

export interface AccountProfileResponseClinicDetail {
  /**  */
  clinicId?: number;

  /**  */
  clinicName?: string;
}

export interface AccountProfileResponseCompanyDetail {
  /**  */
  companyId?: number;

  /**  */
  companyName?: string;

  /**  */
  companyAddress?: string;
}

export interface AccountProfileResponseEmployeeDetail {
  /**  */
  employeeNumber?: string;

  /**  */
  employmentDate?: string;

  /**  */
  salary?: number;
}

export interface AccountProfileResponseMemberDetail {
  /**  */
  memberNumber?: string;

  /**  */
  policyNumber?: string;

  /**  */
  planName?: string;

  /**  */
  effectiveDate?: Date;

  /**  */
  expireDate?: Date;
}

export interface AccountProfileResponsePaymentDetail {
  /**  */
  bankId?: number;

  /**  */
  bankName?: string;

  /**  */
  bankAccountNumber?: string;

  /**  */
  bankAccountName?: string;
}

export interface AccountProfileV2Response {
  /**  */
  fullName?: string;

  /**  */
  userName?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  organizationName?: string;

  /**  */
  phoneNumberDisplay?: string;

  /**  */
  email?: string;

  /**  */
  dateOfBirthDisplay?: string;

  /**  */
  dateOfBirth?: Date;

  /**  */
  identityNumber?: string;

  /**  */
  gender?: string;

  /**  */
  roleDisplay?: string;

  /**  */
  homeAddressDisplay?: string;

  /**  */
  profilePhotoUrl?: string;

  /**  */
  paymentDetail?: AccountProfileResponsePaymentDetail;

  /**  */
  companyDetail?: AccountProfileResponseCompanyDetail;

  /**  */
  clinicDetail?: AccountProfileResponseClinicDetail;

  /**  */
  memberDetail?: AccountProfileResponseMemberDetail;

  /**  */
  employeeDetail?: AccountProfileResponseEmployeeDetail;

  /**  */
  statusDisplay?: string;

  /**  */
  expireDateDisplay?: string;

  /**  */
  effectiveDateDisplay?: string;

  /**  */
  expireDate?: Date;

  /**  */
  effectiveDate?: Date;

  /**  */
  approvalLimit?: number;

  /**  */
  type?: string;

  /**  */
  occupation?: string;

  /**  */
  maritalStatusId?: number;

  /**  */
  memberPhoneNumber?: string;

  /**  */
  memberPhoneNumberDisplay?: string;
}

export interface AccountResendOTPRequest {
  /**  */
  otpToken?: string;

  /**  */
  isApi?: boolean;
}

export interface AccountResendOTPResponse {
  /**  */
  newOTPToken?: string;

  /**  */
  otp?: string;

  /**  */
  phoneMask?: string;

  /**  */
  emailMask?: string;

  /**  */
  waitingSeconds?: number;
}

export interface BodyInjuredTypeRequest {
  /**  */
  id?: number;

  /**  */
  numberPartInjured?: string;
}

export interface BrokeGenerateListMemberRequest {
  /**  */
  planId?: number;

  /**  */
  policyHolderId?: number;

  /**  */
  search?: string;
}

export interface BrokeGenerateListPolicyHolderRequest {
  /**  */
  status?: boolean;

  /**  */
  search?: string;
}

export interface BrokerChangeStatusMemberRequest {
  /**  */
  memberId: number;

  /**  */
  active?: boolean;
}

export interface BrokerDependentItemResponse {
  /**  */
  memberId?: number;

  /**  */
  name?: string;

  /**  */
  profilePhotoUrl?: string;

  /**  */
  fileName?: string;
}

export interface BrokerDetailPolicyHolderItemResponse {
  /**  */
  policyHolderId?: number;

  /**  */
  policyNumber?: string;

  /**  */
  policyHolderName?: string;

  /**  */
  totalMember?: number;

  /**  */
  totalPremium?: string;

  /**  */
  status?: string;

  /**  */
  effectiveDate?: Date;

  /**  */
  effectiveDateDisplay?: string;

  /**  */
  expireDate?: Date;

  /**  */
  expireDateDisplay?: string;
}

export interface BrokerGenerateListClaimRequest {
  /**  */
  policyHolderId?: number;

  /**  */
  claimStatusId?: number;

  /**  */
  claimTypeId?: number;

  /**  */
  oldestFirst?: number;

  /**  */
  beneficiaryId?: number;

  /**  */
  search?: string;

  /**  */
  memberId?: number;

  /**  */
  fromDate?: Date;

  /**  */
  toDate?: Date;
}

export interface BrokerGenerateListMemberReponse {
  /**  */
  fileName?: string;
}

export interface BrokerListPolicyHolderItemResponse {
  /**  */
  policyHolderId?: number;

  /**  */
  policyNumber?: string;

  /**  */
  policyHolderName?: string;

  /**  */
  totalMember?: number;

  /**  */
  amountApprovedClaim?: number;

  /**  */
  amountDelinedClaim?: number;

  /**  */
  amountPendingClaim?: number;

  /**  */
  amountSendBackClaim?: number;

  /**  */
  status?: string;
}

export interface BrokerListPolicyHolderResponse {
  /**  */
  data?: BrokerListPolicyHolderItemResponse[];

  /**  */
  totalCount?: number;

  /**  */
  pageNumber?: number;

  /**  */
  pageSize?: number;

  /**  */
  totalPage?: number;
}

export interface BrokerMBPerosnalDetailResponse {
  /**  */
  phoneNumberDisplay?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  email?: string;

  /**  */
  occupation?: string;

  /**  */
  maritalStatus?: string;

  /**  */
  address?: string;
}

export interface BrokerMBPolicyProfileResponse {
  /**  */
  title?: string;

  /**  */
  policyNumber?: string;

  /**  */
  isDependent?: boolean;

  /**  */
  persionalDetail?: BrokerMBPerosnalDetailResponse;

  /**  */
  paymentDetail?: AccountProfileResponsePaymentDetail;

  /**  */
  companyDetail?: AccountProfileResponseCompanyDetail;

  /**  */
  clinicDetail?: AccountProfileResponseClinicDetail;

  /**  */
  memberDetail?: AccountProfileResponseMemberDetail;

  /**  */
  employeeDetail?: AccountProfileResponseEmployeeDetail;

  /**  */
  dependents?: BrokerDependentItemResponse[];
}

export interface BrokerMBProfileResponse {
  /**  */
  name?: string;

  /**  */
  status?: string;

  /**  */
  isActive?: boolean;

  /**  */
  profilePhotoUrl?: string;

  /**  */
  fileName?: string;

  /**  */
  systemProfile?: BrokerMBSystemProfileResponse;

  /**  */
  policyProfile?: BrokerMBPolicyProfileResponse;
}

export interface BrokerMBSystemProfileResponse {
  /**  */
  phoneNumberDisplay?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  dateOfBirthDisplay?: string;

  /**  */
  dateOfBirth?: Date;

  /**  */
  identityNumber?: string;

  /**  */
  gender?: string;

  /**  */
  profilePhotoUrl?: string;

  /**  */
  fileName?: string;
}

export interface BrokerRequestRefund {
  /**  */
  policyHolderId?: number;
}

export interface ClaimHistoryResponse {
  /**  */
  data?: ClaimHistoryResponseItem[];

  /**  */
  totalCount?: number;

  /**  */
  pageNumber?: number;

  /**  */
  pageSize?: number;

  /**  */
  totalPage?: number;

  /**  */
  totalClaimApproved?: string;

  /**  */
  totalClaimRedFlag?: string;
}

export interface ClaimHistoryResponseItem {
  /**  */
  id?: number;

  /**  */
  code?: string;

  /**  */
  memberNumber?: string;

  /**  */
  memberPhoneNumber?: string;

  /**  */
  memberType?: string;

  /**  */
  name?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  alternativePhoneNumber?: string;

  /**  */
  birthDate?: Date;

  /**  */
  birthDateDisplay?: string;

  /**  */
  policyNumber?: string;

  /**  */
  idCardPassport?: string;

  /**  */
  gender?: string;

  /**  */
  dateTimeAdmission?: Date;

  /**  */
  dateTimeAdmissionDisplay?: string;

  /**  */
  dateTimeDischarge?: Date;

  /**  */
  dateTimeDischargeDisplay?: string;

  /**  */
  totalHours?: number;

  /**  */
  submissionDate?: Date;

  /**  */
  submissionDateDisplay?: string;

  /**  */
  submitedBy?: string;

  /**  */
  clinicId?: number;

  /**  */
  clinicName?: string;

  /**  */
  otherClinicName?: string;

  /**  */
  amountSubmited?: number;

  /**  */
  amountApproved?: number;

  /**  */
  approvedBy?: string;

  /**  */
  approvedDate?: Date;

  /**  */
  approvedDateDisplay?: string;

  /**  */
  reviewDate?: Date;

  /**  */
  reviewDateDisplay?: string;

  /**  */
  amountCollectFromCustomer?: number;

  /**  */
  claimTypeId?: number;

  /**  */
  claimTypeName?: string;

  /**  */
  claimStatusId?: number;

  /**  */
  claimStatusName?: string;

  /**  */
  claimStatusNameAlt?: string;

  /**  */
  aiaNote?: string;

  /**  */
  inOutPatient?: string;

  /**  */
  bankName?: string;

  /**  */
  bankAccountNumber?: string;

  /**  */
  bankAccountName?: string;

  /**  */
  claimItems?: ClaimHistoryResponseItemClaimItem[];

  /**  */
  uploadedFiles?: ClaimHistoryResponseItemClaimItemFileItem[];

  /**  */
  plannedVisitDate?: Date;

  /**  */
  plannedVisitDateDisplay?: string;

  /**  */
  visitDate?: Date;

  /**  */
  visitDateDisplay?: string;

  /**  */
  visitReason?: string;

  /**  */
  clinicPhoneNumber?: string;

  /**  */
  clinicReference?: string;

  /**  */
  clinicComment?: string;

  /**  */
  comment?: string;

  /**  */
  closedDate?: Date;

  /**  */
  closedDateDisplay?: string;

  /**  */
  declinedBy?: string;

  /**  */
  reviewedBy?: string;

  /**  */
  declinedDate?: Date;

  /**  */
  declinedDateDisplay?: string;

  /**  */
  plannedDischargeDateTime?: Date;

  /**  */
  plannedDischargeDateTimeDisplay?: string;

  /**  */
  closedBy?: string;

  /**  */
  cancelledBy?: string;

  /**  */
  cancelledDate?: Date;

  /**  */
  cancelledDateDisplay?: string;

  /**  */
  markAsPaidDate?: Date;

  /**  */
  markAsPaidDateDisplay?: string;

  /**  */
  payTo?: string;

  /**  */
  treatmentType?: MBTreatmentTypeListResponseItem;

  /**  */
  dateOfEvent?: Date;

  /**  */
  dateOfEventDisplay?: string;

  /**  */
  nameCriticalIllness?: string;

  /**  */
  desSymptomsDateOnset?: string;

  /**  */
  dateFirstConsultation?: Date;

  /**  */
  dateFirstConsultationDisplay?: string;

  /**  */
  howLongSignsSymptoms?: string;

  /**  */
  dateConsultation?: Date;

  /**  */
  dateConsultationDisplay?: string;

  /**  */
  nameOfDoctor?: string;

  /**  */
  reasonConsultation?: string;

  /**  */
  hospitalClinicAddress?: string;

  /**  */
  isCriticalIllness?: boolean;

  /**  */
  fullDetail?: string;

  /**  */
  causeOfAccident?: string;

  /**  */
  degreeBurn?: MBDegreeBurnListResponseItem;

  /**  */
  partBodyBurned?: string;

  /**  */
  placeOfAccident?: string;

  /**  */
  confined?: MBConfinedListResponseItem;

  /**  */
  bodyInjuredTypeItem?: MBBodyInjuredTypeClaimHistoryResponseItem[];

  /**  */
  exactDutiesAfterTPD?: string;

  /**  */
  monthlyIncome?: number;

  /**  */
  nameBusinessOrEmployer?: string;

  /**  */
  addressBusinessOrEmployer?: string;

  /**  */
  phoneBusinessOrEmployer?: string;

  /**  */
  exactDutiesBeforeTPD?: string;

  /**  */
  monthlyIncomeBeforeTPD?: number;

  /**  */
  nameBusinessOrEmployerBeforeTPD?: string;

  /**  */
  addressBusinessOrEmployerBeforeTPD?: string;

  /**  */
  phoneBusinessOrEmployerBeforeTPD?: string;

  /**  */
  dateLastWorkingDay?: Date;

  /**  */
  dateLastWorkingDayDisplay?: string;

  /**  */
  dateReturnWork?: Date;

  /**  */
  dateReturnWorkDisplay?: string;

  /**  */
  tpdWasWue?: MBTPDWasWueListResponseItem;

  /**  */
  illnessCausedTPD?: string;

  /**  */
  describeSymptom?: string;

  /**  */
  dateSymptomsStarted?: Date;

  /**  */
  dateSymptomsStartedDisplay?: string;

  /**  */
  manyMonthsInsuredFirstConsultation?: string;

  /**  */
  nameDoctorFristConsultedIllness?: string;

  /**  */
  doctorCommentFristConsulted?: string;

  /**  */
  haClaiming?: MBHAClaimingListResponseItem;

  /**  */
  totalNights?: number;

  /**  */
  additionDate?: Date;

  /**  */
  additionDateDisplay?: string;

  /**  */
  systemAutoApproveStatus?: string;

  /**  */
  policyHolderName?: string;

  /**  */
  memberId?: number;

  /**  */
  address?: string;

  /**  */
  email?: string;

  /**  */
  phoneNumberDisplay?: string;

  /**  */
  alternativePhoneNumberDisplay?: string;

  /**  */
  claimHistoryComments?: CXClaimHistoryCommentResponse[];

  /**  */
  memberStatus?: string;

  /**  */
  memberExpireDateDisplay?: string;

  /**  */
  memberEffectiveDateDisplay?: string;

  /**  */
  memberExpireDate?: Date;

  /**  */
  memberEffectiveDate?: Date;

  /**  */
  isMemberBlackList?: boolean;

  /**  */
  isPolicyHolderBlackList?: boolean;

  /**  */
  isClinicBlackList?: boolean;

  /**  */
  policyHolderId?: number;

  /**  */
  invoiceDate?: Date;

  /**  */
  invoiceDateDisplay?: string;

  /**  */
  invoiceNumber?: string;

  /**  */
  uuid?: string;
}

export interface ClaimHistoryResponseItemClaimItem {
  /**  */
  categoryName?: string;

  /**  */
  categoryNameAlt?: string;

  /**  */
  amount?: number;
}

export interface ClaimHistoryResponseItemClaimItemFileItem {
  /**  */
  uniqueName?: string;

  /**  */
  isDecline?: boolean;

  /**  */
  fileUrl?: string;
}

export interface CommentServiceLogRequest {
  /**  */
  uniqueKey?: string;

  /**  */
  comment?: string;
}

export interface CX2UpdateMemberProfileRequest {
  /**  */
  phoneNumber?: string;

  /**  */
  email?: string;

  /**  */
  address?: string;

  /**  */
  otp?: string;

  /**  */
  memberId: number;

  /**  */
  identityNumber?: string;

  /**  */
  birthDate: Date;

  /**  */
  gender?: string;

  /**  */
  maritalStatusId?: number;

  /**  */
  memberPhoneNumber?: string;

  /**  */
  occupation?: string;
}

export interface CXAddClinicExceptionRequest {
  /**  */
  clinicId?: number;
}

export interface CXAddMemberDocumentRequest {
  /**  */
  memberId: number;

  /**  */
  uniqueName: string;

  /**  */
  documentName: string;
}

export interface CXAddMemberExceptionRequest {
  /**  */
  memberId?: number;
}

export interface CXAddPolicyHolderDocumentRequest {
  /**  */
  policyHolderId: number;

  /**  */
  uniqueName: string;

  /**  */
  documentName: string;
}

export interface CXAddPolicyHolderExceptionRequest {
  /**  */
  policyHolderId?: number;
}

export interface CXAdjustmentBalanceRequest {
  /**  */
  policyHolderId?: number;

  /**  */
  transactionTypeId?: number;

  /**  */
  amount?: number;

  /**  */
  remark?: string;

  /**  */
  englishTitle?: string;

  /**  */
  khmerTitle?: string;
}

export interface CXAmountTerminateRequest {
  /**  */
  amountTerminateMembers?: HRAmountTerminateRequestItem[];

  /**  */
  policyHolderId?: number;
}

export interface CXApproveClaimRequest {
  /**  */
  claimId?: number;

  /**  */
  note?: string;

  /**  */
  approveAmount?: number;

  /**  */
  amountCollectFromCustomer?: number;
}

export interface CXApproveClaimResponse {
  /**  */
  message?: string;
}

export interface CXChangeStatusRateRequest {
  /**  */
  rateId?: number;

  /**  */
  status?: string;
}

export interface CXChangeSystemAutoApproveStatusRequest {
  /**  */
  claimHistoryId?: number;

  /**  */
  systemAutoApproveStatus?: string;
}

export interface CXClaimHistoryCommentRequest {
  /**  */
  claimHistoryId?: number;

  /**  */
  comment?: string;
}

export interface CXClaimHistoryCommentResponse {
  /**  */
  id?: number;

  /**  */
  claimHistoryId?: number;

  /**  */
  comment?: string;

  /**  */
  createdAtDisplay?: string;

  /**  */
  createdAt?: Date;

  /**  */
  userName?: string;
}

export interface CXClaimSummaryResponse {
  /**  */
  numberOfPendingClaim?: number;

  /**  */
  numberOfApprovedClaim?: number;

  /**  */
  numberOfDeclinedClaim?: number;

  /**  */
  numberOfAdditionRequiredClaim?: number;

  /**  */
  numberOfClosedClaim?: number;

  /**  */
  numberOfCancelledClaim?: number;

  /**  */
  numberOfPaidClaim?: number;

  /**  */
  numberOfAutoApprovedClaim?: number;
}

export interface CXClinicDetailResponse {
  /**  */
  name?: string;

  /**  */
  bankId?: number;

  /**  */
  bankName?: string;

  /**  */
  bankAccountNumber?: string;

  /**  */
  bankAccountName?: string;

  /**  */
  address?: string;

  /**  */
  province?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  email?: string;

  /**  */
  latitude?: number;

  /**  */
  longitude?: number;

  /**  */
  contactPersonFirstName?: string;

  /**  */
  contactPersonLastName?: string;

  /**  */
  contactPersonGender?: string;

  /**  */
  contactPersonEmail?: string;

  /**  */
  contactPersonPhonePrimary?: string;

  /**  */
  contactPersonPhoneSecondary?: string;

  /**  */
  specialistDescription?: string;

  /**  */
  isOpen24H?: boolean;

  /**  */
  openTimes?: CXClinicDetailResponseOpenTime[];
}

export interface CXClinicDetailResponseOpenTime {
  /**  */
  day?: string;

  /**  */
  timeFrom?: Date;

  /**  */
  timeTo?: Date;
}

export interface CXClinicUsersResponse {
  /**  */
  groups?: CXClinicUsersResponseGroup[];
}

export interface CXClinicUsersResponseGroup {
  /**  */
  name?: string;

  /**  */
  users?: CXClinicUsersResponseGroupUser[];
}

export interface CXClinicUsersResponseGroupUser {
  /**  */
  id?: string;

  /**  */
  fullName?: string;

  /**  */
  status?: string;
}

export interface CXCommentRateResponseItem {
  /**  */
  id?: number;

  /**  */
  comment?: string;

  /**  */
  createdAtDisplay?: string;

  /**  */
  createdAt?: Date;

  /**  */
  userName?: string;
}

export interface CXCreateCinicRequest {
  /**  */
  name?: string;

  /**  */
  bankId?: number;

  /**  */
  bankAccountNumber?: string;

  /**  */
  bankAccountName?: string;

  /**  */
  address?: string;

  /**  */
  province?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  email?: string;

  /**  */
  latitude?: number;

  /**  */
  longitude?: number;

  /**  */
  contactPersonFirstName?: string;

  /**  */
  contactPersonLastName?: string;

  /**  */
  contactPersonGender?: string;

  /**  */
  contactPersonEmail?: string;

  /**  */
  contactPersonPhonePrimary?: string;

  /**  */
  contactPersonPhoneSecondary?: string;

  /**  */
  specialistDescription?: string;

  /**  */
  isOpen24H?: boolean;

  /**  */
  openTimes?: CXCreateCinicRequestOpenTime[];
}

export interface CXCreateCinicRequestOpenTime {
  /**  */
  day?: string;

  /**  */
  timeFrom?: Date;

  /**  */
  timeTo?: Date;
}

export interface CXCreateCinicResponse {
  /**  */
  id?: number;
}

export interface CXCreateClinicUserRequest {
  /**  */
  clinicId: number;

  /**  */
  firstName: string;

  /**  */
  lastName: string;

  /**  */
  userName: string;

  /**  */
  password: string;

  /**  */
  phoneNumber: string;

  /**  */
  email?: string;

  /**  */
  birthDate: Date;

  /**  */
  identityNumber?: string;

  /**  */
  gender?: string;

  /**  */
  address?: string;

  /**  */
  role: string;
}

export interface CXCreateClinicUserResponse {
  /**  */
  userId?: string;
}

export interface CXCreatePolicyHolderUserRequest {
  /**  */
  policyHolderId: number;

  /**  */
  firstName: string;

  /**  */
  lastName: string;

  /**  */
  password: string;

  /**  */
  phoneNumber: string;

  /**  */
  email?: string;

  /**  */
  birthDate: Date;

  /**  */
  identityNumber?: string;

  /**  */
  gender?: string;

  /**  */
  address?: string;
}

export interface CXDeactivateClinicUserRequest {
  /**  */
  userId: string;
}

export interface CXDeactivateClinicUserResponse {
  /**  */
  message?: string;
}

export interface CXDeclineClaimRequest {
  /**  */
  claimId?: number;

  /**  */
  note?: string;
}

export interface CXDeclineClaimResponse {
  /**  */
  message?: string;
}

export interface CXGenerateListMemberRequest {
  /**  */
  planId?: number;

  /**  */
  search?: string;

  /**  */
  policyHolderId?: number;
}

export interface CXGenerateListRateReponse {
  /**  */
  fileName?: string;
}

export interface CXGenerateReportClaimHistoryRequest {
  /**  */
  dateFrom?: Date;

  /**  */
  dateTo?: Date;
}

export interface CXGenerateReportClaimHistoryResponse {
  /**  */
  reportFileName?: string;
}

export interface CXGetInitialLOGRuleResponse {
  /**  */
  autoApproveAmount?: number;

  /**  */
  clinicExceptions?: CXGetInitialLOGRuleResponseClinicException[];

  /**  */
  memberExceptions?: CXGetInitialLOGRuleResponseMemberException[];

  /**  */
  policyHolderExceptions?: CXGetInitialLOGRuleResponsePolicyHolderException[];
}

export interface CXGetInitialLOGRuleResponseClinicException {
  /**  */
  clinicId?: number;

  /**  */
  clinicName?: string;
}

export interface CXGetInitialLOGRuleResponseMemberException {
  /**  */
  memberId?: number;

  /**  */
  memberName?: string;
}

export interface CXGetInitialLOGRuleResponsePolicyHolderException {
  /**  */
  policyHolderId?: number;

  /**  */
  policyHolderName?: string;
}

export interface CXGetReimbursementRuleResponse {
  /**  */
  memberExceptions?: CXGetReimbursementRuleResponseMemberException[];

  /**  */
  policyHolderExceptions?: CXGetReimbursementRuleResponsePolicyHolderException[];
}

export interface CXGetReimbursementRuleResponseMemberException {
  /**  */
  memberId?: number;

  /**  */
  memberName?: string;
}

export interface CXGetReimbursementRuleResponsePolicyHolderException {
  /**  */
  policyHolderId?: number;

  /**  */
  policyHolderName?: string;
}

export interface CXMarkPaidClaimRequest {
  /**  */
  claimId?: number;
}

export interface CXMarkPaidClaimResponse {
  /**  */
  message?: string;
}

export interface CXMemberDocumentListResponse {
  /**  */
  policyNumber?: string;

  /**  */
  totalDocument?: number;

  /**  */
  documents?: MBDocumentListResponseItem[];
}

export interface CXPolicyHolderDetailResponse {
  /**  */
  policyHolderId?: number;

  /**  */
  policyNumber?: string;

  /**  */
  policyHolderName?: string;

  /**  */
  statusDisplay?: string;

  /**  */
  effectiveDate?: Date;

  /**  */
  expireDate?: Date;

  /**  */
  totalMember?: number;
}

export interface CXPolicyHolderDocumentsResponse {
  /**  */
  policyNumber?: string;

  /**  */
  totalDocument?: number;

  /**  */
  documents?: CXPolicyHolderDocumentsResponseDocument[];
}

export interface CXPolicyHolderDocumentsResponseDocument {
  /**  */
  id?: number;

  /**  */
  uniqueName?: string;

  /**  */
  originalName?: string;

  /**  */
  documentName?: string;

  /**  */
  documentNameAlt?: string;

  /**  */
  link?: string;

  /**  */
  createdAtDisplay?: string;

  /**  */
  createdAt?: Date;
}

export interface CXPolicyHolderMembersResponse {
  /**  */
  data?: CXPolicyHolderMembersResponseMember[];

  /**  */
  totalCount?: number;

  /**  */
  pageNumber?: number;

  /**  */
  pageSize?: number;

  /**  */
  totalPage?: number;
}

export interface CXPolicyHolderMembersResponseMember {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  memberNumber?: string;

  /**  */
  statusDisplay?: string;
}

export interface CXPolicyHolderPlansResponse {
  /**  */
  totalMembers?: number;

  /**  */
  plans?: CXPolicyHolderPlansResponsePlan[];
}

export interface CXPolicyHolderPlansResponsePlan {
  /**  */
  planId?: number;

  /**  */
  planName?: string;

  /**  */
  planNameAlt?: string;
}

export interface CXPolicyHolderProfileResponse {
  /**  */
  name?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  email?: string;

  /**  */
  address?: string;
}

export interface CXPolicyHoldersResponse {
  /**  */
  items?: CXPolicyHoldersResponseItem[];
}

export interface CXPolicyHoldersResponseItem {
  /**  */
  id?: number;

  /**  */
  name?: string;
}

export interface CXPolicyHolderUsersResponse {
  /**  */
  name?: string;

  /**  */
  users?: CXPolicyHolderUsersResponseUser[];
}

export interface CXPolicyHolderUsersResponseUser {
  /**  */
  id?: string;

  /**  */
  fullName?: string;
}

export interface CXRemoveClinicRequest {
  /**  */
  clinicId: number;
}

export interface CXRemoveMemberDocumentRequest {
  /**  */
  memberId?: number;

  /**  */
  memberDocumentId?: number;
}

export interface CXRemovePolicyHolderDocumentRequest {
  /**  */
  policyHolderDocumentId: number;
}

export interface CXRequireInfoClaimRequest {
  /**  */
  claimId?: number;

  /**  */
  note?: string;
}

export interface CXRequireInfoClaimResponse {
  /**  */
  message?: string;
}

export interface CXSearchPolicyResponse {
  /**  */
  items?: CXSearchPolicyResponseItem[];
}

export interface CXSearchPolicyResponseItem {
  /**  */
  policyHolderId?: number;

  /**  */
  policyNumber?: string;

  /**  */
  statusDisplay?: string;
}

export interface CXSubmitCommentRateRequest {
  /**  */
  rateId?: number;

  /**  */
  comment?: string;
}

export interface CXSubmitNewClaimRequest {
  /**  */
  memberId?: number;

  /**  */
  existingId?: number;

  /**  */
  phoneNumber?: string;

  /**  */
  memberPhoneNumber?: string;

  /**  */
  alternativePhoneNumber?: string;

  /**  */
  claimTypeId?: number;

  /**  */
  plannedVisitDate?: Date;

  /**  */
  visitDate?: Date;

  /**  */
  visitReason?: string;

  /**  */
  comment?: string;

  /**  */
  inOutPatient?: string;

  /**  */
  hospitalAdmissionDateTime?: Date;

  /**  */
  plannedDischargeDateTime?: Date;

  /**  */
  dischargeDateTime?: Date;

  /**  */
  totalHours?: number;

  /**  */
  totalAmount?: number;

  /**  */
  claimItems?: FDSubmitNewClaimRequestCategoryItem[];

  /**  */
  uploadedFiles?: FDSubmitNewClaimRequestFileItem[];

  /**  */
  aiaNote?: string;

  /**  */
  phoneNumberDisplay?: string;

  /**  */
  alternativePhoneNumberDisplay?: string;

  /**  */
  invoiceNumber?: string;

  /**  */
  invoiceDate?: Date;

  /**  */
  clinicId?: number;

  /**  */
  otherClinicName?: string;

  /**  */
  clinicReference?: string;

  /**  */
  clinicComment?: string;

  /**  */
  treatmentTypeId?: number;

  /**  */
  dateOfEvent?: Date;
}

export interface CXTerminateMemberRequest {
  /**  */
  memberId: number;
}

export interface CXTerminateMemberResponse {
  /**  */
  memberId?: number;
}

export interface CXUpdateCinicRequest {
  /**  */
  clinicId: number;

  /**  */
  name?: string;

  /**  */
  address?: string;

  /**  */
  province?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  email?: string;

  /**  */
  latitude?: number;

  /**  */
  longitude?: number;

  /**  */
  specialistDescription?: string;

  /**  */
  isOpen24H?: boolean;

  /**  */
  openTimes?: CXCreateCinicRequestOpenTime[];
}

export interface CXUpdateClinicBankInfoRequest {
  /**  */
  clinicId: number;

  /**  */
  bankId: number;

  /**  */
  bankAccountNumber: string;

  /**  */
  bankAccountName: string;
}

export interface CXUpdateClinicContactRequest {
  /**  */
  clinicId: number;

  /**  */
  contactPersonFirstName?: string;

  /**  */
  contactPersonLastName?: string;

  /**  */
  contactPersonGender?: string;

  /**  */
  contactPersonEmail?: string;

  /**  */
  contactPersonPhonePrimary?: string;

  /**  */
  contactPersonPhoneSecondary?: string;

  /**  */
  specialistDescription?: string;
}

export interface CXUpdateClinicUserRequest {
  /**  */
  userId: string;

  /**  */
  clinicId: number;

  /**  */
  firstName: string;

  /**  */
  lastName: string;

  /**  */
  userName: string;

  /**  */
  password?: string;

  /**  */
  phoneNumber: string;

  /**  */
  email?: string;

  /**  */
  birthDate: Date;

  /**  */
  identityNumber?: string;

  /**  */
  gender?: string;

  /**  */
  address?: string;

  /**  */
  role: string;
}

export interface CXUpdateInitialLOGApproveAmountRequest {
  /**  */
  approveAmount: number;
}

export interface CXUpdateInitialLOGApproveAmountResponse {
  /**  */
  message?: string;
}

export interface CXUpdateMemberBankInfoRequest {
  /**  */
  memberId: number;

  /**  */
  bankId: number;

  /**  */
  bankAccountNumber: string;

  /**  */
  bankAccountName: string;
}

export interface CXUpdateMemberProfileRequest {
  /**  */
  phoneNumber?: string;

  /**  */
  email?: string;

  /**  */
  address?: string;

  /**  */
  otp?: string;

  /**  */
  memberId: number;

  /**  */
  identityNumber?: string;

  /**  */
  birthDate: Date;

  /**  */
  gender?: string;
}

export interface CXUpdatePolicyHolderUser {
  /**  */
  userId: string;

  /**  */
  policyHolderId: number;

  /**  */
  firstName: string;

  /**  */
  lastName: string;

  /**  */
  password?: string;

  /**  */
  phoneNumber: string;

  /**  */
  email?: string;

  /**  */
  birthDate: Date;

  /**  */
  identityNumber?: string;

  /**  */
  gender?: string;

  /**  */
  address?: string;
}

export interface CXUpdatePolicyHolderUserPasswordRequest {
  /**  */
  userId: string;

  /**  */
  newPassword: string;
}

export interface FDBankInfoResponse {
  /**  */
  bankId?: number;

  /**  */
  bankName?: string;

  /**  */
  bankAccountNumber?: string;

  /**  */
  bankAccountName?: string;
}

export interface FDCancelClaimRequest {
  /**  */
  claimId?: number;
}

export interface FDCancelClaimResponse {
  /**  */
  message?: string;
}

export interface FDClaimCategoriesResponse {
  /**  */
  outPatients?: FDClaimCategoriesResponseItem[];

  /**  */
  inPatients?: FDClaimCategoriesResponseItem[];
}

export interface FDClaimCategoriesResponseItem {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  nameAlt?: string;

  /**  */
  amountType?: string;
}

export interface FDClaimDetailForEditResponse {
  /**  */
  memberId?: number;

  /**  */
  existingId?: number;

  /**  */
  phoneNumber?: string;

  /**  */
  memberPhoneNumber?: string;

  /**  */
  alternativePhoneNumber?: string;

  /**  */
  claimTypeId?: number;

  /**  */
  plannedVisitDate?: Date;

  /**  */
  visitDate?: Date;

  /**  */
  visitReason?: string;

  /**  */
  comment?: string;

  /**  */
  inOutPatient?: string;

  /**  */
  hospitalAdmissionDateTime?: Date;

  /**  */
  plannedDischargeDateTime?: Date;

  /**  */
  dischargeDateTime?: Date;

  /**  */
  totalHours?: number;

  /**  */
  totalAmount?: number;

  /**  */
  claimItems?: FDSubmitNewClaimRequestCategoryItem[];

  /**  */
  uploadedFiles?: FDClaimDetailForEditResponseFileItem[];

  /**  */
  aiaNote?: string;

  /**  */
  phoneNumberDisplay?: string;

  /**  */
  alternativePhoneNumberDisplay?: string;

  /**  */
  memberNumber?: string;

  /**  */
  policyNumber?: string;

  /**  */
  name?: string;

  /**  */
  birthDate?: Date;

  /**  */
  idCardPassport?: string;

  /**  */
  gender?: string;

  /**  */
  claimStatusId?: number;

  /**  */
  claimStatus?: string;

  /**  */
  claimStatusAlt?: string;

  /**  */
  code?: string;

  /**  */
  clinicComment?: string;

  /**  */
  clinicId?: number;

  /**  */
  treatmentType?: MBTreatmentTypeListResponseItem;

  /**  */
  dateOfEvent?: Date;

  /**  */
  dateOfEventDisplay?: string;

  /**  */
  nameCriticalIllness?: string;

  /**  */
  desSymptomsDateOnset?: string;

  /**  */
  dateFirstConsultation?: Date;

  /**  */
  dateFirstConsultationDisplay?: string;

  /**  */
  howLongSignsSymptoms?: string;

  /**  */
  dateConsultation?: Date;

  /**  */
  dateConsultationDisplay?: string;

  /**  */
  nameOfDoctor?: string;

  /**  */
  reasonConsultation?: string;

  /**  */
  hospitalClinicAddress?: string;

  /**  */
  isCriticalIllness?: boolean;

  /**  */
  fullDetail?: string;

  /**  */
  causeOfAccident?: string;

  /**  */
  degreeBurn?: MBDegreeBurnListResponseItem;

  /**  */
  partBodyBurned?: string;

  /**  */
  placeOfAccident?: string;

  /**  */
  confined?: MBConfinedListResponseItem;

  /**  */
  bodyInjuredTypeItem?: MBBodyInjuredTypeClaimHistoryResponseItem[];

  /**  */
  exactDutiesAfterTPD?: string;

  /**  */
  monthlyIncome?: number;

  /**  */
  nameBusinessOrEmployer?: string;

  /**  */
  addressBusinessOrEmployer?: string;

  /**  */
  phoneBusinessOrEmployer?: string;

  /**  */
  exactDutiesBeforeTPD?: string;

  /**  */
  monthlyIncomeBeforeTPD?: number;

  /**  */
  nameBusinessOrEmployerBeforeTPD?: string;

  /**  */
  addressBusinessOrEmployerBeforeTPD?: string;

  /**  */
  phoneBusinessOrEmployerBeforeTPD?: string;

  /**  */
  dateLastWorkingDay?: Date;

  /**  */
  dateLastWorkingDayDisplay?: string;

  /**  */
  dateReturnWork?: Date;

  /**  */
  dateReturnWorkDisplay?: string;

  /**  */
  tpdWasWue?: MBTPDWasWueListResponseItem;

  /**  */
  illnessCausedTPD?: string;

  /**  */
  describeSymptom?: string;

  /**  */
  dateSymptomsStarted?: Date;

  /**  */
  dateSymptomsStartedDisplay?: string;

  /**  */
  manyMonthsInsuredFirstConsultation?: string;

  /**  */
  nameDoctorFristConsultedIllness?: string;

  /**  */
  doctorCommentFristConsulted?: string;

  /**  */
  haClaiming?: MBHAClaimingListResponseItem;

  /**  */
  totalNights?: number;

  /**  */
  additionDate?: Date;

  /**  */
  additionDateDisplay?: string;

  /**  */
  address?: string;

  /**  */
  otherClinicName?: string;

  /**  */
  invoiceDate?: Date;

  /**  */
  invoiceDateDisplay?: string;

  /**  */
  invoiceNumber?: string;

  /**  */
  uuid?: string;
}

export interface FDClaimDetailForEditResponseFileItem {
  /**  */
  uniqueName?: string;

  /**  */
  isDecline?: boolean;

  /**  */
  fileUrl?: string;
}

export interface FDClaimSummaryResponse {
  /**  */
  numberOfPendingClaim?: number;

  /**  */
  numberOfApprovedClaim?: number;

  /**  */
  numberOfDeclinedClaim?: number;

  /**  */
  numberOfAdditionRequiredClaim?: number;

  /**  */
  numberOfClosedClaim?: number;

  /**  */
  numberOfCancelledClaim?: number;
}

export interface FDSearchMemberResponse {
  /**  */
  data?: FDSearchMemberResponseItem[];
}

export interface FDSearchMemberResponseItem {
  /**  */
  id?: number;

  /**  */
  memberNumber?: string;

  /**  */
  memberName?: string;

  /**  */
  birthDateDisplay?: string;

  /**  */
  birthDate?: Date;

  /**  */
  policyNumber?: string;

  /**  */
  plan?: string;

  /**  */
  masterPolicyHolder?: string;

  /**  */
  expireDateDisplay?: string;

  /**  */
  effectiveDateDisplay?: string;

  /**  */
  expireDate?: Date;

  /**  */
  effectiveDate?: Date;

  /**  */
  phoneNumber?: string;

  /**  */
  phoneNumberAlt?: string;

  /**  */
  idCardPassport?: string;

  /**  */
  gender?: string;

  /**  */
  status?: string;

  /**  */
  type?: string;

  /**  */
  uniqueId?: string;

  /**  */
  isDefault?: boolean;

  /**  */
  premiumAmount?: number;

  /**  */
  premiumAmountDisplay?: string;

  /**  */
  firstRegister?: boolean;

  /**  */
  useSystemPhoneNumberSMS?: boolean;

  /**  */
  occupation?: string;

  /**  */
  walletBalance?: number;

  /**  */
  walletBalanceStr?: string;

  /**  */
  premiumDueDate?: Date;

  /**  */
  premiumDueDateDisplay?: string;

  /**  */
  maturityDate?: Date;

  /**  */
  maturityDateDisplay?: string;

  /**  */
  premiumFrequency?: string;

  /**  */
  employeeNumber?: string;

  /**  */
  cardImage?: string;

  /**  */
  canSubmit?: boolean;

  /**  */
  isIMR?: boolean;

  /**  */
  isActive?: boolean;
}

export interface FDSubmitNewClaimRequest {
  /**  */
  memberId?: number;

  /**  */
  existingId?: number;

  /**  */
  phoneNumber?: string;

  /**  */
  memberPhoneNumber?: string;

  /**  */
  alternativePhoneNumber?: string;

  /**  */
  claimTypeId?: number;

  /**  */
  plannedVisitDate?: Date;

  /**  */
  visitDate?: Date;

  /**  */
  visitReason?: string;

  /**  */
  comment?: string;

  /**  */
  inOutPatient?: string;

  /**  */
  hospitalAdmissionDateTime?: Date;

  /**  */
  plannedDischargeDateTime?: Date;

  /**  */
  dischargeDateTime?: Date;

  /**  */
  totalHours?: number;

  /**  */
  totalAmount?: number;

  /**  */
  claimItems?: FDSubmitNewClaimRequestCategoryItem[];

  /**  */
  uploadedFiles?: FDSubmitNewClaimRequestFileItem[];

  /**  */
  aiaNote?: string;

  /**  */
  phoneNumberDisplay?: string;

  /**  */
  alternativePhoneNumberDisplay?: string;

  /**  */
  invoiceNumber?: string;

  /**  */
  invoiceDate?: Date;
}

export interface FDSubmitNewClaimRequestCategoryItem {
  /**  */
  claimCategoryId?: number;

  /**  */
  amount?: number;
}

export interface FDSubmitNewClaimRequestFileItem {
  /**  */
  uniqueName?: string;

  /**  */
  isDecline?: boolean;
}

export interface FDSubmitNewClaimResponse {
  /**  */
  newId?: number;

  /**  */
  existingId?: number;
}

export interface FDV2SubmitNewClaimRequest {
  /**  */
  memberId?: number;

  /**  */
  existingId?: number;

  /**  */
  phoneNumber?: string;

  /**  */
  memberPhoneNumber?: string;

  /**  */
  alternativePhoneNumber?: string;

  /**  */
  claimTypeId?: number;

  /**  */
  plannedVisitDate?: Date;

  /**  */
  visitDate?: Date;

  /**  */
  visitReason?: string;

  /**  */
  comment?: string;

  /**  */
  inOutPatient?: string;

  /**  */
  hospitalAdmissionDateTime?: Date;

  /**  */
  plannedDischargeDateTime?: Date;

  /**  */
  dischargeDateTime?: Date;

  /**  */
  totalHours?: number;

  /**  */
  totalAmount?: number;

  /**  */
  claimItems?: FDSubmitNewClaimRequestCategoryItem[];

  /**  */
  uploadedFiles?: FDSubmitNewClaimRequestFileItem[];

  /**  */
  aiaNote?: string;

  /**  */
  phoneNumberDisplay?: string;

  /**  */
  alternativePhoneNumberDisplay?: string;

  /**  */
  invoiceNumber?: string;

  /**  */
  invoiceDate?: Date;

  /**  */
  treatmentTypeId?: number;

  /**  */
  dateOfEvent?: Date;
}

export interface FlagServiceLogRequest {
  /**  */
  uniqueKey?: string;

  /**  */
  flagStatusId?: number;
}

export interface GeneralMessageResponse {
  /**  */
  message?: string;
}

export interface GenerateListClaimRequest {
  /**  */
  fromDate?: Date;

  /**  */
  toDate?: Date;

  /**  */
  oldestFirst?: number;
}

export interface HelpInfoResponse {
  /**  */
  hotlines?: HelpInfoResponseHotline[];

  /**  */
  telegram?: HelpInfoResponseTelegram;

  /**  */
  panelLocator?: HelpInfoResponsePanelLocator;
}

export interface HelpInfoResponseHotline {
  /**  */
  contactNumber?: string;

  /**  */
  displayContactNumber?: string;
}

export interface HelpInfoResponsePanelLocator {
  /**  */
  url?: string;
}

export interface HelpInfoResponseTelegram {
  /**  */
  url?: string;

  /**  */
  qrUrl?: string;
}

export interface HRAmountTerminateRequest {
  /**  */
  amountTerminateMembers?: HRAmountTerminateRequestItem[];
}

export interface HRAmountTerminateRequestItem {
  /**  */
  memberId?: number;

  /**  */
  effectiveDate?: Date;
}

export interface HRAmountTerminateResponse {
  /**  */
  amount?: number;
}

export interface HRCompanyProfileResponse {
  /**  */
  companyName?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  email?: string;

  /**  */
  address?: string;
}

export interface HRDetailMemberResponseItem {
  /**  */
  id?: number;

  /**  */
  memberNumber?: string;

  /**  */
  memberName?: string;

  /**  */
  birthDateDisplay?: string;

  /**  */
  birthDate?: Date;

  /**  */
  policyNumber?: string;

  /**  */
  plan?: string;

  /**  */
  masterPolicyHolder?: string;

  /**  */
  expireDateDisplay?: string;

  /**  */
  effectiveDateDisplay?: string;

  /**  */
  expireDate?: Date;

  /**  */
  effectiveDate?: Date;

  /**  */
  phoneNumber?: string;

  /**  */
  phoneNumberAlt?: string;

  /**  */
  idCardPassport?: string;

  /**  */
  gender?: string;

  /**  */
  status?: string;

  /**  */
  type?: string;

  /**  */
  uniqueId?: string;

  /**  */
  isDefault?: boolean;

  /**  */
  premiumAmount?: number;

  /**  */
  premiumAmountDisplay?: string;

  /**  */
  firstRegister?: boolean;

  /**  */
  useSystemPhoneNumberSMS?: boolean;

  /**  */
  occupation?: string;

  /**  */
  walletBalance?: number;

  /**  */
  walletBalanceStr?: string;

  /**  */
  premiumDueDate?: Date;

  /**  */
  premiumDueDateDisplay?: string;

  /**  */
  maturityDate?: Date;

  /**  */
  maturityDateDisplay?: string;

  /**  */
  premiumFrequency?: string;

  /**  */
  employeeNumber?: string;

  /**  */
  cardImage?: string;

  /**  */
  canSubmit?: boolean;

  /**  */
  isIMR?: boolean;

  /**  */
  isActive?: boolean;

  /**  */
  email?: string;

  /**  */
  address?: string;

  /**  */
  employmentDate?: string;

  /**  */
  salary?: string;

  /**  */
  bankName?: string;

  /**  */
  bankAccountNumber?: string;

  /**  */
  bankAccountName?: string;
}

export interface HRGenerateListMemberReponse {
  /**  */
  fileName?: string;
}

export interface HRGenerateListMemberRequest {
  /**  */
  planId?: number;

  /**  */
  search?: string;
}

export interface HRListMemberResponse {
  /**  */
  data?: FDSearchMemberResponseItem[];

  /**  */
  totalCount?: number;

  /**  */
  pageNumber?: number;

  /**  */
  pageSize?: number;

  /**  */
  totalPage?: number;

  /**  */
  totalMember?: number;
}

export interface HRListPlanMemberResponse {
  /**  */
  data?: FDSearchMemberResponseItem[];

  /**  */
  totalCount?: number;

  /**  */
  pageNumber?: number;

  /**  */
  pageSize?: number;

  /**  */
  totalPage?: number;

  /**  */
  totalMember?: number;

  /**  */
  walletBalence?: number;

  /**  */
  displayWalletBalance?: string;
}

export interface HRPlanListResponse {
  /**  */
  data?: HRPlanListResponseItem[];
}

export interface HRPlanListResponseItem {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  nameAlt?: string;

  /**  */
  reimbursementAutoApproveAmount?: number;

  /**  */
  reimbursementAnnualLimitAmount?: number;

  /**  */
  coastId?: string;

  /**  */
  coastAdditional1?: string;
}

export interface HRPolicyBenefitResponse {
  /**  */
  plans?: HRPolicyBenefitResponsePlan[];
}

export interface HRPolicyBenefitResponseBenefitItem {
  /**  */
  name?: string;

  /**  */
  nameAlt?: string;

  /**  */
  value?: string;
}

export interface HRPolicyBenefitResponsePlan {
  /**  */
  planId?: number;

  /**  */
  planName?: string;

  /**  */
  planNameAlt?: string;

  /**  */
  limits?: HRPolicyBenefitResponseBenefitItem[];

  /**  */
  benefits?: HRPolicyBenefitResponseBenefitItem[];

  /**  */
  optionals?: HRPolicyBenefitResponseBenefitItem[];

  /**  */
  coPayments?: HRPolicyBenefitResponseBenefitItem[];
}

export interface HRPolicyBenefitResponseV2 {
  /**  */
  items?: MBPolicyBenefitV2Response[];
}

export interface HRPolicyInfoResponse {
  /**  */
  policyNumber?: string;

  /**  */
  policyHolderName?: string;

  /**  */
  effectiveDate?: Date;

  /**  */
  expireDate?: Date;

  /**  */
  totalMember?: number;
}

export interface HRRequestRefund {
  /**  */
  id?: number;
}

export interface HRTransactionListResponse {
  /**  */
  walletBalance?: number;

  /**  */
  walletBalanceStr?: string;

  /**  */
  transactions?: TransactionResponseItem[];
}

export interface HRTransactionTypeListResponse {
  /**  */
  data?: HRTransactionTypeResponseItem[];
}

export interface HRTransactionTypeResponseItem {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  nameAlt?: string;
}

export interface HRUpdateMemberProfileRequest {
  /**  */
  phoneNumber?: string;

  /**  */
  email?: string;

  /**  */
  address?: string;

  /**  */
  otp?: string;

  /**  */
  memberId: number;
}

export interface ImageUploadResponse {
  /**  */
  files?: ImageUploadResponseFile[];
}

export interface ImageUploadResponseFile {
  /**  */
  fileName?: string;

  /**  */
  fileUrl?: string;
}

export interface MBBankInfoResponse {
  /**  */
  bankId?: number;

  /**  */
  bankName?: string;

  /**  */
  bankAccountNumber?: string;

  /**  */
  bankAccountName?: string;

  /**  */
  memberType?: MemberDetailOTPResponse;
}

export interface MBBankListResponse {
  /**  */
  items?: MBBankListResponseItem[];
}

export interface MBBankListResponseItem {
  /**  */
  bankId?: number;

  /**  */
  bankName?: string;
}

export interface MBBeneficiaryListResponse {
  /**  */
  items?: MBBeneficiaryListResponseItem[];
}

export interface MBBeneficiaryListResponseItem {
  /**  */
  type?: string;

  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  generalConsulation?: MBBeneficiaryListResponseItemGeneralConsulation;
}

export interface MBBeneficiaryListResponseItemGeneralConsulation {
  /**  */
  display?: string;

  /**  */
  displayAlt?: string;

  /**  */
  amount?: number;
}

export interface MBBeneficiaryV2ListResponse {
  /**  */
  items?: MBBeneficiaryV2ListResponseItem[];
}

export interface MBBeneficiaryV2ListResponseItem {
  /**  */
  type?: string;

  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  generalConsulation?: MBBeneficiaryListResponseItemGeneralConsulation;

  /**  */
  memberNumber?: string;

  /**  */
  birthDate?: Date;

  /**  */
  birthDateDisplay?: string;

  /**  */
  policyNumber?: string;

  /**  */
  idCardPassport?: string;

  /**  */
  gender?: string;
}

export interface MBBodyInjuredTypeClaimHistoryResponseItem {
  /**  */
  id?: number;

  /**  */
  bodyInjuredTypeItem?: MBBodyInjuredTypeListResponseItem;

  /**  */
  numberPartInjured?: string;
}

export interface MBBodyInjuredTypeListResponse {
  /**  */
  data?: MBBodyInjuredTypeListResponseItem[];
}

export interface MBBodyInjuredTypeListResponseItem {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  nameAlt?: string;
}

export interface MBClaimStatusListResponse {
  /**  */
  items?: MBClaimStatusListResponseItem[];
}

export interface MBClaimStatusListResponseItem {
  /**  */
  claimStatusId?: number;

  /**  */
  claimStatus?: string;

  /**  */
  claimStatusAlt?: string;
}

export interface MBClaimTypeCanSubmitResponse {
  /**  */
  lifeClaim?: boolean;

  /**  */
  medcareClaim?: boolean;

  /**  */
  criticalIllness?: boolean;

  /**  */
  accidentalBurn?: boolean;

  /**  */
  totalPermanentDismemberment?: boolean;

  /**  */
  hospitalizationAllowance?: boolean;

  /**  */
  generalConsultationCashless?: boolean;

  /**  */
  reimbursement?: boolean;

  /**  */
  canSubmit?: boolean;
}

export interface MBClaimTypeListResponse {
  /**  */
  items?: MBClaimTypeListResponseItem[];
}

export interface MBClaimTypeListResponseItem {
  /**  */
  claimTypeId?: number;

  /**  */
  claimType?: string;

  /**  */
  claimTypeAlt?: string;
}

export interface MBClinicListResponse {
  /**  */
  items?: MBClinicListResponseItem[];
}

export interface MBClinicListResponseItem {
  /**  */
  clinicId?: number;

  /**  */
  clinicName?: string;

  /**  */
  phoneNumber?: string;
}

export interface MBConfinedListResponse {
  /**  */
  data?: MBConfinedListResponseItem[];
}

export interface MBConfinedListResponseItem {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  nameAlt?: string;
}

export interface MBDegreeBurnListResponse {
  /**  */
  data?: MBDegreeBurnListResponseItem[];
}

export interface MBDegreeBurnListResponseItem {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  nameAlt?: string;
}

export interface MBDetailIdRequest {
  /**  */
  memberId?: number;
}

export interface MBDocumentListResponse {
  /**  */
  data?: MBDocumentListResponseItem[];
}

export interface MBDocumentListResponseItem {
  /**  */
  id?: number;

  /**  */
  uniqueName?: string;

  /**  */
  originalName?: string;

  /**  */
  documentName?: string;

  /**  */
  documentNameAlt?: string;

  /**  */
  link?: string;

  /**  */
  createdAtDisplay?: string;

  /**  */
  createdAt?: Date;
}

export interface MBExclusionListResponse {
  /**  */
  data?: MBExclusionListResponseItem[];
}

export interface MBExclusionListResponseItem {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  nameAlt?: string;

  /**  */
  image?: string;
}

export interface MBHAClaimingListResponse {
  /**  */
  data?: MBHAClaimingListResponseItem[];
}

export interface MBHAClaimingListResponseItem {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  nameAlt?: string;
}

export interface MBMaritalStatusListResponse {
  /**  */
  data?: MBMaritalStatusListResponseItem[];
}

export interface MBMaritalStatusListResponseItem {
  /**  */
  id?: number;

  /**  */
  title?: string;
}

export interface MBMemberDetailListResponse {
  /**  */
  data?: FDSearchMemberResponseItem[];

  /**  */
  registerMemberId?: number;
}

export interface MBMemberDetailResponse {
  /**  */
  id?: number;

  /**  */
  memberNumber?: string;

  /**  */
  memberName?: string;

  /**  */
  birthDateDisplay?: string;

  /**  */
  birthDate?: Date;

  /**  */
  policyNumber?: string;

  /**  */
  plan?: string;

  /**  */
  masterPolicyHolder?: string;

  /**  */
  expireDateDisplay?: string;

  /**  */
  effectiveDateDisplay?: string;

  /**  */
  expireDate?: Date;

  /**  */
  effectiveDate?: Date;

  /**  */
  phoneNumber?: string;

  /**  */
  phoneNumberAlt?: string;

  /**  */
  idCardPassport?: string;

  /**  */
  gender?: string;

  /**  */
  status?: string;

  /**  */
  type?: string;

  /**  */
  uniqueId?: string;

  /**  */
  isDefault?: boolean;

  /**  */
  premiumAmount?: number;

  /**  */
  premiumAmountDisplay?: string;

  /**  */
  firstRegister?: boolean;

  /**  */
  useSystemPhoneNumberSMS?: boolean;

  /**  */
  occupation?: string;

  /**  */
  walletBalance?: number;

  /**  */
  walletBalanceStr?: string;

  /**  */
  premiumDueDate?: Date;

  /**  */
  premiumDueDateDisplay?: string;

  /**  */
  maturityDate?: Date;

  /**  */
  maturityDateDisplay?: string;

  /**  */
  premiumFrequency?: string;

  /**  */
  employeeNumber?: string;

  /**  */
  cardImage?: string;

  /**  */
  canSubmit?: boolean;

  /**  */
  isIMR?: boolean;

  /**  */
  isActive?: boolean;
}

export interface MBNewBeneficiaryListResponse {
  /**  */
  data?: MBNewBeneficiaryListResponseItem[];
}

export interface MBNewBeneficiaryListResponseItem {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  gender?: string;

  /**  */
  dateOfBirth?: Date;

  /**  */
  dateOfBirthDisplay?: string;

  /**  */
  relationship?: MBRelationshipListResponseItem;

  /**  */
  phoneNumber?: string;

  /**  */
  email?: string;

  /**  */
  address?: string;

  /**  */
  amountPercent?: number;

  /**  */
  image?: string;

  /**  */
  profileImageUrl?: string;
}

export interface MBNewBeneficiaryRequest {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  gender?: string;

  /**  */
  dateOfBirth?: Date;

  /**  */
  relationshipId?: number;

  /**  */
  memberId?: number;

  /**  */
  phoneNumber?: string;

  /**  */
  email?: string;

  /**  */
  address?: string;

  /**  */
  amountPercent?: number;

  /**  */
  otp?: string;

  /**  */
  existingBeneficiary?: MBNExistingBeneficiary[];
}

export interface MBNExistingBeneficiary {
  /**  */
  id?: number;

  /**  */
  amountPercent?: number;
}

export interface MBPaymentKHQRListResponse {
  /**  */
  merchantName?: string;

  /**  */
  code?: string;

  /**  */
  message?: string;

  /**  */
  amount?: number;

  /**  */
  policyNumber?: string;

  /**  */
  base64KHQR?: string;

  /**  */
  transactionId?: string;

  /**  */
  sessionDuration?: number;
}

export interface MBPolicyBenefitResponse {
  /**  */
  planId?: number;

  /**  */
  planName?: string;

  /**  */
  planNameAlt?: string;

  /**  */
  limits?: HRPolicyBenefitResponseBenefitItem[];

  /**  */
  benefits?: HRPolicyBenefitResponseBenefitItem[];

  /**  */
  optionals?: HRPolicyBenefitResponseBenefitItem[];

  /**  */
  coPayments?: HRPolicyBenefitResponseBenefitItem[];

  /**  */
  remainings?: HRPolicyBenefitResponseBenefitItem[];
}

export interface MBPolicyBenefitV2IMRResponse {
  /**  */
  planName?: string;

  /**  */
  planNameAlt?: string;

  /**  */
  lifeBenefits?: MBPolicyBenefitV2ResponseBenefitItemDetail[];

  /**  */
  medCareBenefits?: MBPolicyBenefitV2ResponseBenefitItem[];
}

export interface MBPolicyBenefitV2Response {
  /**  */
  planId?: number;

  /**  */
  planName?: string;

  /**  */
  planNameAlt?: string;

  /**  */
  isIMR?: boolean;

  /**  */
  lifeBenefits?: MBPolicyBenefitV2ResponseBenefitItem[];

  /**  */
  medCareBenefits?: MBPolicyBenefitV2ResponseBenefitItem[];

  /**  */
  accidentCareBenefits?: MBPolicyBenefitV2ResponseBenefitItem[];
}

export interface MBPolicyBenefitV2ResponseBenefitItem {
  /**  */
  groupName?: string;

  /**  */
  groupNameAlt?: string;

  /**  */
  description?: string;

  /**  */
  descriptionAlt?: string;

  /**  */
  details?: MBPolicyBenefitV2ResponseBenefitItemDetail[];
}

export interface MBPolicyBenefitV2ResponseBenefitItemDetail {
  /**  */
  benefitName?: string;

  /**  */
  benefitNameAlt?: string;

  /**  */
  description?: string;

  /**  */
  descriptionAlt?: string;

  /**  */
  items?: MBPolicyBenefitV2ResponseBenefitItemDetailItem[];

  /**  */
  subItems?: MBPolicyBenefitV2ResponseBenefitItemDetail[];

  /**  */
  order?: number;
}

export interface MBPolicyBenefitV2ResponseBenefitItemDetailItem {
  /**  */
  name?: string;

  /**  */
  value?: string;

  /**  */
  description?: string;

  /**  */
  descriptionAlt?: string;

  /**  */
  order?: number;
}

export interface MBPolicyNotFoundRequest {
  /**  */
  phoneNumber?: string;

  /**  */
  birthDate?: Date;

  /**  */
  policyNumber?: string;

  /**  */
  memberNumber?: string;

  /**  */
  type?: string;
}

export interface MBPolicyProfileResponse {
  /**  */
  id?: number;

  /**  */
  memberNumber?: string;

  /**  */
  memberName?: string;

  /**  */
  birthDateDisplay?: string;

  /**  */
  birthDate?: Date;

  /**  */
  policyNumber?: string;

  /**  */
  plan?: string;

  /**  */
  masterPolicyHolder?: string;

  /**  */
  expireDateDisplay?: string;

  /**  */
  effectiveDateDisplay?: string;

  /**  */
  expireDate?: Date;

  /**  */
  effectiveDate?: Date;

  /**  */
  phoneNumber?: string;

  /**  */
  phoneNumberAlt?: string;

  /**  */
  idCardPassport?: string;

  /**  */
  gender?: string;

  /**  */
  status?: string;

  /**  */
  type?: string;

  /**  */
  uniqueId?: string;

  /**  */
  isDefault?: boolean;

  /**  */
  premiumAmount?: number;

  /**  */
  premiumAmountDisplay?: string;

  /**  */
  firstRegister?: boolean;

  /**  */
  useSystemPhoneNumberSMS?: boolean;

  /**  */
  occupation?: string;

  /**  */
  walletBalance?: number;

  /**  */
  walletBalanceStr?: string;

  /**  */
  premiumDueDate?: Date;

  /**  */
  premiumDueDateDisplay?: string;

  /**  */
  maturityDate?: Date;

  /**  */
  maturityDateDisplay?: string;

  /**  */
  premiumFrequency?: string;

  /**  */
  employeeNumber?: string;

  /**  */
  cardImage?: string;

  /**  */
  canSubmit?: boolean;

  /**  */
  isIMR?: boolean;

  /**  */
  isActive?: boolean;

  /**  */
  title?: string;

  /**  */
  email?: string;

  /**  */
  address?: string;

  /**  */
  maritalStatus?: MBMaritalStatusListResponseItem;

  /**  */
  paymentDetail?: AccountProfileResponsePaymentDetail;

  /**  */
  companyDetail?: AccountProfileResponseCompanyDetail;
}

export interface MBProfileResponse {
  /**  */
  systemProfile?: MBSystemProfileResponse;

  /**  */
  policyProfile?: MBPolicyProfileResponse;
}

export interface MBRateDetailResponseItem {
  /**  */
  id?: number;

  /**  */
  rateNumber?: number;

  /**  */
  memberNumber?: string;

  /**  */
  policyNumber?: string;

  /**  */
  memberName?: string;

  /**  */
  memberId?: number;

  /**  */
  submissionDate?: Date;

  /**  */
  submissionDateDisplay?: string;

  /**  */
  fromScreen?: string;

  /**  */
  device?: string;

  /**  */
  location?: string;

  /**  */
  ipAddress?: string;

  /**  */
  os?: string;

  /**  */
  comment?: string;

  /**  */
  status?: string;

  /**  */
  memberType?: string;

  /**  */
  rateComments?: CXCommentRateResponseItem[];
}

export interface MBRateListPaginateResponse {
  /**  */
  data?: MBRateListResponseItem[];

  /**  */
  totalCount?: number;

  /**  */
  pageNumber?: number;

  /**  */
  pageSize?: number;

  /**  */
  totalPage?: number;
}

export interface MBRateListResponseItem {
  /**  */
  id?: number;

  /**  */
  rateNumber?: number;

  /**  */
  memberNumber?: string;

  /**  */
  policyNumber?: string;

  /**  */
  memberName?: string;

  /**  */
  memberId?: number;

  /**  */
  submissionDate?: Date;

  /**  */
  submissionDateDisplay?: string;

  /**  */
  fromScreen?: string;

  /**  */
  device?: string;

  /**  */
  location?: string;

  /**  */
  ipAddress?: string;

  /**  */
  os?: string;

  /**  */
  comment?: string;

  /**  */
  status?: string;

  /**  */
  memberType?: string;
}

export interface MBRateRequest {
  /**  */
  id?: number;

  /**  */
  rateNumber?: number;

  /**  */
  memberId?: number;

  /**  */
  claimHistoryId?: number;

  /**  */
  submissionDate?: Date;

  /**  */
  fromScreen?: string;

  /**  */
  device?: string;

  /**  */
  location?: string;

  /**  */
  ipAddress?: string;

  /**  */
  os?: string;

  /**  */
  comment?: string;
}

export interface MBRegisterRequest {
  /**  */
  phoneNumber?: string;

  /**  */
  birthDate?: Date;

  /**  */
  policyNumber?: string;

  /**  */
  memberNumber?: string;
}

export interface MBRegisterResponse {
  /**  */
  phoneNumberMask?: string;

  /**  */
  memberNumber?: string;

  /**  */
  otp?: string;

  /**  */
  otpToken?: string;

  /**  */
  waitingSeconds?: number;

  /**  */
  userId?: string;

  /**  */
  lastName?: string;

  /**  */
  firstName?: string;

  /**  */
  email?: string;
}

export interface MBRegisterSetPasswordRequest {
  /**  */
  password?: string;

  /**  */
  otpToken?: string;

  /**  */
  setPasswordToken?: string;
}

export interface MBRegisterSetPasswordResponse {
  /**  */
  id?: number;

  /**  */
  memberNumber?: string;

  /**  */
  memberName?: string;

  /**  */
  birthDateDisplay?: string;

  /**  */
  birthDate?: Date;

  /**  */
  policyNumber?: string;

  /**  */
  plan?: string;

  /**  */
  masterPolicyHolder?: string;

  /**  */
  expireDateDisplay?: string;

  /**  */
  effectiveDateDisplay?: string;

  /**  */
  expireDate?: Date;

  /**  */
  effectiveDate?: Date;

  /**  */
  phoneNumber?: string;

  /**  */
  phoneNumberAlt?: string;

  /**  */
  idCardPassport?: string;

  /**  */
  gender?: string;

  /**  */
  status?: string;

  /**  */
  type?: string;

  /**  */
  uniqueId?: string;

  /**  */
  isDefault?: boolean;

  /**  */
  premiumAmount?: number;

  /**  */
  premiumAmountDisplay?: string;

  /**  */
  firstRegister?: boolean;

  /**  */
  useSystemPhoneNumberSMS?: boolean;

  /**  */
  occupation?: string;

  /**  */
  walletBalance?: number;

  /**  */
  walletBalanceStr?: string;

  /**  */
  premiumDueDate?: Date;

  /**  */
  premiumDueDateDisplay?: string;

  /**  */
  maturityDate?: Date;

  /**  */
  maturityDateDisplay?: string;

  /**  */
  premiumFrequency?: string;

  /**  */
  employeeNumber?: string;

  /**  */
  cardImage?: string;

  /**  */
  canSubmit?: boolean;

  /**  */
  isIMR?: boolean;

  /**  */
  isActive?: boolean;
}

export interface MBRegisterV2Request {
  /**  */
  phoneNumber?: string;

  /**  */
  birthDate?: Date;

  /**  */
  policyNumber?: string;

  /**  */
  memberNumber?: string;

  /**  */
  type?: string;

  /**  */
  requestType?: string;
}

export interface MBRegisterValidateOTPRequest {
  /**  */
  otpToken?: string;

  /**  */
  otp?: string;
}

export interface MBRegisterValidateOTPResponse {
  /**  */
  otpToken?: string;

  /**  */
  setPasswordToken?: string;
}

export interface MBRegisterValidateOTPV2Response {
  /**  */
  otpToken?: string;

  /**  */
  setPasswordToken?: string;

  /**  */
  memberList?: MemberDetailOTPResponse[];
}

export interface MBRelationshipListResponse {
  /**  */
  data?: MBRelationshipListResponseItem[];
}

export interface MBRelationshipListResponseItem {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  nameAlt?: string;
}

export interface MBRewardItemListResponseItem {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  nameAlt?: string;

  /**  */
  discount?: string;

  /**  */
  image?: string;

  /**  */
  description?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  address?: string;
}

export interface MBRewardTypeListResponse {
  /**  */
  data?: MBRewardTypeListResponseItem[];
}

export interface MBRewardTypeListResponseItem {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  nameAlt?: string;

  /**  */
  rewardItems?: MBRewardItemListResponseItem[];
}

export interface MBSubmitClaimOnlyDocumentRequest {
  /**  */
  memberId?: number;

  /**  */
  existingId?: number;

  /**  */
  uploadedFiles?: FDSubmitNewClaimRequestFileItem[];
}

export interface MBSubmitNewClaimRequest {
  /**  */
  memberId?: number;

  /**  */
  existingId?: number;

  /**  */
  phoneNumber?: string;

  /**  */
  memberPhoneNumber?: string;

  /**  */
  alternativePhoneNumber?: string;

  /**  */
  claimTypeId?: number;

  /**  */
  plannedVisitDate?: Date;

  /**  */
  visitDate?: Date;

  /**  */
  visitReason?: string;

  /**  */
  comment?: string;

  /**  */
  inOutPatient?: string;

  /**  */
  hospitalAdmissionDateTime?: Date;

  /**  */
  plannedDischargeDateTime?: Date;

  /**  */
  dischargeDateTime?: Date;

  /**  */
  totalHours?: number;

  /**  */
  totalAmount?: number;

  /**  */
  claimItems?: FDSubmitNewClaimRequestCategoryItem[];

  /**  */
  uploadedFiles?: FDSubmitNewClaimRequestFileItem[];

  /**  */
  aiaNote?: string;

  /**  */
  phoneNumberDisplay?: string;

  /**  */
  alternativePhoneNumberDisplay?: string;

  /**  */
  invoiceNumber?: string;

  /**  */
  invoiceDate?: Date;

  /**  */
  clinicId?: number;

  /**  */
  otherClinicName?: string;
}

export interface MBSubmitNewClaimResponse {
  /**  */
  newId?: number;

  /**  */
  existingId?: number;
}

export interface MBSubmitNewClaimV2Request {
  /**  */
  memberId?: number;

  /**  */
  existingId?: number;

  /**  */
  phoneNumber?: string;

  /**  */
  memberPhoneNumber?: string;

  /**  */
  alternativePhoneNumber?: string;

  /**  */
  claimTypeId?: number;

  /**  */
  plannedVisitDate?: Date;

  /**  */
  visitDate?: Date;

  /**  */
  visitReason?: string;

  /**  */
  comment?: string;

  /**  */
  inOutPatient?: string;

  /**  */
  hospitalAdmissionDateTime?: Date;

  /**  */
  plannedDischargeDateTime?: Date;

  /**  */
  dischargeDateTime?: Date;

  /**  */
  totalHours?: number;

  /**  */
  totalAmount?: number;

  /**  */
  claimItems?: FDSubmitNewClaimRequestCategoryItem[];

  /**  */
  uploadedFiles?: FDSubmitNewClaimRequestFileItem[];

  /**  */
  aiaNote?: string;

  /**  */
  phoneNumberDisplay?: string;

  /**  */
  alternativePhoneNumberDisplay?: string;

  /**  */
  invoiceNumber?: string;

  /**  */
  invoiceDate?: Date;

  /**  */
  clinicId?: number;

  /**  */
  otherClinicName?: string;

  /**  */
  treatmentTypeId?: number;

  /**  */
  dateOfEvent?: Date;

  /**  */
  nameCriticalIllness?: string;

  /**  */
  desSymptomsDateOnset?: string;

  /**  */
  dateFirstConsultation?: Date;

  /**  */
  howLongSignsSymptoms?: string;

  /**  */
  dateConsultation?: Date;

  /**  */
  nameOfDoctor?: string;

  /**  */
  reasonConsultation?: string;

  /**  */
  hospitalClinicAddress?: string;

  /**  */
  isCriticalIllness?: boolean;

  /**  */
  fullDetail?: string;

  /**  */
  causeOfAccident?: string;

  /**  */
  degreeBurnId?: number;

  /**  */
  partBodyBurned?: string;

  /**  */
  placeOfAccident?: string;

  /**  */
  confinedId?: number;

  /**  */
  bodyInjuredTypeItem?: BodyInjuredTypeRequest[];

  /**  */
  exactDutiesAfterTPD?: string;

  /**  */
  monthlyIncome?: number;

  /**  */
  nameBusinessOrEmployer?: string;

  /**  */
  addressBusinessOrEmployer?: string;

  /**  */
  phoneBusinessOrEmployer?: string;

  /**  */
  exactDutiesBeforeTPD?: string;

  /**  */
  monthlyIncomeBeforeTPD?: number;

  /**  */
  nameBusinessOrEmployerBeforeTPD?: string;

  /**  */
  addressBusinessOrEmployerBeforeTPD?: string;

  /**  */
  phoneBusinessOrEmployerBeforeTPD?: string;

  /**  */
  dateLastWorkingDay?: Date;

  /**  */
  dateReturnWork?: Date;

  /**  */
  tpdWasWueId?: number;

  /**  */
  illnessCausedTPD?: string;

  /**  */
  describeSymptom?: string;

  /**  */
  dateSymptomsStarted?: Date;

  /**  */
  manyMonthsInsuredFirstConsultation?: string;

  /**  */
  nameDoctorFristConsultedIllness?: string;

  /**  */
  doctorCommentFristConsulted?: string;

  /**  */
  haClaimingId?: number;

  /**  */
  totalNights?: number;

  /**  */
  address?: string;
}

export interface MBSystemProfileResponse {
  /**  */
  phoneNumberDisplay?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  dateOfBirthDisplay?: string;

  /**  */
  dateOfBirth?: Date;

  /**  */
  identityNumber?: string;

  /**  */
  gender?: string;

  /**  */
  profilePhotoUrl?: string;

  /**  */
  fileName?: string;
}

export interface MBTPDWasWueListResponse {
  /**  */
  data?: MBTPDWasWueListResponseItem[];
}

export interface MBTPDWasWueListResponseItem {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  nameAlt?: string;
}

export interface MBTreatmentTypeListResponse {
  /**  */
  data?: MBTreatmentTypeListResponseItem[];
}

export interface MBTreatmentTypeListResponseItem {
  /**  */
  id?: number;

  /**  */
  title?: string;

  /**  */
  titleAlt?: string;
}

export interface MBUpdateBankInfoRequest {
  /**  */
  bankId?: number;

  /**  */
  bankAccountNumber?: string;

  /**  */
  bankAccountName?: string;

  /**  */
  otp?: string;

  /**  */
  memberId?: number;
}

export interface MBUpdateBankInfoResponse {
  /**  */
  bankId?: number;

  /**  */
  bankName?: string;

  /**  */
  bankAccountNumber?: string;

  /**  */
  bankAccountName?: string;
}

export interface MBUpdatePartialProfileRequest {
  /**  */
  memberId?: number;

  /**  */
  phoneNumber?: string;

  /**  */
  email?: string;

  /**  */
  address?: string;

  /**  */
  occupation?: string;

  /**  */
  maritalStatusId?: number;

  /**  */
  useSystemPhoneNumberSMS?: boolean;

  /**  */
  secondaryPhoneNumber?: string;

  /**  */
  otp?: string;
}

export interface MBUpdateProfileRequest {
  /**  */
  phoneNumber?: string;

  /**  */
  email?: string;

  /**  */
  address?: string;

  /**  */
  otp?: string;
}

export interface MBUpdateProfileResponse {
  /**  */
  phoneNumber?: string;

  /**  */
  email?: string;

  /**  */
  address?: string;

  /**  */
  otp?: string;
}

export interface MBUpdateProfileV2Request {
  /**  */
  memberId?: number;

  /**  */
  phoneNumber?: string;

  /**  */
  email?: string;

  /**  */
  address?: string;

  /**  */
  occupation?: string;

  /**  */
  maritalStatusId?: number;

  /**  */
  useSystemPhoneNumberSMS?: boolean;

  /**  */
  secondaryPhoneNumber?: string;

  /**  */
  otp?: string;

  /**  */
  bankId?: number;

  /**  */
  bankAccountNumber?: string;

  /**  */
  bankAccountName?: string;
}

export interface MemberCardImage {
  /**  */
  cardImage?: string;
}

export interface MemberDetailOTPResponse {
  /**  */
  memberId?: number;

  /**  */
  title?: string;

  /**  */
  policyNumber?: string;

  /**  */
  memberNumber?: string;

  /**  */
  type?: string;
}

export interface MemberDocumentPayment {
  /**  */
  fileName?: string;

  /**  */
  link?: string;
}

export interface OktaLoginRequest {
  /**  */
  email?: string;

  /**  */
  password?: string;
}

export interface OktaUserResponse {
  /**  */
  id?: string;

  /**  */
  status?: string;

  /**  */
  email?: string;

  /**  */
  employeeNumber?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  locale?: string;

  /**  */
  login?: string;

  /**  */
  middleName?: string;

  /**  */
  mobilePhone?: string;

  /**  */
  nickName?: string;

  /**  */
  primaryPhone?: string;

  /**  */
  profileUrl?: string;

  /**  */
  secondEmail?: string;

  /**  */
  state?: string;

  /**  */
  title?: string;

  /**  */
  userType?: string;

  /**  */
  zipCode?: string;

  /**  */
  additionalProperties?: string;

  /**  */
  created?: string;

  /**  */
  activated?: string;

  /**  */
  statusChanged?: string;

  /**  */
  lastLogin?: string;

  /**  */
  lastUpdated?: string;
}

export interface RefreshTokenRequest {
  /**  */
  refreshToken?: string;
}

export interface ServiceLogRecordResponse {
  /**  */
  uniqueKey?: string;

  /**  */
  id?: string;

  /**  */
  policyNumber?: string;

  /**  */
  memberNumber?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  dateBirthDisplay?: string;

  /**  */
  nationalNumber?: string;

  /**  */
  gender?: string;

  /**  */
  name?: string;

  /**  */
  serviceDateDisplay?: string;

  /**  */
  serviceType?: string;

  /**  */
  requestedBy?: string;

  /**  */
  effectiveDateDisplay?: string;

  /**  */
  isGreenFlag?: boolean;

  /**  */
  isRedFlag?: boolean;

  /**  */
  hasComment?: boolean;

  /**  */
  comments?: ServiceLogRecordResponseComment[];
}

export interface ServiceLogRecordResponseComment {
  /**  */
  id?: number;

  /**  */
  commentBy?: string;

  /**  */
  comment?: string;

  /**  */
  commentDateDisplay?: string;
}

export interface ServiceLogRecordResponseGeneralListResponse {
  /**  */
  data?: ServiceLogRecordResponse[];

  /**  */
  totalCount?: number;

  /**  */
  pageNumber?: number;

  /**  */
  pageSize?: number;

  /**  */
  totalPage?: number;
}

export interface TransactionResponseItem {
  /**  */
  id?: number;

  /**  */
  transactionType?: HRTransactionTypeResponseItem;

  /**  */
  amount?: number;

  /**  */
  remark?: string;

  /**  */
  englishTitle?: string;

  /**  */
  khmerTitle?: string;

  /**  */
  createdAt?: Date;

  /**  */
  createdAtDisplay?: string;
}
