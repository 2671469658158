import { getTranslate } from "@/core/helpers/custom";
import {
  IInput,
  IRadioInput,
  IRadioOption,
  ISelectInput,
} from "@/interface/IInput";

export const appName = {
  isFD: (): string => {
    return "AIA Provider Portal".toUpperCase();
  },

  isMB: (): string => {
    return "My aia / aia comcare portal".toUpperCase();
  },
};

export enum StatusEnum {
  Pending = 0,
  Approved = 1,
  Declined = 2,
  Additional = 3,
  Closed = 4,
  Cancelled = 5,
  Paid = 6,
}

export enum ClaimTypeEnum {
  Initial = 1,
  Final = 2,
  Reimburse = 3,
}

export enum ClaimTypeStringIdEnum {
  Initial = "1",
  Final = "2",
  Reimburse = "3",
}

export enum InOutEnum {
  In = "IN",
  Out = "OUT",
}

export enum InOutStringEnum {
  In = "inPatients",
  Out = "outPatients",
}

export enum AdditionalRowEnum {
  HospitalAdmissionDateAndtime = 8,
  PlannedDischargeDateAndTime = 9,
  TotalHour = 10,
  ServiceProviderName = 13,
  ClinicComment = 16,
  DischargeDateAndTime = 17,
  ReasonForVisit = 18,
  PlannedDateOfVisit = 19,
  DateOfVisit = 20,
  ClaimAmountApproved = 21,
  AmountCollectionFromCustomer = 22,
  ClaimApprovedBy = 23,
  ClaimApprovedDate = 24,
  ClaimDeclineBy = 25,
  ClaimDeclineDate = 26,
  ClaimReviewBy = 27,
  ClaimReviewDate = 28,
  ClaimCloseBy = 29,
  ClaimClosedDate = 30,
  ClaimCancelledBy = 31,
  ClaimCancelledDate = 32,
  PhoneNumberOfClinic = 33,
  ClinicReference = 34,
  SubmittedBy = 35,
  ClaimPaidDate = 36,
  ClaimAmount = 37,
  MemberNumber = 38,
  PolicyNumber = 39,
  PassportNumber = 40,
  Gender = 41,
  PhoneNumber = 42,
  TreatmentType = 43,
  DateOfEvent = 44,
  DateOfBirth = 45,
  NameOfInsured = 46,
  AlternatePhoneNumber = 47,
  HospitalClinicName = 48,
  HospitalClinicAddress = 49,
  NameOfCriticalIllness = 50,
  DescriptionOfSymptomsFromDateOfOnset = 51,
  DateOfFirstConsultation = 52,
  HowLongHaveYouBeenHavingTheseSignsAndSymptoms = 53,
  DateOfConsultation = 54,
  NameOfDoctor = 55,
  ReasonForConsultation = 56,
  AreThereAnyOtherIllnessComplaintsSufferedToYouPriorToThisCriticalIllness = 57,
  ClaimSubmittedDate = 58,
  PleaseProvideTheDetailsOfTheCriticalIllness = 59,
  FullDetailsOfTheCriticalIllness = 60,
  ClaimAdditionalInformationRequiredDate = 61,
  WhichDegreeOfBurnAreYouClaimingFor = 62,
  WhichPartOfYourBodyWasBurned = 63,
  CauseOfAccident = 64,
  AccidentDate = 65,
  PlaceOfAccident = 66,
  AreYouCurrentlyConfinedToAnyOfTheFollowing = 67,
  PartOfBodyInjured = 68,
  CurrentExactDutiesAfterTPD = 69,
  CurrentMonthlyIncome = 70,
  NameOfCurrentBusinessOrEmployer = 71,
  AddressOfCurrentBusinessOrEmployer = 72,
  PhoneNumberOfCurrentBusinessOrEmployer = 73,
  CurrentExactDutiesBeforeTPD = 74,
  MonthlyIncomeBeforeTPD = 75,
  NameOfBusinessOrEmployerBeforeTPD = 76,
  AddressOfBusinessOrEmployerBeforeTPD = 77,
  PhoneNumberOfBusinessOrEmployerBeforeTPD = 78,
  DateOfLastWorkingDay = 79,
  DateYouReturnToWork = 80,
  IfTPDWasDueTo = 81,
  IllnessThatCausedTPDToHappen = 82,
  DescribeSymptom = 83,
  DateSymptomsStarted = 84,
  HowManyMonthsHadTheInsuredBeenHavingTheseSymptomsPriorToTheFirstConsultation = 85,
  NameOfDoctorHospitalClinicForFirstConsultedForTheIllness = 86,
  DoctorCommentAfterFirstConsultation = 87,
  DescribeSymptoms = 88,
  NameOfDoctorHospitalClinic = 89,
  AddressOfDoctorHospitalClinic = 90,
  WhichPartOfHaAreYouClaimingFor = 91,
  AdmissionDate = 92,
  DischargeDate = 93,
  TotalNights = 94,
  InvoiceNumber = 95,
  InvoiceDate = 96,
}

export enum NotificationType {
  CLAIM_APPROVED = "CLAIM_APPROVED",
  CLAIM_PAID = "CLAIM_PAID",
  CLAIM_CANCELLED = "CLAIM_CANCELLED",
  CLAIM_PARTIAL_APPROVED = "CLAIM_PARTIAL_APPROVED",
  CLAIM_CLOSED = "CLAIM_CLOSED",
  CLAIM_DECLINED = "CLAIM_DECLINED",
  CLAIM_NEED_INFORMATION = "CLAIM_NEED_INFORMATION",
  CLAIM_SUBMITTED = "CLAIM_SUBMITTED",
}

export enum CardTypeEnum {
  Individual = 0,
  Corporate = 1,
}

export enum CardTypeStringEnum {
  INDIVIDUAL = "Individual",
  CORPORATE = "Corporate",
}

export enum InputTypeEnum {
  Text = "text",
  Select = "select",
  Date = "date",
  Radio = "radio",
  File = "file",
  Checkbox = "checkbox",
  Number = "number",
  Button = "button",
  CustomCheckboxPartOfInjured = "custom-checkbox-part-of-injured",
  PhoneInput = "phone-input",
}

export enum EnvironmentEnum {
  Local = "development",
  Staging = "staging",
  Production = "production",
}

export enum ProjectsEnum {
  "FD" = "FD",
  "MB" = "MB",
  "HR" = "HR",
  "CX" = "CX",
}

export const defaultComponent = {
  uploadDocument: () => {
    return {
      titles: {
        en: "UPLOAD DOCUMENT",
        km: getTranslate("UPLOAD DOCUMENT"),
      },
      mainSubTitle: {
        en: "Please upload these documents to proceed your claim request",
        km: getTranslate(
          "Please upload these documents to proceed your claim request"
        ),
      },
      field: [
        {
          type: InputTypeEnum.File,
          listingRequiredDocument: [
            {
              label: {
                en: "Proof of ID card or Passport of Insured and/or Claimant(s)",
                km: getTranslate(
                  "Proof of ID card or Passport of Insured and/or Claimant(s)"
                ),
              },
            },
            {
              label: {
                en: "Medical Certificate",
                km: getTranslate("Medical Certificate"),
              },
            },
            {
              label: {
                en: "Proof of Relationship of Insured and Claimant(s); If different person",
                km: getTranslate(
                  "Proof of Relationship of Insured and Claimant(s); If different person"
                ),
              },
            },
            {
              label: {
                en: "All relevant medical documents",
                km: getTranslate("All relevant medical documents"),
              },
            },
            {
              label: {
                en: "Attending Physician’s Statement (APS)",
                km: getTranslate("Attending Physician’s Statement (APS)"),
              },
            },
            {
              label: {
                en: "Consent Letters for the release of information form signed by Claimant",
                km: getTranslate(
                  "Consent Letters for the release of information form signed by Claimant"
                ),
              },
            },
            {
              label: {
                en: "Proof of the Bank account of the Claimant(s)",
                km: getTranslate(
                  "Proof of the Bank account of the Claimant(s)"
                ),
              },
            },
          ],
          uploadButton: {
            en: "Upload Document",
            km: getTranslate("Upload Document"),
          },
        },
      ],
    };
  },
  paymentDetails: () => {
    return {
      titles: {
        en: "PAYMENT DETAILS",
        km: getTranslate("PAYMENT DETAILS"),
      },
      field: [
        <IInput>{
          label: {
            en: "Bank Name",
            km: getTranslate("Bank Name"),
          },
          name: "bankName",
          value: "ACLEDA Bank",
          type: InputTypeEnum.Text,
          column: "col-12",
          placeholder: "Ex: ACLEDA Bank",
          isDisabled: true,
        },
        <IInput>{
          label: {
            en: "Bank Account Number",
            km: getTranslate("Bank Account Number"),
          },
          name: "bankAccountNumber",
          value: "123456789",
          type: InputTypeEnum.Text,
          column: "col-12 col-md-6",
          placeholder: "Ex: 123456789",
          isDisabled: true,
        },
        <IInput>{
          label: {
            en: "Bank Account Name",
            km: getTranslate("Bank Account Name"),
          },
          name: "bankAccountName",
          value: "Raffel Medical",
          type: InputTypeEnum.Text,
          column: "col-12 col-md-6",
          placeholder: "Ex: Raffel Medical",
          isDisabled: true,
        },
      ],
    };
  },
  nameOfInsured: () => {
    return {
      titles: {
        en: "INFORMATION OF INSURED",
        km: getTranslate("INFORMATION OF INSURED"),
      },
      field: [
        <ISelectInput>{
          label: {
            en: "Name of Insured",
            km: getTranslate("Name of Insured"),
          },
          name: "nameOfInsured",
          value: 1,
          type: InputTypeEnum.Select,
          options: {},
          column: "col-12",
          formDataKey: "nameOfInsuredList",
        },
        <IInput>{
          label: {
            en: "Phone Number",
            km: getTranslate("Phone Number"),
          },
          name: "phoneNumber",
          type: InputTypeEnum.PhoneInput,
          column: "col-12 col-md-6",
          placeholder: "Ex. 012456232",
          directives: [InputDirective.phoneValidateV2()],
        },
        <IInput>{
          label: {
            en: "Alternate Phone Number",
            km: getTranslate("Alternate Phone Number"),
          },
          name: "alternatePhoneNumber",
          type: InputTypeEnum.PhoneInput,
          column: "col-12 col-md-6",
          placeholder: "Ex. 012456232",
          directives: [InputDirective.phoneValidateV2()],
        },
        <IInput>{
          label: {
            en: "Address",
            km: getTranslate("Address"),
          },
          name: "address",
          type: InputTypeEnum.Text,
          column: "col-12",
          placeholder: "Ex: Phnom Penh, Cambodia",
          directives: [InputDirective.allowMaxCharacter()],
        },
      ],
    };
  },
  areYouCurrentlyConfinedAnyOfTheFollowing: () => {
    return <IRadioInput>{
      label: {
        en: "Are you currently confined any of the following:",
        km: getTranslate("Are you currently confined any of the following:"),
      },
      name: "areYouCurrentlyConfinedAnyOfTheFollowing",
      type: InputTypeEnum.Radio,
      options: <IRadioOption[]>[],
      column: "col-12",
      children: [],
      formDataKey: "confinedRadioList",
    };
  },
};

export class InputDirective {
  static allowOnlyPhoneNumber(max = 10) {
    return {
      directive: "allow-only-phone-number",
      args: { max: max },
    };
  }

  static allowMaxCharacter(max = 100) {
    return {
      directive: "allow-max-character",
      args: { max: max },
    };
  }

  static allowOnlyAlphaNumeric(max = 10) {
    return {
      directive: "allow-only-alpha-numeric",
      args: { max: max },
    };
  }

  static allowOnlyNumber(max = 10) {
    return {
      directive: "allow-only-number",
      args: { max: max },
    };
  }

  static unsignedDecimal(max = 7) {
    return {
      directive: "unsigned-decimal",
      args: { max: max },
    };
  }

  static phoneValidateV2() {
    return {
      directive: "phone-validate-v2",
      args: {},
    };
  }
}

export enum LifeClaimFormEnum {
  CRITICAL_ILLNESS = "critical_illness",
  ACCIDENTAL_BURN = "accidental_burn",
  TOTAL_AND_PERMANENT_DISMEMBERMENT = "total_and_permanent_dismemberment",
  HOSPITALIZATION_ALLOWANCE = "hospitalization_allowance",
  DEATH = "death",
}

export enum LifeClaimIdEnum {
  CRITICAL_ILLNESS = 4,
  ACCIDENTAL_BURN = 5,
  TPD = 6,
  HOSPITALIZATION_ALLOWANCE = 7,
  DEATH = 8,
}

export enum DateTimeFormatEnum {
  "dd MMMM yyyy" = "dd MMMM yyyy",
  "dd MMMM yyyy hh:mm:ss" = "dd MMM yyyy hh:mm:ss",
}

export enum CardStatusEnum {
  ContractStatusDescription = "Contract Status Description",
  "Cancelled from Inception" = "Cancelled from Inception",
  "Contract Declined" = "Contract Declined",
  "Terminated due to claim" = "Terminated due to claim",
  "Expiry" = "Expiry",
  "InForce" = "In Force",
  "Incomplete" = "Incomplete",
  "Contract Lapsed" = "Contract Lapsed",
  "Manual Terminated" = "Manual Terminated",
  "Not taken Up" = "Not taken Up",
  "Contract Postponed" = "Contract Postponed",
  "Contract Proposal" = "Contract Proposal",
  "Contract Surrendered" = "Contract Surrendered",
  // "Terminated due to claim" = "Terminated due to claim",
  "Underwriting Approval" = "Underwriting Approval",
  "Contract Withdrawn" = "Contract Withdrawn",
  "Active" = "Active",
  "Terminated" = "Terminated",
}
