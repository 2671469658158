import { fdRouteName } from "@/core/helpers/util";
import type {
  IFrontDeskOtpResponse,
  User,
  UserAuthInfo,
} from "@/core/interface";
import ApiService from "@/core/services/ApiService";
import getLocalStorageService from "@/core/services/getLocalStorageService";
import JwtService from "@/core/services/JwtService";
import LocalStorageService from "@/core/services/LocalStorageService";
import type { IProfile } from "@/interface/front-desk/IProfile";
import { OTPScreenDataOriginateScreen } from "@/interface/IOTPScreenData";
import router from "@/router";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import type { AxiosRequestConfig } from "axios";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { AccountApi } from "@/api";
import { getIsApi } from "@/core/helpers/custom";

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  isAuthenticated = JwtService.getToken() !== null;
  frontDesktopOtpResponse = {} as IFrontDeskOtpResponse;
  profile = {} as IProfile;

  get currentUser(): User {
    return this.user;
  }

  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  get getErrors() {
    return this.errors;
  }

  get getProfile() {
    return this.profile;
  }

  get getFrontDesktopOtpResponse() {
    return this.frontDesktopOtpResponse;
  }

  @Mutation
  [Mutations.SET_PROFILE](payload: IProfile) {
    this.profile = {
      ...payload,
    };
  }

  @Mutation
  [Mutations.SET_FRONT_DESK_OTP_RESPONSE](payload: IFrontDeskOtpResponse) {
    this.frontDesktopOtpResponse = {
      ...payload,
      ...this.frontDesktopOtpResponse,
    };
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user;
    this.errors = {};
    JwtService.saveToken(user.accessToken);
    getLocalStorageService()?.SaveToken(user.accessToken);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    this.profile = {} as IProfile;
    JwtService.destroyToken();
  }

  @Action({ rawError: true })
  [Actions.REQUEST_OTP](payload: { currentSystem: string; credential: any }) {
    const currentSystem = "fd";

    const loginRouteMap = new Map();
    loginRouteMap.set("fd", "/Account/FD/OTPByUserPwd");

    return new Promise((resolve, reject) => {
      if (currentSystem === payload?.currentSystem) {
        return ApiService.post(
          loginRouteMap.get(currentSystem),
          payload?.credential
        )
          .then(({ data }) => {
            localStorage.setItem("ok", data.oktaSession ?? "");
            LocalStorageService.SaveOTPOriginateScreen(
              OTPScreenDataOriginateScreen.FDSI
            );
            LocalStorageService.SaveOTPScreenData({
              otp: data.otp,
              resendOTPToken: data.otpToken,
              waitingSeconds: data.waitingSeconds,
              phoneMask: data.phoneMask,
              userName: payload?.credential.userName,
              resetPasswordTokenId: data.resetPasswordTokenId,
            });
            LocalStorageService.SaveLoginByUserOTPRequest({
              userName: data.userName,
            });
            router.push({ name: fdRouteName("verify-otp") });
            resolve(data);
          })
          .catch((err) => {
            console.log(err);
            this.context.commit(Mutations.SET_ERROR, err.data);
            reject(err.data);
          });
      }
    });
  }

  @Action({ rawError: true })
  [Actions.RESEND_OTP](payload: AxiosRequestConfig) {
    return new Promise((resolve, reject) => {
      // ApiService.post("/Account/ResendOTP", payload)
      AccountApi.resendOtp({
        body: {
          ...payload,
          isApi: getIsApi(),
        },
      })
        .then((data) => {
          // this.context.commit(Mutations.SET_AUTH, data);
          this.context.commit(Mutations.SET_FRONT_DESK_OTP_RESPONSE, {
            otp: data?.otp,
            otpToken: data?.newOTPToken,
          });
          resolve(data);
        })
        .catch(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, data);
          reject(data);
        });
    });
  }

  @Action
  [Actions.LOGIN](payload) {
    const currentSystem = "fd";

    const loginRouteMap = new Map();
    loginRouteMap.set("fd", "/Account/FD/LoginByUserOTP");

    return new Promise((resolve, reject) => {
      if (currentSystem === payload?.currentSystem) {
        ApiService.post(loginRouteMap.get(currentSystem), payload?.credential)
          .then(({ data }) => {
            // this.context.commit(Mutations.SET_AUTH, data);

            this.context.commit(Mutations.SET_AUTH, data);
            resolve(data);
          })
          .catch(({ data }) => {
            this.context.commit(Mutations.SET_ERROR, data);
            reject(data);
          });
      }
    });

    // return ApiService.post("login", credentials)
    //   .then(({ data }) => {
    //     this.context.commit(Mutations.SET_AUTH, data);
    //   })
    //   .catch(({ response }) => {
    //     this.context.commit(Mutations.SET_ERROR, response.data.errors);
    //   });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
    // return ApiService.get(`/Account/Logout`)
    //   .then(() => {
    //     this.context.commit(Mutations.PURGE_AUTH);
    //   })
    //   .catch(() => {
    //     this.context.commit(Mutations.PURGE_AUTH);
    //   });
  }

  @Action
  [Actions.REGISTER](credentials: AxiosRequestConfig) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload: {
    currentSystem: string;
    data: AxiosRequestConfig;
  }) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        `/Account/${payload.currentSystem.toUpperCase()}/ResetPassword`,
        payload.data
      )
        .then(({ data }) => {
          this.context.commit(Mutations.SET_FRONT_DESK_OTP_RESPONSE, data);
          this.context.commit(Mutations.SET_ERROR, {});
          resolve(data);
        })
        .catch(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, data);
          reject(data);
        });
    });
  }

  @Action
  [Actions.NEW_PASSWORD](payload: {
    currentSystem: string;
    data: AxiosRequestConfig;
  }) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        `/Account/${payload.currentSystem.toUpperCase()}/SetNewPassword`,
        payload.data
      )
        .then(({ data }) => {
          // this.context.commit(Mutations.SET_ERROR, {});
          resolve(data);
        })
        .catch(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, data);
          reject(data);
        });
    });
  }

  @Action
  [Actions.UPDATE_PASSWORD](payload: {
    currentSystem: string;
    data: AxiosRequestConfig;
  }) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/Account/ChangePassword`, payload.data)
        .then(({ data }) => {
          // this.context.commit(Mutations.SET_ERROR, {});
          resolve(data);
        })
        .catch(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, data);
          reject(data);
        });
    });
  }

  @Action({ rawError: true })
  [Actions.VERIFY_AUTH]() {
    return new Promise((resolve, reject) => {
      if (JwtService.getToken()) {
        ApiService.get("/Account/Profile")
          .then(({ data }) => {
            this.context.commit(Mutations.SET_PROFILE, data);
            resolve(data);
          })
          .catch(({ response }) => {
            this.context.commit(Mutations.SET_ERROR, response.data.errors);
            this.context.commit(Mutations.PURGE_AUTH);
            reject(response);
          });
      } else {
        this.context.commit(Mutations.PURGE_AUTH);
        resolve(null);
      }
    });
  }
}
