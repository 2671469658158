import { Store } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { routeCheck } from "@/core/helpers/util";

export const AppOnMount = (store: Store<any>) => {
  store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
  store.dispatch(Actions.SET_THEME_MODE_ACTION, "light").then();
  if (
    store.getters.isUserAuthenticated ||
    store.getters.isUserAuthenticatedMember
  ) {
    if (store.getters.isUserAuthenticated) {
      store.dispatch(Actions.REQUEST_NOTIFICATION).then();
    }
    if (routeCheck.isMB() && store.getters.isUserAuthenticatedMember) {
      store.dispatch(Actions.REQUEST_MEMBER_NOTIFICATION).then();
    }
    store.dispatch(Actions.VERIFY_AUTH).then();
  }
};

export const notificationInterval = (store: Store<unknown>) => {
  return setInterval(() => {
    if (store.getters.isUserAuthenticated) {
      store.dispatch(Actions.REQUEST_NOTIFICATION).then();
    }
    if (routeCheck.isMB() && store.getters.isUserAuthenticatedMember) {
      store.dispatch(Actions.REQUEST_MEMBER_NOTIFICATION).then();
    }
  }, 60 * 1000);
};
