enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  MEMBER_LOGOUT = "memberLogout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  SET_THEME_MODE_ACTION = "setThemeModeAction",
  REQUEST_OTP = "requestOtp",
  RESEND_OTP = "resendOtp",
  REQUEST_NOTIFICATION = "requestNotification",
  NEW_PASSWORD = "newPassword",
  UPDATE_PASSWORD = "updatePassword",
  STOTE_DATA_BEFORE_SUBMIT = "storeDataBeforeSumit",
  OPEN_OTP_MEMEBER = "OPEN_OTP_MEMEBER",
  OPEN_SET_NEW_PASSOWRD = "OPEN_SET_NEW_PASSOWRD",
  REQUEST_MEMBER_NOTIFICATION = "requestMemberNotification",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_THEME_MODE_MUTATION = "setThemeModeMutation",
  SET_FRONT_DESK_OTP_RESPONSE = "setFrontDeskOtpResponse",
  SET_NOTIFICATION = "setNotification",
  SET_MEMBER_NOTIFICATION = "setMemberNotification",
  SET_PROFILE = "setProfile",
  SET_DATA_BEFORE_SUBMIT = "setDataBeforeSumit",
  SET_SYSTEM_TYPE = "setSystemType",
  SET_OTP_MEMEBER = "SET_OTP_MEMEBER",
  _OPEN_SET_NEW_PASSOWRD = "_OPEN_SET_NEW_PASSOWRD",
  CLEAR_DATA_NEW_PASSOWRD = "CLEAR_DATA_NEW_PASSOWRD",
  SET_AUTH_MEMBER = "SET_AUTH_MEMBER",
  SET_PROFILE_MEMBER = "setProfileMember",
}

export { Actions, Mutations };
